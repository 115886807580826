import React, { Component } from "react"
import axios from "axios"

import "./AdminHomeInfo.scss"

class AdminHome extends Component {
    constructor(props) {
        super(props)

        this.state = {
            about: "",
            support: "",
            businessInquiries: "",
            media: "",
            mailingName: "",
            mailingStreet: "",
            mailingCity: "",
            mailingState: "",
            mailingZipcode: "",
            supportEmailValidation: true,
            mediaEmailValidation: true,
            businessEmailValidation: true,
            twitterUsername: "",
        }
    }

    async componentDidMount() {
        try {
            let homeInfoRes = await axios.get("/api/admin/get/home-info")
            
            this.setState({
                about: homeInfoRes.data.about,
                support: homeInfoRes.data.support,
                businessInquiries: homeInfoRes.data.business_inquiries,
                media: homeInfoRes.data.media_contact,
                mailingName: homeInfoRes.data.mailing_name,
                mailingStreet: homeInfoRes.data.mailing_street,
                mailingCity: homeInfoRes.data.mailing_city,
                mailingState: homeInfoRes.data.mailing_state,
                mailingZipcode: homeInfoRes.data.mailing_zipcode,
                twitterUsername: homeInfoRes.data.twitter_username
            })
        }
        catch(err) {
            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }

    updateInput = (e) => {
        let emailFormat = /\S+@\S+\.\S+/

        if(e.target.name === "support") {
            if(e.target.value === "") {
                this.setState({
                    [e.target.name]: e.target.value,
                    supportEmailValidation: true
                })
            }
            else {
                if(e.target.value.match(emailFormat)) {
                    this.setState({
                        [e.target.name]: e.target.value,
                        supportEmailValidation: true
                    })
                }
                else {
                    this.setState({
                        [e.target.name]: e.target.value,
                        supportEmailValidation: false
                    })
                }
            }
        }
        else if(e.target.name === "businessInquiries") {
            if(e.target.value === "") {
                this.setState({
                    [e.target.name]: e.target.value,
                    businessEmailValidation: true
                })
            }
            else {
                if(e.target.value.match(emailFormat)) {
                    this.setState({
                        [e.target.name]: e.target.value,
                        businessEmailValidation: true
                    })
                }
                else {
                    this.setState({
                        [e.target.name]: e.target.value,
                        businessEmailValidation: false
                    })
                }
            }
        }
        else if(e.target.name === "media") {
            if(e.target.value === "") {
                this.setState({
                    [e.target.name]: e.target.value,
                    mediaEmailValidation: true
                })
            }
            else {
                if(e.target.value.match(emailFormat)) {
                    this.setState({
                        [e.target.name]: e.target.value,
                        mediaEmailValidation: true
                    })
                }
                else {
                    this.setState({
                        [e.target.name]: e.target.value,
                        mediaEmailValidation: false
                    })
                }
            }
        }
        else {
            this.setState({
                [e.target.name]: e.target.value
            })
        }
    }

    updateFrontPageInfo = async () => {
        try {
            let updateBtn = document.getElementById("admin_home_info_update_btn")
            updateBtn.innerHTML = ""
            updateBtn.classList.add("spinner")

            if(!this.state.supportEmailValidation || !this.state.businessEmailValidation || !this.state.mediaEmailValidation) {
                alert("Please enter in a valid email(s)")

                updateBtn.innerHTML = "Update"
                updateBtn.classList.remove("spinner")
            }
            else {
                let updateRes = await axios.put("/api/admin/update/home-info", {
                    about: this.state.about,
                    support: this.state.support,
                    business: this.state.businessInquiries,
                    media: this.state.media,
                    mailingName: this.state.mailingName,
                    mailingStreet: this.state.mailingStreet,
                    mailingCity: this.state.mailingCity,
                    mailingState: this.state.mailingState,
                    mailingZipcode: this.state.mailingZipcode,
                    twitterUsername: this.state.twitterUsername
                })

                alert(updateRes.data)

                updateBtn.innerHTML = "Update"
                updateBtn.classList.remove("spinner")
            }
        }
        catch(err) {
            let updateBtn = document.getElementById("admin_home_info_update_btn")

            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
            
            updateBtn.innerHTML = "Update"
            updateBtn.classList.remove("spinner")
        }
    }

    render() {
        let supportClass = this.state.supportEmailValidation ? "email_input" : "email_err_input"
        let businessClass = this.state.businessEmailValidation ? "email_input" : "email_err_input"
        let mediaClass = this.state.mediaEmailValidation ? "email_input" : "email_err_input"
        return (
            <div className="admin_home_info">
                <h4>
                    <strong>Frontpage Information:</strong>
                </h4>
                <div className="admin_form_container">
                    <div className="admin_input_container">
                        <p>About Us:</p>
                        <textarea name="about" 
                        cols="30" 
                        rows="10"
                        placeholder="about us" 
                        value={this.state.about} 
                        onChange={this.updateInput}></textarea>
                    </div>
                    <div className="admin_input_container">
                        <p>Support Email:</p>
                        {
                            this.state.supportEmailValidation
                            ?
                                null
                            :
                                <p className="email_err_msg">Invalid Email</p>
                        }
                        <input type="text" 
                        name="support"
                        className={supportClass}  
                        placeholder="user@example.com" 
                        value={this.state.support} 
                        onChange={this.updateInput} />
                    </div>
                    <div className="admin_input_container">
                        <p>Business Inquiries Email:</p>
                        {
                            this.state.businessEmailValidation
                            ?
                                null
                            :
                                <p className="email_err_msg">Invalid Email</p>
                        }
                        <input type="text" 
                        name="businessInquiries" 
                        className={businessClass} 
                        placeholder="user@example.com" 
                        value={this.state.businessInquiries} 
                        onChange={this.updateInput} />
                    </div>
                    <div className="admin_input_container">
                        <p>Media Contact Email:</p>
                        {
                            this.state.mediaEmailValidation
                            ?
                                null
                            :
                                <p className="email_err_msg">Invalid Email</p>
                        }
                        <input type="text" 
                        name="media" 
                        className={mediaClass} 
                        placeholder="user@example.com" 
                        value={this.state.media} 
                        onChange={this.updateInput} />
                    </div>
                    <div className="admin_input_container">
                        <p>Mailing Address Name:</p>
                        <input type="text" 
                        name="mailingName" 
                        placeholder="mailing name" 
                        value={this.state.mailingName} 
                        onChange={this.updateInput} />
                    </div>
                    <div className="admin_input_container">
                        <p>Mailing Address Street:</p>
                        <input type="text" 
                        name="mailingStreet" 
                        placeholder="mailing street" 
                        value={this.state.mailingStreet} 
                        onChange={this.updateInput} />
                    </div>
                    <div className="admin_input_container">
                        <p>Mailing Address City:</p>
                        <input type="text" 
                        name="mailingCity" 
                        placeholder="mailing city" 
                        value={this.state.mailingCity} 
                        onChange={this.updateInput} />
                    </div>
                    <div className="admin_input_container">
                        <p>Mailing Address State:</p>
                        <select name="mailingState" value={this.state.mailingState} onChange={this.updateInput}>
	                        <option value="AL">Alabama</option>
	                        <option value="AK">Alaska</option>
	                        <option value="AZ">Arizona</option>
	                        <option value="AR">Arkansas</option>
	                        <option value="CA">California</option>
	                        <option value="CO">Colorado</option>
	                        <option value="CT">Connecticut</option>
	                        <option value="DE">Delaware</option>
	                        <option value="DC">District Of Columbia</option>
	                        <option value="FL">Florida</option>
	                        <option value="GA">Georgia</option>
	                        <option value="HI">Hawaii</option>
	                        <option value="ID">Idaho</option>
	                        <option value="IL">Illinois</option>
	                        <option value="IN">Indiana</option>
	                        <option value="IA">Iowa</option>
	                        <option value="KS">Kansas</option>
	                        <option value="KY">Kentucky</option>
	                        <option value="LA">Louisiana</option>
	                        <option value="ME">Maine</option>
	                        <option value="MD">Maryland</option>
	                        <option value="MA">Massachusetts</option>
	                        <option value="MI">Michigan</option>
	                        <option value="MN">Minnesota</option>
	                        <option value="MS">Mississippi</option>
	                        <option value="MO">Missouri</option>
	                        <option value="MT">Montana</option>
	                        <option value="NE">Nebraska</option>
	                        <option value="NV">Nevada</option>
	                        <option value="NH">New Hampshire</option>
	                        <option value="NJ">New Jersey</option>
	                        <option value="NM">New Mexico</option>
	                        <option value="NY">New York</option>
	                        <option value="NC">North Carolina</option>
	                        <option value="ND">North Dakota</option>
	                        <option value="OH">Ohio</option>
	                        <option value="OK">Oklahoma</option>
	                        <option value="OR">Oregon</option>
	                        <option value="PA">Pennsylvania</option>
	                        <option value="RI">Rhode Island</option>
	                        <option value="SC">South Carolina</option>
	                        <option value="SD">South Dakota</option>
	                        <option value="TN">Tennessee</option>
	                        <option value="TX">Texas</option>
	                        <option value="UT">Utah</option>
	                        <option value="VT">Vermont</option>
	                        <option value="VA">Virginia</option>
	                        <option value="WA">Washington</option>
	                        <option value="WV">West Virginia</option>
	                        <option value="WI">Wisconsin</option>
	                        <option value="WY">Wyoming</option>
                        </select>
                    </div>
                    <div className="admin_input_container">
                        <p>Mailing Zipcode:</p>
                        <input type="text" 
                        name="mailingZipcode" 
                        placeholder="00000" 
                        value={this.state.mailingZipcode} 
                        onChange={this.updateInput} />
                    </div>
                    <div className="admin_input_container">
                        <p>Twitter Feed Username (do not include @ sign):</p>
                        <input type="text" 
                        name="twitterUsername" 
                        placeholder="TwitterUsername" 
                        value={this.state.twitterUsername} 
                        onChange={this.updateInput} />
                    </div>
                    <div className="admin_btn_container">
                        <button id="admin_home_info_update_btn" 
                        className="admin_spinner_container" 
                        disabled={!this.state.about || 
                        !this.state.support || 
                        !this.state.businessInquiries || 
                        !this.state.media || 
                        !this.state.mailingName || 
                        !this.state.mailingStreet || 
                        !this.state.mailingCity || 
                        !this.state.mailingState || 
                        !this.state.mailingZipcode} 
                        onClick={this.updateFrontPageInfo}>Update</button>
                    </div>
                </div>
            </div>
        )
    }
}

export default AdminHome