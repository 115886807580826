import React, { Component } from "react"
import axios from "axios"
import { Redirect } from "react-router-dom"

import Navbar from "../../ReusableComponents/Navbar/Navbar"
import Footer from "../../ReusableComponents/Footer/Footer"
import "./AdminNew.scss"

class AdminNew extends Component {
    constructor(props) {
        super(props)

        this.state = {
            pageStatus: "loading",
            user: {},
            screenName: "",
            tempPassword: "",
            password: "",
            confirmPassword: "",
            tempPasswordIcon: "/PasswordVisibility/hide_password.png",
            passwordIcon: "/PasswordVisibility/hide_password.png",
            confirmPasswordIcon: "/PasswordVisibility/hide_password.png",
            tempPasswordVisibility: "hide",
            passwordVisibility: "hide",
            confirmPasswordVisibility: "hide"
        }
    }

    async componentDidMount() {
        try {
            const urlParams = new URLSearchParams(this.props.location.search)
            let email = urlParams.get("email")
            let token = urlParams.get("token")
            if(email === null || token === null) {
                this.setState({
                    pageStatus: "redirect"
                })
            }
            else {
                let newUserRes = await axios.put("/auth-serv/admin/check/new", { email: email, token: token })
    
                this.setState({
                    pageStatus: "authorized",
                    user: newUserRes.data
                })
            }
        }
        catch(err) {
            if(err.response) {
                if(err.response.status) {
                    if(err.response.status === 401 || err.response.status === 403 || err.response.status === 500) {
                        if(err.response.data) {
                            alert(err.response.data)
                        }
                        this.setState({
                            pageStatus: "redirect"
                        })
                    }
                }
            }
        }
    }

    updateInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    showHideTempPassword = () => {
        let passwordInput = document.getElementById("new_user_temp_password")

        if(this.state.tempPasswordVisibility === "hide") {
            passwordInput.type = "text"

            this.setState({
                tempPasswordVisibility: "show",
                tempPasswordIcon: "/PasswordVisibility/show_password.png"
            })
        }
        else {
            passwordInput.type = "password"

            this.setState({
                tempPasswordVisibility: "hide",
                tempPasswordIcon: "/PasswordVisibility/hide_password.png"
            })
        }
    }

    showHidePassword = () => {
        let passwordInput = document.getElementById("new_user_password")

        if(this.state.passwordVisibility === "hide") {
            passwordInput.type = "text"

            this.setState({
                passwordVisibility: "show",
                passwordIcon: "/PasswordVisibility/show_password.png"
            })
        }
        else {
            passwordInput.type = "password"

            this.setState({
                passwordVisibility: "hide",
                passwordIcon: "/PasswordVisibility/hide_password.png"
            })
        }
    }

    showHideConfirmPassword = () => {
        let passwordInput = document.getElementById("new_user_confirm_password")

        if(this.state.confirmPasswordVisibility === "hide") {
            passwordInput.type = "text"

            this.setState({
                confirmPasswordVisibility: "show",
                confirmPasswordIcon: "/PasswordVisibility/show_password.png"
            })
        }
        else {
            passwordInput.type = "password"

            this.setState({
                confirmPasswordVisibility: "hide",
                confirmPasswordIcon: "/PasswordVisibility/hide_password.png"
            })
        }
    }

    finishSetup = async () => {
        try {
            let finishBtn = document.getElementById("admin_new_finish_setup_btn")
            finishBtn.innerHTML = ""
            finishBtn.classList.add("spinner")

            if(this.state.screenName === "") {
                alert("You must have a screen name it can be just your name if you want.")

                finishBtn.innerHTML = "Finish Setup"
                finishBtn.classList.remove("spinner")
            }
            else if(this.state.screenName.length > 20) {
                alert("Your screen name must be under 20 characters including spaces.")

                finishBtn.innerHTML = "Finish Setup"
                finishBtn.classList.remove("spinner")
            }
            else if(!this.state.tempPassword) {
                alert("Must have temporary password that super admin gave you")

                finishBtn.innerHTML = "Finish Setup"
                finishBtn.classList.remove("spinner")
            }
            else if(!this.state.password) {
                alert("Must have a new password")

                finishBtn.innerHTML = "Finish Setup"
                finishBtn.classList.remove("spinner")
            }
            else if(this.state.password.length > 255) {
                alert("Your password must be under 255 characters including spaces.")

                finishBtn.innerHTML = "Finish Setup"
                finishBtn.classList.remove("spinner")
            }
            else {
                if(this.state.password === this.state.confirmPassword) {
                    await axios.put("/api/admin/update/new/user", {
                        id: this.state.user.id, 
                        email: this.state.user.email, 
                        screenName: this.state.screenName,
                        tempPassword: this.state.tempPassword, 
                        password: this.state.password
                    })

                    finishBtn.innerHTML = "Finish Setup"
                    finishBtn.classList.remove("spinner")

                    this.props.history.push("/admin")
                }
                else {
                    alert("Password does not match Confirm Password")

                    finishBtn.innerHTML = "Finish Setup"
                    finishBtn.classList.remove("spinner")
                }
            }
        }
        catch(err) {
            let finishBtn = document.getElementById("admin_new_finish_setup_btn")

            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }

            finishBtn.innerHTML = "Finish Setup"
            finishBtn.classList.remove("spinner")
        }
    }

    render() {
        const { REACT_APP_SERVER_HOST } = process.env
        return (
            this.state.pageStatus === "loading"
            ?
                <div></div>
            :
            this.state.pageStatus === "authorized"
            ?
                <div className="main_wrapper">
                    <div className="admin_new">
                        <Navbar />
                        <div className="admin_new_panel panel">
                            <header className="admin_new_header">
                                <h3>
                                    <b>Finish setting up your account</b>
                                </h3>
                            </header>
                            <div className="new_user_content">
                                <div className="admin_new_input_container">
                                    <p>Screen Name:</p>
                                    <input type="text" 
                                    name="screenName" 
                                    placeholder="Screen Name" 
                                    value={this.state.screenName} 
                                    onChange={this.updateInput}/>
                                </div>
                                <div className="admin_new_input_container">
                                    <p>Temporary Password:</p>
                                    <div className="admin_new_password_input">
                                        <input type="password" 
                                        id="new_user_temp_password" 
                                        name="tempPassword" 
                                        placeholder="Temporary Password" 
                                        value={this.state.tempPassword} 
                                        onChange={this.updateInput}/>
                                        <img src={`${REACT_APP_SERVER_HOST}${this.state.tempPasswordIcon}`} alt="password_icon" onClick={this.showHideTempPassword}/>
                                    </div>
                                </div>
                                <div className="admin_new_input_container">
                                    <p>New Password:</p>
                                    <div className="admin_new_password_input">
                                        <input type="password" 
                                        id="new_user_password" 
                                        name="password" 
                                        placeholder="Password" 
                                        value={this.state.password} 
                                        onChange={this.updateInput}/>
                                        <img src={`${REACT_APP_SERVER_HOST}${this.state.passwordIcon}`} alt="password_icon" onClick={this.showHidePassword}/>
                                    </div>
                                </div>
                                <div className="admin_new_input_container">
                                    <p>Confirm New Password:</p>
                                    <div className="admin_new_password_input">
                                        <input type="password" 
                                        id="new_user_confirm_password" 
                                        name="confirmPassword" 
                                        placeholder="Confirm Password" 
                                        value={this.state.confirmPassword} 
                                        onChange={this.updateInput}/>
                                        <img src={`${REACT_APP_SERVER_HOST}${this.state.confirmPasswordIcon}`} alt="password_icon" onClick={this.showHideConfirmPassword}/>
                                    </div>
                                </div>
                                <div className="admin_new_btn_container">
                                    <button id="admin_new_finish_setup_btn" 
                                    className="admin_spinner_container" 
                                    onClick={this.finishSetup}>Finish Setup</button>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            :
                <Redirect  to={{ pathname: "/" }}/>
        )
    }
}

export default AdminNew