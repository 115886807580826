import React, { Component } from "react"

import "./AdminPreviewGame.scss"

class AdminPreviewGame extends Component {
    constructor(props) {
        super(props)

        this.state = {
            videoId: "",
            errMessage: "",
            err: false
        }

        this.backgroundRef = React.createRef()
        this.logoRef = React.createRef()
        this.overlayRef = React.createRef()
    }

    componentDidMount() {
        const { REACT_APP_SERVER_HOST } = process.env

        this.logoRef.current.style.backgroundImage = `url("${REACT_APP_SERVER_HOST}${this.props.logo}")`

        this.logoRef.current.style.marginTop = `${this.props.marginTop}px`

        this.logoRef.current.style.marginLeft = `${this.props.marginLeft}px`

        this.overlayRef.current.style.backgroundImage = `url("${REACT_APP_SERVER_HOST}${this.props.overlay}")`

        let regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/

        let match = this.props.video.match(regExp)

        if(match && match[2].length >= 11) {
            this.setState({
                videoId: match[2],
                errMessage: "",
                err: false
            })
        }
        else {
            this.setState({
                errMessage: "Invalid url or there was an error embedding the video",
                err: true
            })
        }
    }

    componentDidUpdate(prevProps) {
        const { REACT_APP_SERVER_HOST } = process.env

        if(this.props.backgroundImg !== prevProps.backgroundImg) {
            this.backgroundRef.current.style.backgroundImage = `url("${REACT_APP_SERVER_HOST}${this.props.backgroundImg}")`
        }

        if(this.props.marginTop !== prevProps.marginTop) {
            this.logoRef.current.style.marginTop = `${this.props.marginTop}px`
        }

        if(this.props.marginLeft !== prevProps.marginLeft) {
            this.logoRef.current.style.marginLeft = `${this.props.marginLeft}px`
        }

        if(this.props.overlay !== prevProps.overlay) {
            this.overlayRef.current.style.backgroundImage = `url("${REACT_APP_SERVER_HOST}${this.props.overlay}")`
        }

        if(this.props.video !== prevProps.video) {
            let regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/

            let match = this.props.video.match(regExp)

            if(match && match[2].length >= 11) {
                this.setState({
                    videoId: match[2],
                    errMessage: "",
                    err: false
                })
            }
            else {
                this.setState({
                    errMessage: "Invalid url or there was an error embedding the video",
                    err: true
                })
            }
        }
    }

    stopRun = (e) => {
        e.stopPropagation()
    }

    render() {
        const { REACT_APP_SERVER_HOST } = process.env
        let className = this.props.show ? "admin_preview_game preview_show" : "admin_preview_game preview_hide"
        let descriptionLines
        if(this.props.description.replace(/\r\n|\r|\n/g, "/newline").split("/newline")) {
            descriptionLines = this.props.description.replace(/\r\n|\r|\n/g, "/newline").split("/newline").filter((text) => text).map((text, i) => {
                return (
                    <p key={i}>{text}</p>
                )
            })
        }
        else {
            descriptionLines = this.props.description
        }
        return (
            <div className={className} onClick={this.props.openClosePreview}>
                {
                    this.state.err
                    ?
                        <div className="err_message_container">
                            <p>{this.state.errMessage}</p>
                        </div>
                    :
                        null
                }
                <div className="game_overlay" ref={this.overlayRef}></div>
                <div className="game_container" ref={this.backgroundRef} onClick={this.stopRun}>
                    <div className="logo_container">
                        <img className="logo" src={`${REACT_APP_SERVER_HOST}${this.props.logo}`} alt="logo" ref={this.logoRef} />
                    </div>
                    <div className="info_youtube_container">
                        <div className="info_container">
                            <h2>{this.props.name}</h2>
                            <h4>{this.props.developer}</h4>
                            <div className="description_container">
                                {descriptionLines}
                            </div>
                        </div>
                        <div className="youtube_container">
                            <iframe src={`https://www.youtube.com/embed/${this.state.videoId}?feature=player_detailpage&autoplay=0&rel=0`} 
                            frameBorder="0" 
                            allowFullScreen 
                            height="315" 
                            width="560" 
                            title="Game Video"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AdminPreviewGame