import React from "react"

import AdminHomeVideo from "./Components/AdminHomeVideo/AdminHomeVideo"
import "./AdminViewHomeVideos.scss"

let AdminViewHomeVideos = (props) => {
    let changeToAdd = () => {
        props.changePanelType("add")
    }

    let videos = props.videos.map((video, i) => {
        return (
            <AdminHomeVideo key={i} video={video} getVideosAgain={props.getVideosAgain} changePanelType={props.changePanelType} />
        )
    })

    return (
        <div className="admin_view_home_videos">
            <div className="admin_view_page_info_container">
                <h4>
                    Home Videos page - {props.pageNumber}
                </h4>
                <button onClick={changeToAdd}>New</button>
            </div>
            <div className="admin_home_videos_videos_container">
                {videos}
            </div>
            <div className="admin_previous_next_container">
                <button disabled={props.pageNumber === 1} onClick={props.previousPage}>Previous</button>
                <button disabled={props.pageNumber === props.pages} onClick={props.nextPage}>Next</button>
            </div>
        </div>
    )
}

export default AdminViewHomeVideos