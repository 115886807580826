import React, { Component } from "react"
import axios from "axios"

import Navbar from "../../ReusableComponents/Navbar/Navbar"
import Footer from "../../ReusableComponents/Footer/Footer"
import TeamMember from "./Components/TeamMember/TeamMember"
import "./Team.scss"

class Team extends Component {
    constructor(props) {
        super(props)

        this.state = {
            team: [],
            description: ""
        }
    }

    async componentDidMount() {
        document.title = "Our Team - Re-Logic"

        let teamRes = await axios.get("/api/user/get/team")
        let descriptionRes = await axios.get("/api/user/get/team-description")

        this.setState({
            team: teamRes.data,
            description: descriptionRes.data[0].description
        })
    }

    render() {
        let teamMembers = this.state.team.map((teamMember, i) => {
            return (
                <TeamMember key={i} teamMember={teamMember} />
            )
        })
        return (
            <div className="main_wrapper">
                <div className="team">
                    <Navbar />
                    <div className="team_panel panel">
                        <h2>The Team</h2>
                        {/* <p>
                            The Re-Logic team is made up of 12 experienced & dedicated individuals from diverse backgrounds that are dedicated to bringing amazing & 
                            innovative games to the market.
                        </p>
                        <p style={{ "marginTop": "0" }}>
                            From Game Design to Coding, Art, and even the Business side of gaming, 
                            the team covers all aspects of game development – and takes the time to have a lot of fun along the way!
                        </p> */}
                        <p>{ this.state.description }</p>
                        <hr />
                        <div className="portrait_list">
                            {teamMembers}
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        )
    }
}

export default Team