import React, { Component } from "react"
import axios from "axios"
import { Redirect } from "react-router-dom"

import Navbar from "../../ReusableComponents/Navbar/Navbar"
import Footer from "../../ReusableComponents/Footer/Footer"
import AdminHomeInfo from "./Components/AdminHomeInfo/AdminHomeInfo"
import AdminHomeGames from "./Components/AdminHomeVideos/AdminHomeVideos"
import AdminHomeSocials from "./Components/AdminHomeSocials/AdminHomeSocials"
import AdminGames from "./Components/AdminGames/AdminGames"
import AdminTeam from "./Components/AdminTeam/AdminTeam"
import AdminUsers from "./Components/AdminUsers/AdminUsers"
import AdminChangePassword from "./Components/AdminChangePassword/AdminChangePassword"
import "./AdminMain.scss"

class AdminMain extends Component {
    constructor(props) {
        super(props)

        this.state = {
            pageStatus: "loading",
            user: {},
            selectedTab: "home info",
            updateNav: false
        }
    }

    async componentDidMount() {
        try {
            document.title = "Admin - Re-logic"
    
            let loggedInRes = await axios.get("/auth-serv/admin/check")
    
            this.setState({
                pageStatus: loggedInRes.data.result ? "loggedIn" : "redirect",
                user: loggedInRes.data.user
            })
        }
        catch(err) {
            if(err.response) {
                if(err.response.status) {
                    if(err.response.status === 401) {
                        this.setState({
                            pageStatus: "redirect"
                        })
                    }
                }
            }
        }
    }

    changeView = (e) => {
        this.setState({
            selectedTab: e.target.innerText.toLowerCase()
        })
    }

    pushToSignIn = () => {
        this.props.history.push("/auth/sign-in")
    }

    updateNavSocials = () => {
        this.setState({
            updateNav: !this.state.updateNav
        })
    }

    logout = async () => {
        await axios.post("/auth-serv/admin/sign-out")
    }

    render() {
        let homeInfoClass = this.state.selectedTab === "home info" ? "admin_nav_active admin_nav_btn admin_nav_home_info" : "admin_nav_btn admin_nav_home_info"
        let homeGamesClass = this.state.selectedTab === "home videos" ? "admin_nav_active admin_nav_btn admin_nav_home_videos" : "admin_nav_btn admin_nav_home_videos"
        let homeSocialClass = this.state.selectedTab === "home socials" ? "admin_nav_active admin_nav_btn admin_nav_home_social" : "admin_nav_btn admin_nav_home_social"
        let gamesClass = this.state.selectedTab === "games" ? "admin_nav_active admin_nav_btn admin_nav_games" : "admin_nav_btn admin_nav_games"
        let teamClass = this.state.selectedTab === "team" ? "admin_nav_active admin_nav_btn admin_nav_team" : "admin_nav_btn admin_nav_team"
        let usersClass = this.state.selectedTab === "users" ? "admin_nav_active admin_nav_btn admin_nav_users" : "admin_nav_btn admin_nav_users"
        let changePasswordClass = this.state.selectedTab === "change password" ? "admin_nav_active admin_nav_btn admin_nav_change_password" : "admin_nav_btn admin_nav_change_password"
        return (
            this.state.pageStatus === "loading"
            ?
                <div>
                    <Navbar />
                </div>
            :
            this.state.pageStatus === "loggedIn"
            ?
            this.state.user.role === "new user"
            ?
                <div className="main_wrapper">
                    <div className="admin_main">
                        <Navbar />
                        <div className="admin_new_user_content">
                            <a href="/">
                                <div className="logo"></div>
                            </a>
                            <p>You do not have admin access yet please wait for a super admin to change your role then sign in again</p>
                            <span className="admin_new_user_sign_out">
                                <a href="/auth-serv/admin/sign-out">Sign out</a>
                            </span>
                        </div>
                    </div>
                </div>
            :
                <div className="main_wrapper">
                    <div className="admin_main">
                        <Navbar updateNav={this.state.updateNav} updateNavSocials={this.updateNavSocials} />                  
                        <div className="admin_panel panel">
                            <header className="admin_main_header">
                                <h3 style={{"margin": "0"}}>
                                    <b>Admin Panel ({this.state.user.screenName || "Admin"})</b>
                                </h3>
                            </header>
                            <div className="admin_panel_content">
                                <nav>
                                    <ul>
                                        <li className={homeInfoClass} onClick={this.changeView}>
                                            <span id="home_info">Home Info</span>
                                        </li>
                                        <li className={homeGamesClass} onClick={this.changeView}>
                                            <span id="home_videos">Home Videos</span>
                                        </li>
                                        <li className={homeSocialClass} onClick={this.changeView}>
                                            <span id="navbar_socials">Navbar Socials</span>
                                        </li>
                                        <li className={gamesClass} onClick={this.changeView}>
                                            <span id="games">Games</span>
                                        </li>
                                        <li className={teamClass} onClick={this.changeView}>
                                            <span id="team">Team</span>
                                        </li>
                                        {
                                            this.state.user.role === "super admin"
                                            ?
                                                <li className={usersClass} onClick={this.changeView}>
                                                    <span id="users">Users</span>
                                                </li>
                                            :
                                                null
                                        }
                                        <li className={changePasswordClass} onClick={this.changeView}>
                                            <span id="change_password">Change password</span>
                                        </li>
                                        <li className="admin_nav_btn admin_nav_sign_out">
                                            <span>
                                                <a href="/auth-serv/admin/sign-out">Sign out</a>
                                            </span>
                                        </li>
                                    </ul>
                                </nav>
                                <div className="admin_main_content">
                                    {
                                        this.state.selectedTab === "home info"
                                        ?
                                            <AdminHomeInfo />
                                        :
                                        this.state.selectedTab === "home videos"
                                        ?
                                            <AdminHomeGames />
                                        :
                                        this.state.selectedTab === "navbar socials"
                                        ?
                                            <AdminHomeSocials updateNavSocials={this.updateNavSocials} />
                                        :
                                        this.state.selectedTab === "games"
                                        ?
                                            <AdminGames />
                                        :
                                        this.state.selectedTab === "team"
                                        ?
                                            <AdminTeam />
                                        :
                                        this.state.selectedTab === "users"
                                        ?
                                            <AdminUsers user={this.state.user} />
                                        :
                                            <AdminChangePassword user={this.state.user} pushToSignIn={this.pushToSignIn} />
                                    }
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            :
                <Redirect to={{ pathname: "/auth/sign-in", state: { err: "You need to sign in or sign up before continuing" } }} />
        )
    }
}

export default AdminMain