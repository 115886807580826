import React from "react"
import axios from "axios"

import "./AdminTeamMember.scss"

let AdminTeamMember = (props) => {
    let changeToEdit = () => {
        props.changePanelType("edit", props.teamMember)
    }

    let deleteTeamMember = async () => {
        try {
            let deleteBtn = document.getElementById(`admin_team_member_delete_btn_${props.teamMember.id}`)
            deleteBtn.innerHTML = ""
            deleteBtn.classList.add("spinner")

            if(window.confirm("Are you sure you want to delete this team member")) {
                let teamMemberRes = await axios.delete(`/api/admin/delete/team-member/${props.teamMember.id}`)

                alert(teamMemberRes.data)

                deleteBtn.innerHTML = "Delete"
                deleteBtn.classList.remove("spinner")

                await props.getTeamMembersAgain()
            }
            else {
                deleteBtn.innerHTML = "Delete"
                deleteBtn.classList.remove("spinner")
            }
        }
        catch(err) {
            let deleteBtn = document.getElementById(`admin_team_member_delete_btn_${props.teamMember.id}`)

            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }

            deleteBtn.innerHTML = "Delete"
            deleteBtn.classList.remove("spinner")
        }
    }

    return (
        <div className="admin_team_member admin_list_item">
            <p>{props.teamMember.name}</p>
            <div className="admin_list_item_btn_container">
                <button onClick={changeToEdit}>Edit</button>
                <button id={`admin_team_member_delete_btn_${props.teamMember.id}`} 
                className="admin_spinner_container" 
                onClick={deleteTeamMember}>Delete</button>
            </div>
        </div>
    )
}

export default AdminTeamMember