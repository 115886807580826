import React, { Component } from "react"
import axios from "axios"

import AdminViewHomeVideos from "./Components/AdminViewHomeVideos/AdminViewHomeVideos"
import AdminAddHomeVideo from "./Components/AdminAddHomeVideo/AdminAddHomeVideo"
import AdminEditHomeVideo from "./Components/AdminEditHomeVideo/AdminEditHomeVideo"
import "./AdminHomeVideos.scss"

class AdminHomeVideos extends Component {
    constructor(props) {
        super(props)

        this.state = {
            panelType: "view",
            videos: [],
            editVideo: {},
            offset: 0,
            pages: 1,
            pageNumber: 1,
            allVideos: []
        }
    }

    async componentDidMount() {
        let videosRes = await axios.get("/api/admin/get/initial/home-videos")
        let allVideosRes = await axios.get("/api/admin/get/all/home-videos")

        let pages = videosRes.data[0] === 0 ? 1 : Math.ceil(videosRes.data[0] / 10)

        this.setState({
            pages: pages,
            offset: 0,
            pageNumber: 1,
            videos: videosRes.data[1],
            allVideos: allVideosRes.data
        })
    }

    getVideosAgain = async () => {
        let videosRes = await axios.get("/api/admin/get/initial/home-videos")
        let allVideosRes = await axios.get("/api/admin/get/all/home-videos")

        let pages = videosRes.data[0] === 0 ? 1 : Math.ceil(videosRes.data[0] / 10)

        this.setState({
            pages: pages,
            offset: 0,
            pageNumber: 1,
            videos: videosRes.data[1],
            allVideos: allVideosRes.data
        })
    }

    changePanelType = (type, edit) => {
        if(edit && type === "edit") {
            this.setState({
                panelType: type,
                editVideo: edit
            })
        }
        else {
            this.setState({
                panelType: type
            })
        }
    }

    previousPage = async () => {
        try {
            let videosRes = await axios.get(`/api/admin/get/home-videos/${this.state.offset - 10}`)

            this.setState({
                videos: videosRes.data,
                offset: this.state.offset - 10,
                pageNumber: this.state.pageNumber - 1
            })
        }
        catch(err) {
            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }

    nextPage = async () => {
        try {
            let videosRes = await axios.get(`/api/admin/get/home-videos/${this.state.offset + 10}`)

            this.setState({
                videos: videosRes.data,
                offset: this.state.offset + 10,
                pageNumber: this.state.pageNumber + 1
            })
        }
        catch(err) {
            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }

    render() {
        return (
            <div className="admin_home_videos">
                {
                    this.state.panelType === "view"
                    ?
                        <AdminViewHomeVideos changePanelType={this.changePanelType} 
                        getVideosAgain={this.getVideosAgain} 
                        videos={this.state.videos} 
                        pages={this.state.pages} 
                        pageNumber={this.state.pageNumber} 
                        previousPage={this.previousPage} 
                        nextPage={this.nextPage} />
                    :
                    this.state.panelType === "add"
                    ?
                        <AdminAddHomeVideo changePanelType={this.changePanelType} videosLength={this.state.allVideos.length} getVideosAgain={this.getVideosAgain} />
                    :
                        <AdminEditHomeVideo changePanelType={this.changePanelType} video={this.state.editVideo} getVideosAgain={this.getVideosAgain} videos={this.state.allVideos} />
                }
            </div>
        )
    }
}

export default AdminHomeVideos