import React from "react"
import { Route, Switch, Redirect } from "react-router-dom"

import Home from "./Views/Home/Home"
import Games from "./Views/Games/Games"
import Team from "./Views/Team/Team"
import Legal from "./Views/Legal/Legal"
import AdminMain from "./Views/AdminMain/AdminMain"
import AdminNew from "./Views/AdminNew/AdminNew"
import AdminSignIn from "./Views/AdminSignIn/AdminSignIn"
import AdminUnlock from "./Views/AdminUnlock/AdminUnlock"
import AdminResetPassword from "./Views/AdminResetPassword/AdminResetPassword"

export default <Switch>
    <Route exact path="/" component={Home} />
    <Route exact path="/games" component={Games} />
    <Route exact path="/team" component={Team} />
    <Route exact path="/admin" component={AdminMain} />
    <Route exact path="/legal" component={Legal} />
    <Route path="/auth/admin/new" component={AdminNew} />
    <Route path="/auth/sign-in" component={AdminSignIn} />
    <Route path="/auth/admin/unlock" component={AdminUnlock} />
    <Route path="/auth/admin/reset-password" component={AdminResetPassword} />
    <Route render={() => <Redirect to={{pathname: "/"}} />} />
</Switch>