import React, { Component } from "react"
import axios from "axios"

import "./AdminEditTeamMember.scss"

class AdminEditTeam extends Component {
    constructor(props) {
        super(props)

        this.state = {
            img: "",
            name: "",
            title: "",
            favoriteGameOne: "",
            favoriteGameTwo: "",
            imgName: "No file selected.",
            deleteImgName: "",
            oldImgName: "",
            order: 0,
            changeOrder: false,
            swapTeamMemberId: 0,
            manualOrderReset: false
        }
    }

    componentDidMount() {
        let oldImgName = this.props.teamMember.img.split("TeamMembers/")[1]
        this.setState({
            img: this.props.teamMember.img,
            name: this.props.teamMember.name,
            title: this.props.teamMember.title,
            favoriteGameOne: this.props.teamMember.favorite_game_one,
            favoriteGameTwo: this.props.teamMember.favorite_game_two,
            oldImgName: oldImgName,
            order: this.props.teamMember.team_order
        })
    }

    componentDidUpdate(prevProps) {
        if(this.props.teamMember.id !== prevProps.teamMember.id) {
            let oldImgName = this.props.teamMember.img.split("TeamMembers/")[1]
            this.setState({
                img: this.props.teamMember.img,
                name: this.props.teamMember.name,
                title: this.props.teamMember.title,
                favoriteGameOne: this.props.teamMember.favorite_game_one,
                favoriteGameTwo: this.props.teamMember.favorite_game_two,
                oldImgName: oldImgName,
                order: this.props.teamMember.team_order
            })
        }
    }

    changeToView = () => {
        this.props.changePanelType("view")
    }

    updateInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    
    uploadImg = async (e) => {
        try {
            let file = e.target.files[0]
            
            if(file.type === "image/png" || file.type === "image/bmp" || file.type === "image/jpeg" || file.type === "image/jpg" || file.type === "image/gif" || file.type === "image/x-icon") {
                let regex = /\s+|[,/\\]/g
                if(!file.name.match(regex)) {
                    if(((file.size / 1000) / 1000) > 10) {
                        alert("File is too large to store please upload a smaller image")
                    }
                    else {
                        const formData = new FormData()
                        
                        formData.append("teamMemberImg", file)
            
                        if(this.state.imgName !== "No file selected.") {
                            let deleteFile = new File(["yes"], "delete", { type: "text/plain" })
                            let imgNameFile = new File([`${this.state.deleteImgName}`], "iconName", { type: "text/plain" })
                            
                            formData.append("delete", deleteFile)
                            formData.append("imgName", imgNameFile)
                        }
                        
                        let imgRes = await axios.post("/api/admin/add/team-member-img", formData)
                        
                        e.target.value = null
                        
                        this.setState({
                            img: imgRes.data[0],
                            imgName: imgRes.data[1],
                            deleteImgName: imgRes.data[2]
                        })
                    }
                }
                else {
                    alert("There must be no spaces, forward slashes or back slashes in the image name")
                }
            }
            else {
                alert("Image must be of type png, jpg, gif, ico or bmp")
            }
        }
        catch(err) {
            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }

    changeTeamMemberOrder = (e) => {
        let teamMember = this.props.team.find((teamMember) => teamMember.team_order === Number(e.target.value))

        this.setState({
            order: Number(e.target.value),
            changeOrder: true,
            swapTeamMemberId: teamMember.id
        })
    }
    
    updateTeamMember = async () => {
        try {
            let updateBtn = document.getElementById("admin_edit_team_member_update_btn")
            updateBtn.innerHTML = ""
            updateBtn.classList.add("spinner")

            if(!this.state.img || !this.state.name || !this.state.title || !this.state.favoriteGameOne || !this.state.favoriteGameTwo) {
                alert("Must upload an image, add a name, add a title, add first favorite game and second favorite game")

                updateBtn.innerHTML = "Update"
                updateBtn.classList.remove("spinner")
            }
            else {
                let teamMemberRes = await axios.put("/api/admin/update/team-member", {
                    id: this.props.teamMember.id,
                    img: this.state.img,
                    name: this.state.name,
                    title: this.state.title,
                    favoriteGameOne: this.state.favoriteGameOne,
                    favoriteGameTwo: this.state.favoriteGameTwo,
                    changeOrder: this.state.changeOrder,
                    swapTeamMemberId: this.state.swapTeamMemberId
                })

                alert(teamMemberRes.data)

                updateBtn.innerHTML = "Update"
                updateBtn.classList.remove("spinner")

                if(this.state.imgName !== "No file selected.") {
                    await axios.delete(`/api/admin/delete/old-team-member-img/${this.state.oldImgName}`)
                }

                this.setState({
                    img: "",
                    name: "",
                    title: "",
                    favoriteGameOne: "",
                    favoriteGameTwo: "",
                    imgName: "No file selected.",
                    deleteImgName: "",
                    oldImgName: "",
                    order: 0,
                    changeOrder: false,
                    swapTeamMemberId: 0
                }, async () => {
                    await this.props.getTeamMembersAgain()
                    this.props.changePanelType("view")
                })
            }
        }
        catch(err) {
            let updateBtn = document.getElementById("admin_edit_team_member_update_btn")

            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }

            updateBtn.innerHTML = "Update"
            updateBtn.classList.remove("spinner")
        }
    }

    toggleOrderReset = () => {
        this.setState({
            manualOrderReset: !this.state.manualOrderReset
        })
    }


    manuallyUpdateOrder = async () => {
        try {
            let updateBtn = document.getElementById("team_update_order_btn")
            updateBtn.innerHTML = ""
            updateBtn.classList.add("spinner")

            if( this.state.order > 2147483647 || this.state.order <= 0) {
                alert("Order must be above 0 and less than 2147483647")

                updateBtn.innerHTML = "Update Order"
                updateBtn.classList.remove("spinner")
            }
            else {
                let orderRes = await axios.put("api/admin/update/team/order", { id: this.props.teamMember.id, order: this.state.order })

                alert(orderRes.data)

                updateBtn.innerHTML = "Update Order"
                updateBtn.classList.remove("spinner")

                await this.props.getTeamMembersAgain()
            }
        }
        catch(err) {
            let updateBtn = document.getElementById("team_update_order_btn")

            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }

            updateBtn.innerHTML = "Update Order"
            updateBtn.classList.remove("spinner")
        }
    }

    render() {
        const { REACT_APP_SERVER_HOST } = process.env
        let teamMembersOrder = this.props.team.map((teamMember, i) => {
            return (
                <option key={i} value={teamMember.team_order}>{teamMember.team_order}</option>
            )
        })
        return (
            <div className="admin_edit_team_member">
                <div className="admin_form_preview_container">
                    <div className="admin_form_container">
                        <div className="admin_input_container">
                            <p>Image (120 x 120):</p>
                            <div className="admin_file_upload_container">
                                <input type="file" 
                                accept="image/*" 
                                onChange={this.uploadImg} />
                                <p>{this.state.imgName}</p>
                            </div>
                        </div>
                        <div className="admin_input_container">
                            <p>Name:</p>
                            <input type="text" 
                            name="name" 
                            placeholder="Name" 
                            value={this.state.name} 
                            onChange={this.updateInput} />
                        </div>
                        <div className="admin_input_container">
                            <p>Title:</p>
                            <input type="text" 
                            name="title" 
                            placeholder="Title" 
                            value={this.state.title} 
                            onChange={this.updateInput} />
                        </div>
                        <div className="admin_input_container">
                            <p>First Favorite Game:</p>
                            <input type="text" 
                            name="favoriteGameOne" 
                            placeholder="first favorite game" 
                            value={this.state.favoriteGameOne} 
                            onChange={this.updateInput} />
                        </div>
                        <div className="admin_input_container">
                            <p>Second Favorite Game:</p>
                            <input type="text" 
                            name="favoriteGameTwo" 
                            placeholder="second favorite game" 
                            value={this.state.favoriteGameTwo} 
                            onChange={this.updateInput} />
                        </div>
                        <div className="admin_input_container">
                            <p>Order:</p>
                            <select name="order" value={this.state.order} onChange={this.changeTeamMemberOrder}>
                                {teamMembersOrder}
                            </select>
                        </div>
                        <div className="admin_input_container">
                            <p>Team member order not working correctly? Click the button below to manually update this team member's order</p>
                            {
                                this.state.manualOrderReset
                                ?
                                    <div className="admin_order_change_container">
                                        <button className="admin_store_order_reset_cancel_btn" onClick={this.toggleOrderReset}>X</button>
                                        <input type="number" 
                                        name="order" 
                                        placeholder="0" 
                                        value={this.state.order} 
                                        onChange={this.updateInput} />
                                        <button id="team_update_order_btn" 
                                        className="admin_spinner_container" 
                                        onClick={this.manuallyUpdateOrder}>Update Order</button>
                                    </div>
                                :
                                    <div className="admin_order_change_container">
                                        <button onClick={this.toggleOrderReset}>Set Order</button>
                                    </div>
                            }
                        </div>
                    </div>
                    <div className="admin_preview_container">
                        <div className="admin_edit_team_member_preview">
                        <div className="team_member_img_preview">
                                <img src={`${REACT_APP_SERVER_HOST}${this.state.img}`} alt="team_member" />
                            </div>
                            <div className="team_member_info_preview">
                                <div className="name_title_preivew">
                                    <span id="name">{this.state.name}</span>
                                    <span id="title">{this.state.title}</span>    
                                </div>
                                <div className="games_preview">
                                    <p>Favorite Games:</p>
                                    <p>- {this.state.favoriteGameOne}</p>
                                    <p>- {this.state.favoriteGameTwo}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="admin_btn_container">
                    <button onClick={this.changeToView}>Cancel</button>
                    <button id="admin_edit_team_member_update_btn" 
                    className="admin_spinner_container" 
                    disabled={!this.state.img || !this.state.name || !this.state.title || !this.state.favoriteGameOne || !this.state.favoriteGameTwo} 
                    onClick={this.updateTeamMember}>Update</button>
                </div>
            </div>
        )
    }
}

export default AdminEditTeam