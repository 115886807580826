import React from "react"

import "./Footer.scss"

let Footer = (props) => {
    return (
        <div className="page_footer">
            <p>&copy;2021 Re-Logic. All rights reserved |</p>
            <a href="/legal">Legal</a>
        </div>
    )
}

export default Footer