import React from "react"

import AdminHomeSocial from "./Components/AdminHomeSocial/AdminHomeSocial"
import "./AdminViewHomeSocials.scss"

let AdminViewHomeSocial = (props) => {
    let changeToAdd = () => {
        props.changePanelType("add")
    }

    let socials = props.socials.map((social, i) => {
        return (
            <AdminHomeSocial key={i} social={social} getSocialsAgain={props.getSocialsAgain} changePanelType={props.changePanelType} />
        )
    })

    return (
        <div className="admin_view_home_socials">
            <div className="admin_view_page_info_container">
                <h4>
                    Navbar Socials page - {props.pageNumber}
                </h4>
                <button onClick={changeToAdd}>New</button>
            </div>
            <div className="admin_home_socials_socials_container">
                {socials}
            </div>
            <div className="admin_previous_next_container">
                <button disabled={props.pageNumber === 1} onClick={props.previousPage}>Previous</button>
                <button disabled={props.pageNumber === props.pages} onClick={props.nextPage}>Next</button>
            </div>
        </div>
    )
}

export default AdminViewHomeSocial