import React from "react"

import "./Game.scss"

let Game = (props) => {
    const { REACT_APP_SERVER_HOST } = process.env
    let className = props.extended === props.game.id ? "game game_extended" : "game game_retracted"
    let regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
    let match = props.game.video.match(regExp)
    let descriptionLines
        if(props.game.description.replace(/\r\n|\r|\n/g, "/newline").split("/newline")) {
            descriptionLines = props.game.description.replace(/\r\n|\r|\n/g, "/newline").split("/newline").filter((text) => text).map((text, i) => {
                return (
                    <p key={i}>{text}</p>
                )
            })
        }
        else {
            descriptionLines = this.props.description
        }
    return (
        <div className={className} 
        style={{ "backgroundImage": `url("${REACT_APP_SERVER_HOST}${props.game.background_img}")` }} 
        onClick={() => props.extendGame(props.game.id)}>
            <div className="collapsed_content">
                <img className="logo" src={`${REACT_APP_SERVER_HOST}${props.game.logo}`} alt="logo" style={{ "marginTop": `${props.game.margin_top}px`, "marginLeft": `${props.game.margin_left}px` }} />
            </div>
            <div className="game_inner_wrapper">
                <div className="extended_content">
                    <div className="game_info">
                        <h2 id={props.game.name.toLowerCase().replace(/\s/g, "_")}>{props.game.name}</h2>
                        <h4 id={props.game.developer.toLowerCase().replace(/\s/g, "_")}>By {props.game.developer}</h4>
                        {descriptionLines}
                        {
                            props.game.website
                            ?
                                <div className="links">
                                    <a rel="noreferrer" target="_blank" href={`${props.game.website}`}>Official Website</a>
                                </div>
                            :
                                null
                        }
                    </div>
                    <div className="youtube_container">
                        {
                            match && match[2] >= 11
                            ?
                                <p>Error embedding youtube video</p>
                            :
                            <iframe src={`https://www.youtube.com/embed/${match[2]}?feature=player_detailpage&autoplay=0&rel=0`} 
                            frameBorder="0" 
                            allowFullScreen 
                            height="315" 
                            width="560" 
                            title="Game Video"></iframe>
                        }
                    </div>
                </div>
                <div className="game_footer"></div>
            </div>
        </div>
    )
}

export default Game