import React, { Component } from "react"
import axios from "axios"

import "./AdminAddHomeSocial.scss"

class AdminAddHomeSocial extends Component {
    constructor(props) {
        super(props)

        this.state = {
            name: "",
            img: "",
            imgName: "No file selected.",
            deleteImgName: "",
            url: ""
        }
    }

    changeToView = () => {
        this.props.changePanelType("view")
    }

    updateInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    uploadImg = async (e) => {
        try {
            let file = e.target.files[0]
    
            if(file.type === "image/png" || file.type === "image/bmp" || file.type === "image/jpeg" || file.type === "image/jpg" || file.type === "image/gif" || file.type === "image/x-icon") {
                let regex = /\s+|[,/\\]/g
                if(!file.name.match(regex)) {
                    if(((file.size / 1000) / 1000) > 10) {
                        alert("File is too large to store please upload a smaller image")
                    }
                    else {
                        const formData = new FormData()
            
                        formData.append("homeSocialImg", file)
            
                        if(this.state.imgName !== "No file selected.") {
                            let deleteFile = new File(["yes"], "delete", { type: "text/plain" })
                            let imgNameFile = new File([`${this.state.deleteImgName}`], "iconName", { type: "text/plain" })
                
                            formData.append("delete", deleteFile)
                            formData.append("imgName", imgNameFile)
                        }
    
                        let imgRes = await axios.post("/api/admin/add/home-social-img", formData)
    
                        e.target.value = null
    
                        this.setState({
                            img: imgRes.data[0],
                            imgName: imgRes.data[1],
                            deleteImgName: imgRes.data[2]
                        })
                    }
                }
                else {
                    alert("There must be no spaces, forward slashes or back slashes in the image name")
                }
            }
            else {
                alert("Image must be of type png, jpg, gif, ico or bmp")
            }
        }
        catch(err) {
            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }

    addHomeSocial = async () => {
        try {
            let addBtn = document.getElementById("admin_add_home_social_btn")
            addBtn.innerHTML = ""
            addBtn.classList.add("spinner")

            if(!this.state.name || !this.state.img || !this.state.url) {
                alert("You must add a name, upload an image and add an url")
                
                addBtn.innerHTML = "Add"
                addBtn.classList.remove("spinner")
            }
            else {
                let regExp = /^(ftp|http|https):\/\/[^ "]+$/
                if(this.state.url.match(regExp)) {
                    let socialRes = await axios.post("/api/admin/add/home-social", { name: this.state.name, img: this.state.img, url: this.state.url, order: this.props.socialsLength + 1 })

                    alert(socialRes.data)

                    addBtn.innerHTML = "Add"
                    addBtn.classList.remove("spinner")

                    this.setState({
                        name: "",
                        img: "",
                        imgName: "No file selected.",
                        deleteImgName: "",
                        url: ""
                    }, async () => {
                        await this.props.updateNavSocials()
                        await this.props.getSocialsAgain()
                        this.props.changePanelType("view")
                    })
                }
                else {
                    alert("Invalid url")

                    addBtn.innerHTML = "Add"
                    addBtn.classList.remove("spinner")
                }
            }
        }
        catch(err) {
            let addBtn = document.getElementById("admin_add_home_social_btn")

            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }

            addBtn.innerHTML = "Add"
            addBtn.classList.remove("spinner")
        }
    }

    render() {
        const { REACT_APP_SERVER_HOST } = process.env
        return (
            <div className="admin_add_home_social">
                <h4>
                    <strong>Admin Navbar Socials:</strong>
                </h4>
                <div className="admin_form_preview_container">
                    <div className="admin_form_container">
                        <div className="admin_input_container">
                            <p>Name:</p>
                            <input type="text" 
                            name="name" 
                            placeholder="Name" 
                            value={this.state.name} 
                            onChange={this.updateInput} />
                        </div>
                        <div className="admin_input_container">
                            <p>Image (17 x 15):</p>
                            <div className="admin_file_upload_container">
                                <input type="file" 
                                accept="image/*" 
                                onChange={this.uploadImg} />
                                <p>{this.state.imgName}</p>
                            </div>
                        </div>
                        <div className="admin_input_container">
                            <p>URL:</p>
                            <input type="text" 
                            name="url" 
                            placeholder="https://example.com" 
                            value={this.state.url} 
                            onChange={this.updateInput} />
                        </div>
                    </div>
                    <div className="admin_preview_container">
                        <div className="admin_add_social_preview">
                            <p>FOLLOW US</p>
                            <div className="logo_preview" style={{"backgroundImage": `url("${REACT_APP_SERVER_HOST}${this.state.img}")`}}></div>
                        </div>
                    </div>
                </div>
                <div className="admin_btn_container">
                    <button onClick={this.changeToView}>Cancel</button>
                    <button id="admin_add_home_social_btn" 
                    className="admin_spinner_container" 
                    disabled={!this.state.name || !this.state.img || !this.state.url} 
                    onClick={this.addHomeSocial}>Add</button>
                </div>
            </div>
        )
    }
}

export default AdminAddHomeSocial