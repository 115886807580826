import axios from "axios"
import React from "react"

import "./AdminHomeSocial.scss"

let AdminSingleHomeSocial = (props) => {
    let changeToEdit = () => {
        props.changePanelType("edit", props.social)
    }

    let deleteSocial = async () => {
        try {
            let deleteBtn = document.getElementById(`admin_home_social_delete_btn_${props.social.id}`)
            deleteBtn.innerHTML = ""
            deleteBtn.classList.add("spinner")

            if(window.confirm("Are you sure you want to delete this home social?")) {
                let socialRes = await axios.delete(`/api/admin/delete/home-social/${props.social.id}`)

                alert(socialRes.data)

                deleteBtn.innerHTML = "Delete"
                deleteBtn.classList.remove("spinner")

                await props.getSocialsAgain()
            }
            else {
                deleteBtn.innerHTML = "Delete"
                deleteBtn.classList.remove("spinner")
            }
        }
        catch(err) {
            let deleteBtn = document.getElementById(`admin_home_social_delete_btn_${props.social.id}`)

            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }

            deleteBtn.innerHTML = "Delete"
            deleteBtn.classList.remove("spinner")
        }
    }

    return (
        <div className="admin_home_social admin_list_item">
            <p>{props.social.name}</p>
            <div className="admin_list_item_btn_container">
                <button onClick={changeToEdit}>Edit</button>
                <button id={`admin_home_social_delete_btn_${props.social.id}`} 
                className="admin_spinner_container" 
                onClick={deleteSocial}>Delete</button>
            </div>
        </div>
    )
}

export default AdminSingleHomeSocial