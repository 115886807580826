import React, { Component } from "react"
import axios from "axios"

import AdminPreviewHomeVideo from "../AdminPreviewHomeVideo/AdminPreviewHomeVideo"
import "./AdminAddHomeVideo.scss"

class AdminAddHomeVideo extends Component {
    constructor(props) {
        super(props)

        this.state = {
            name: "",
            videoSource: "",
            backupSource: "",
            logo: "",
            iconActive: "",
            iconGreyed : "",
            videoName: "No file selected.",
            backupVideoName: "No file selected.",
            logoName: "No file selected.",
            iconActiveName: "No file selected.",
            iconGreyedName: "No file selected.",
            deleteVideoName: "",
            deleteBackupVideoName: "",
            deleteLogoName: "",
            deleteIconActiveName: "",
            deleteIconGreyedName: "",
            previewShow: false,
            videoMimeType: "",
            backupMimeType: "",
            url: "",
            errMessage: ""
        }
    }

    changeToView = () => {
        this.props.changePanelType("view")
    }

    updateInput = (e) => {
        if(e.target.name === "url") {
            let regExp = /^(ftp|http|https):\/\/[^ "]+$/

            if(e.target.value === "") {
                this.setState({
                    [e.target.name]: e.target.value,
                    errMessage: ""
                })
            }
            else {
                if(e.target.value.match(regExp)) {
                    this.setState({
                        [e.target.name]: e.target.value,
                        errMessage: ""
                    })
                }
                else {
                    this.setState({
                        [e.target.name]: e.target.value,
                        errMessage: "Invalid URL"
                    })
                }
            }
        } 
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    uploadHomeVideo = async (e) => {
        try {
            let file = e.target.files[0]
    
            if(file.type === "video/mp4" || file.type=== "video/webm" || file.type === "video/m4v" || file.type === "video/avi" || file.type === "video/mpg") {
                let regex = /\s+|[,/\\]/g
                if(!file.name.match(regex)) {
                    if(((file.size / 1000) / 1000) > 10) {
                        alert("File is too large to store please upload a smaller video")
                    }
                    else {
                        const formData = new FormData()
            
                        formData.append("homeVideo", file)
            
                        if(e.target.name === "videoSource") {
                            if(this.state.videoName !== "No file selected.") {
                                let deleteFile = new File(["yes"], "delete", { type: "text/plain" })
                                let videoNameFile = new File([`${this.state.deleteVideoName}`], "videoName", { type: "text/plain" })
                
                                formData.append("delete", deleteFile)
                                formData.append("videoName", videoNameFile)
                            }
                        }
                        else {
                            if(this.state.backupVideoName !== "No file selected.") {
                                let deleteFile = new File(["yes"], "delete", { type: "text/plain" })
                                let videoNameFile = new File([`${this.state.deleteBackupVideoName}`], "videoName", { type: "text/plain" })
                
                                formData.append("delete", deleteFile)
                                formData.append("videoName", videoNameFile)
                            }
                        }
    
                        let homeVideoRes = await axios.post("/api/admin/add/home-video-source", formData)
    
                        e.target.value = null
    
                        if(e.target.name === "videoSource") {
                            this.setState({
                                videoSource: homeVideoRes.data[0],
                                videoName: homeVideoRes.data[1],
                                deleteVideoName: homeVideoRes.data[2],
                                videoMimeType: homeVideoRes.data[3]
                            })
                        }
                        else {
                            this.setState({
                                backupSource: homeVideoRes.data[0],
                                backupVideoName: homeVideoRes.data[1],
                                deleteBackupVideoName: homeVideoRes.data[2],
                                backupMimeType: homeVideoRes.data[3]
                            })
                        }
                    }
                }
                else {
                    alert("There must be no spaces, forward slashes or back slashes in the video name")
                }
            }
            else {
                alert("Video must be of type mp4, webm, m4v, avi or mpg")
            }
        }
        catch(err) {
            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }

    uploadHomeVideoLogo = async (e) => {
        try {
            let file = e.target.files[0]
    
            if(file.type === "image/png" || file.type === "image/bmp" || file.type === "image/jpeg" || file.type === "image/jpg" || file.type === "image/gif" || file.type === "image/x-icon") {
                let regex = /\s+|[,/\\]/g
                if(!file.name.match(regex)) {
                    if(((file.size / 1000) / 1000) > 10) {
                        alert("File is too large to store please upload a smaller image")
                    }
                    else {
                        const formData = new FormData()
            
                        formData.append("homeVideoLogo", file)
            
                        if(this.state.logoName !== "No file selected.") {
                            let deleteFile = new File(["yes"], "delete", { type: "text/plain" })
                            let imgNameFile = new File([`${this.state.deleteLogoName}`], "logoName", { type: "text/plain" })
                
                            formData.append("delete", deleteFile)
                            formData.append("imgName", imgNameFile)
                        }
    
                        let logoRes = await axios.post("/api/admin/add/logo", formData)
    
                        e.target.value = null
    
                        this.setState({
                            logo: logoRes.data[0],
                            logoName: logoRes.data[1],
                            deleteLogoName: logoRes.data[2]
                        })
                    }
                }
                else {
                    alert("There must be no spaces, forward slashes or back slashes in the image name")
                }
            }
            else {
                alert("Image must be of type png, jpg, gif, ico or bmp")
            }
        }
        catch(err) {
            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }

    uploadHomeVideoIcon = async (e) => {
        try {
            let file = e.target.files[0]
    
            if(file.type === "image/png" || file.type === "image/bmp" || file.type === "image/jpeg" || file.type === "image/jpg" || file.type === "image/gif" || file.type === "image/x-icon") {
                let regex = /\s+|[,/\\]/g
                if(!file.name.match(regex)) {
                    if(((file.size / 1000) / 1000) > 10) {
                        alert("File is too large to store please upload a smaller image")
                    }
                    else {
                        const formData = new FormData()
            
                        formData.append("homeVideoIcon", file)
            
                        if(e.target.name === "iconActive") {
                            if(this.state.iconActiveName !== "No file selected.") {
                                let deleteFile = new File(["yes"], "delete", { type: "text/plain" })
                                let imgNameFile = new File([`${this.state.deleteIconActiveName}`], "iconName", { type: "text/plain" })
                    
                                formData.append("delete", deleteFile)
                                formData.append("imgName", imgNameFile)
                            }
                        }
                        else {
                            if(this.state.iconGreyedName !== "No file selected.") {
                                let deleteFile = new File(["yes"], "delete", { type: "text/plain" })
                                let imgNameFile = new File([`${this.state.deleteIconGreyedName}`], "iconName", { type: "text/plain" })
                    
                                formData.append("delete", deleteFile)
                                formData.append("imgName", imgNameFile)
                            }
                        }
    
                        let iconRes = await axios.post("/api/admin/add/icon", formData)
    
                        e.target.value = null
    
                        if(e.target.name === "iconActive") {
                            this.setState({
                                iconActive: iconRes.data[0],
                                iconActiveName: iconRes.data[1],
                                deleteIconActiveName: iconRes.data[2]
                            })
                        }
                        else {
                            this.setState({
                                iconGreyed: iconRes.data[0],
                                iconGreyedName: iconRes.data[1],
                                deleteIconGreyedName: iconRes.data[2]
                            })
                        }
                    }
                }
                else {
                    alert("There must be no spaces, forward slashes or back slashes in the image name")
                }
            }
            else {
                alert("Image must be of type png, jpg, gif, ico or bmp")
            }
        }
        catch(err) {
            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }

    openClosePreview = () => {
        this.setState({
            previewShow: !this.state.previewShow
        })
    }

    addHomeVideo = async () => {
        try {
            let addBtn = document.getElementById("admin_add_home_video_btn")
            addBtn.innerHTML = ""
            addBtn.classList.add("spinner")

            if(!this.state.name || !this.state.videoSource || !this.state.backupSource ||  !this.state.logo || !this.state.iconActive || !this.state.iconGreyed) {
                alert("Must have a name, video source uploaded, backup source uploaded, logo uploaded, a greyed out icon uploaded and an icon uploaded")

                addBtn.innerHTML = "Add"
                addBtn.classList.remove("spinner")
            }
            else {
                if(this.state.url) {
                    let regExp = /^(ftp|http|https):\/\/[^ "]+$/
                    if(this.state.url.match(regExp)) {
                        let homeVideoRes = await axios.post("api/admin/add/home-video", {
                            name: this.state.name,
                            videoSource: this.state.videoSource,
                            backupSource: this.state.backupSource,
                            logo: this.state.logo,
                            iconActive: this.state.iconActive,
                            iconGreyed: this.state.iconGreyed,
                            order: this.props.videosLength + 1,
                            videoMimeType: this.state.videoMimeType,
                            backupMimeType: this.state.backupMimeType,
                            url: this.state.url
                        })
        
                        alert(homeVideoRes.data)
        
                        addBtn.innerHTML = "Add"
                        addBtn.classList.remove("spinner")
        
                        this.setState({
                            name: "",
                            videoSource: "",
                            backupSource: "",
                            logo: "",
                            iconActive : "",
                            iconGreyed: "",
                            videoName: "No file selected.",
                            backupVideoName: "No file selected.",
                            logoName: "No file selected.",
                            iconName: "No file selected.",
                            deleteVideoName: "",
                            deleteBackupVideoName: "",
                            deleteLogoName: "",
                            deleteIconName: "",
                            url: ""
                        }, async () => {
                            await this.props.getVideosAgain()
                            this.props.changePanelType("view")
                        })
                    }
                    else {
                        alert("Invalid URL if you are going to add a url it needs to be valid")
    
                        addBtn.innerHTML = "Add"
                        addBtn.classList.remove("spinner")
                    }

                }
                else {
                    let homeVideoRes = await axios.post("api/admin/add/home-video", {
                        name: this.state.name,
                        videoSource: this.state.videoSource,
                        backupSource: this.state.backupSource,
                        logo: this.state.logo,
                        iconActive: this.state.iconActive,
                        iconGreyed: this.state.iconGreyed,
                        order: this.props.videosLength + 1,
                        videoMimeType: this.state.videoMimeType,
                        backupMimeType: this.state.backupMimeType,
                        url: this.state.url
                    })
    
                    alert(homeVideoRes.data)
    
                    addBtn.innerHTML = "Add"
                    addBtn.classList.remove("spinner")
    
                    this.setState({
                        name: "",
                        videoSource: "",
                        backupSource: "",
                        logo: "",
                        iconActive : "",
                        iconGreyed: "",
                        videoName: "No file selected.",
                        backupVideoName: "No file selected.",
                        logoName: "No file selected.",
                        iconName: "No file selected.",
                        deleteVideoName: "",
                        deleteBackupVideoName: "",
                        deleteLogoName: "",
                        deleteIconName: "",
                        url: ""
                    }, async () => {
                        await this.props.getVideosAgain()
                        this.props.changePanelType("view")
                    })
                }
            }
        }
        catch(err) {
            let addBtn = document.getElementById("admin_add_home_video_btn")

            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }

            addBtn.innerHTML = "Add"
            addBtn.classList.remove("spinner")
        }
    }

    render() {
        let className = this.state.errMessage ? "err_input" : "input"
        return (
            <div className="admin_add_home_video">
                <h4>
                    <strong>Admin Home Videos:</strong>
                </h4>
                <div className="admin_form_container">
                    <div className="admin_input_container">
                        <p>Name:</p>
                        <input type="text" 
                        name="name" 
                        placeholder="Name" 
                        value={this.state.name} 
                        onChange={this.updateInput} />
                    </div>
                    <div className="admin_input_container">
                        <p>Video Source:</p>
                        <p>Recomemended File Type (mp4):</p>
                        <div className="admin_file_upload_container">
                            <input type="file" 
                            name="videoSource" 
                            accept="video/*" 
                            onChange={this.uploadHomeVideo} />
                            <p>{this.state.videoName}</p>
                        </div>
                    </div>
                    <div className="admin_input_container">
                        <p>Backup Video Source:</p>
                        <p>Recomemended File Type (webm):</p>
                        <div className="admin_file_upload_container">
                            <input type="file" 
                            name="backupSource" 
                            accept="video/*" 
                            onChange={this.uploadHomeVideo} />
                            <p>{this.state.backupVideoName}</p>
                        </div>
                    </div>
                    <div className="admin_input_container">
                        <p>Logo (no more than 400 x 150):</p>
                        <div className="admin_file_upload_container">
                            <input type="file" 
                            accept="image/*" 
                            onChange={this.uploadHomeVideoLogo} />
                            <p>{this.state.logoName}</p>
                        </div>
                    </div>
                    <div className="admin_input_container">
                        <p>Icon Greyed Out (36 x 36):</p>
                        <div className="admin_file_upload_container">
                            <input type="file" 
                            name="iconGreyed" 
                            accept="image/*" 
                            onChange={this.uploadHomeVideoIcon} />
                            <p>{this.state.iconGreyedName}</p>
                        </div>
                    </div>
                    <div className="admin_input_container">
                        <p>Icon Active (36 x 36):</p>
                        <div className="admin_file_upload_container">
                            <input type="file" 
                            name="iconActive" 
                            accept="image/*" 
                            onChange={this.uploadHomeVideoIcon} />
                            <p>{this.state.iconActiveName}</p>
                        </div>
                    </div>
                    <div className="admin_input_container">
                        <p>URL (optional): (leads to the video game website)</p>
                        {
                            this.state.errMessage
                            ?
                                <p className="err_message">{this.state.errMessage}</p>
                            :
                                null
                        }
                        <input type="text"  
                        className={className} 
                        name="url" 
                        placeholder="https://example.com" 
                        value={this.state.url} 
                        onChange={this.updateInput} />
                    </div>
                    <div className="admin_btn_container">
                        <button onClick={this.changeToView}>Cancel</button>
                        <div className="admin_two_btn">
                            <button onClick={this.openClosePreview}>Preview</button>
                            <button id="admin_add_home_video_btn" 
                            className="admin_spinner_container" 
                            disabled={!this.state.name || !this.state.videoSource || !this.state.backupSource || !this.state.logo || !this.state.iconActive || !this.state.iconGreyed} 
                            onClick={this.addHomeVideo}>Add</button>
                        </div>
                    </div>
                </div>
                <AdminPreviewHomeVideo show={this.state.previewShow} 
                videoSource={this.state.videoSource} 
                backupSource={this.state.backupSource} 
                logo={this.state.logo} 
                iconGreyed={this.state.iconGreyed}
                iconActive={this.state.iconActive} 
                openClosePreview={this.openClosePreview} 
                videoMimeType={this.state.videoMimeType} 
                backupMimeType={this.state.backupMimeType} />
            </div>
        )
    }
}

export default AdminAddHomeVideo