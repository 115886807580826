import React, { Component } from "react"
import axios from "axios"

import Navbar from "../../ReusableComponents/Navbar/Navbar"
import Footer from "../../ReusableComponents/Footer/Footer"
import Game from "./Components/Game/Game"
import GameOverlay from "./Components/GameOverlay/GameOverlay"
import "./Games.scss"

class Games extends Component {
    constructor(props) {
        super(props)

        this.state = {
            games: [],
            extended: 0
        }
    }

    async componentDidMount() {
        document.title = "Games - Re-Logic"
        
        let gamesRes = await axios.get("/api/user/get/games")

        this.setState({
            games: gamesRes.data
        })
    }

    extendGame = (game) => {
        if(this.state.extended === game) {
            this.setState({
                extended: 0
            })
        }
        else {
            this.setState({
                extended: game
            })
        }
    }

    render() {
        let games = this.state.games.map((game, i) => {
            return (
                <Game key={i} game={game} extended={this.state.extended} extendGame={this.extendGame} />
            )
        })
        let overlays = this.state.games.map((overlay, i) => {
            return (
                <GameOverlay key={i} overlay={overlay} activeOverlay={this.state.extended} />
            )
        })
        return (
            <div className="main_wrapper">
                <div className="games">
                    <Navbar />
                    <div className="game_overlays_container">
                        {overlays}
                    </div>
                    <div className="games_games_container">
                        {games}
                    </div>
                    <Footer />
                </div>
            </div>
        )
    }
}

export default Games