import React, { Component } from "react"
import { Link, withRouter } from "react-router-dom"
import axios from "axios"

import NavbarSocial from "./Components/NavbarSocial/NavbarSocial"
import "./Navbar.scss"

class Navbar extends Component {
    constructor(props) {
        super(props)

        this.state = {
            socials: []
        }
    }

    async componentDidMount() {
        let socialsRes = await axios.get("/api/user/get/navbar-socials")

        this.setState({
            socials: socialsRes.data
        })
    }

    async componentDidUpdate(prevProps) {
        if(this.props.updateNav === true && prevProps.updateNav === false) {
            let socialsRes = await axios.get("/api/user/get/navbar-socials")

            this.setState({
                socials: socialsRes.data
            }, async () => {
                await this.props.updateNavSocials()
            })
        }
    }

    render() {
        let homeClass = this.props.match.path === "/" ? "nav_btn_active nav_btn" : "nav_btn"
        let gamesClass = this.props.match.path === "/games" ? "nav_btn_active nav_btn" : "nav_btn"
        let teamClass = this.props.match.path === "/team" ? "nav_btn_active nav_btn" : "nav_btn"
        let navbarSocial = this.state.socials.map((social, i) => {
            return (
                <NavbarSocial key={i} social={social} />
            )
        })
        return (
            <nav className="navbar">
                <div className="content_wrapper">
                    <div className="logo_ul_container">
                        <a href="/">
                            <div className="logo"></div>
                        </a>
                        <div className="ul_social_container">
                            <ul>
                                <li className={homeClass}>
                                    <Link to="/">HOME</Link>
                                </li>
                                <li className={gamesClass}>
                                        <Link to="/games">GAMES</Link>
                                </li>
                                <li className={teamClass}>
                                    <Link to="/team">TEAM</Link>
                                </li>
                            </ul>
                            <div className="social_links">
                                <span>FOLLOW US</span>
                                {navbarSocial}
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        )
    }
}

export default withRouter(Navbar)