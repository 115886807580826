import React, { Component } from "react"
import axios from "axios"
import { Redirect } from "react-router-dom"

import Navbar from "../../ReusableComponents/Navbar/Navbar"
import Footer from "../../ReusableComponents/Footer/Footer"
import "./AdminResetPassword.scss"

class AdminResetPassword extends Component {
    constructor(props) {
        super(props)

        this.state = {
            pageStatus: "loading",
            user: {},
            password: "",
            confirmPassword: "",
            passwordIcon: "/PasswordVisibility/hide_password.png",
            confirmPasswordIcon: "/PasswordVisibility/hide_password.png",
            passwordVisibility: "hide",
            confirmPasswordVisibility: "hide"
        }
    }

    async componentDidMount() {
        try {
            const urlParams = new URLSearchParams(this.props.location.search)

            let email = urlParams.get("email")
            let token = urlParams.get("token")

            if(email === null || token === null) {
                this.setState({
                    pageStatus: "redirect"
                })
            }
            else {
                let resetPasswordRes = await axios.put(`/auth-serv/admin/check/reset-password`, { email, token })
                this.setState({
                    pageStatus: "authorized",
                    user: resetPasswordRes.data
                })
            }
        }
        catch(err) {
            if(err.response) {
                if(err.response.status) {
                    if(err.response.status === 401 || err.response.status === 403 || err.response.status === 500) {
                        if(err.response.data) {
                            alert(err.response.data)
                        }

                        this.setState({
                            pageStatus: "redirect"
                        })
                    }
                }
            }
        }
    }

    updateInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    showHidePassword = () => {
        let passwordInput = document.getElementById("reset_password_input")

        if(this.state.passwordVisibility === "hide") {
            passwordInput.type = "text"

            this.setState({
                passwordVisibility: "show",
                passwordIcon: "/PasswordVisibility/show_password.png"
            })
        }
        else {
            passwordInput.type = "password"

            this.setState({
                passwordVisibility: "hide",
                passwordIcon: "/PasswordVisibility/hide_password.png"
            })
        }
    }

    showHideConfirmPassword = () => {
        let passwordInput = document.getElementById("reset_confirm_password_input")

        if(this.state.confirmPasswordVisibility === "hide") {
            passwordInput.type = "text"

            this.setState({
                confirmPasswordVisibility: "show",
                confirmPasswordIcon: "/PasswordVisibility/show_password.png"
            })
        }
        else {
            passwordInput.type = "password"

            this.setState({
                confirmPasswordVisibility: "hide",
                confirmPasswordIcon: "/PasswordVisibility/hide_password.png"
            })
        }
    }

    resetPassword = async () => {
        try {
            let resetPasswordBtn = document.getElementById("admin_reset_password_btn")
            resetPasswordBtn.innerHTML = ""
            resetPasswordBtn.classList.add("spinner")

            if(!this.state.password || !this.state.confirmPassword) {
                alert("Please fill out both password fields")

                resetPasswordBtn.innerHTML = "Reset Password"
                resetPasswordBtn.classList.remove("spinner")
            }
            else {
                if(this.state.password === this.state.confirmPassword) {
                    let resetPasswordRes = await axios.put("/auth-serv/admin/change/password", { id: this.state.user.id, email: this.state.user.email, password: this.state.password })

                    alert(resetPasswordRes.data)

                    resetPasswordBtn.innerHTML = "Reset Password"
                    resetPasswordBtn.classList.remove("spinner")

                    this.props.history.push("/auth/sign-in")
                }
                else {
                    alert("Passwords do not match")

                    resetPasswordBtn.innerHTML = "Reset Password"
                    resetPasswordBtn.classList.remove("spinner")
                }
            }
        }
        catch(err) {
            let resetPasswordBtn = document.getElementById("admin_reset_password_btn")

            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }

            resetPasswordBtn.innerHTML = "Reset Password"
            resetPasswordBtn.classList.remove("spinner")
        }
    }

    render() {
        const { REACT_APP_SERVER_HOST } = process.env
        return (
            this.state.pageStatus === "loading"
            ?
                <div className="admin_reset_password"></div>
            :
            this.state.pageStatus === "authorized"
            ?
                <div className="main_wrapper">
                    <div className="admin_reset_password">
                        <Navbar />
                        <div className="reset_password_panel panel">
                            <header className="reset_password_header">
                                <h3>
                                    <b>Reset your password</b>
                                </h3>
                            </header>
                            <div className="reset_password_content">
                                <div className="admin_form_container">
                                    <div className="admin_input_container">
                                        <p>Password:</p>
                                        <div className="admin_password_input_container">
                                            <input type="password" 
                                            name="password" 
                                            id="reset_password_input" 
                                            placeholder="Password" 
                                            value={this.state.password} 
                                            onChange={this.updateInput} />
                                            <img src={`${REACT_APP_SERVER_HOST}${this.state.passwordIcon}`} alt="password_icon" onClick={this.showHidePassword} />
                                        </div>
                                    </div>
                                    <div className="admin_input_container">
                                        <p>Confirm Password:</p>
                                        <div className="admin_password_input_container">
                                            <input type="password" 
                                            name="confirmPassword" 
                                            id="reset_confirm_password_input" 
                                            placeholder="Password" 
                                            value={this.state.confirmPassword} 
                                            onChange={this.updateInput} />
                                            <img src={`${REACT_APP_SERVER_HOST}${this.state.confirmPasswordIcon}`} alt="password_icon" onClick={this.showHideConfirmPassword} />
                                        </div>
                                    </div>
                                    <div className="admin_btn_container">
                                        <button id="admin_reset_password_btn" 
                                        className="admin_spinner_container" 
                                        disabled={!this.state.password || !this.state.confirmPassword} 
                                        onClick={this.resetPassword}>Reset Password</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            :
                <Redirect to={{pathname: "/"}} />
        )
    }
}

export default AdminResetPassword