import React, { Component } from "react"
import axios from "axios"

import AdminPreviewGame from "../AdminPreviewGame/AdminPreviewGame"
import "./AdminAddGame.scss"

class AdminAddGame extends Component {
    constructor(props) {
        super(props)

        this.state = {
            name: "",
            description: "",
            developer: "",
            backgroundImg: "",
            logo: "",
            overlay: "",
            video: "",
            website: "",
            marginTop: 0,
            marginLeft: 0,
            backgroundImgName: "No file selected.",
            logoImgName: "No file selected.",
            overlayImgName: "No file selected.",
            deleteBackgroundImgName: "",
            deleteLogoImgName: "",
            deleteOverlayImgName: "",
            previewShow: false
        }
    }

    changeToView = () => {
        this.props.changePanelType("view")
    }

    updateInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    uploadBackgroundImage = async (e) => {
        try {
            let file = e.target.files[0]
    
            if(file.type === "image/png" || file.type === "image/bmp" || file.type === "image/jpeg" || file.type === "image/jpg" || file.type === "image/gif" || file.type === "image/x-icon") {
                let regex = /\s+|[,/\\]/g
                if(!file.name.match(regex)) {
                    if(((file.size / 1000) / 1000) > 10) {
                        alert("File is too large to store please upload a smaller image")
                    }
                    else {
                        const formData = new FormData()
            
                        formData.append("gameBackgroundImg", file)
            
                        if(this.state.backgroundImgName !== "No file selected.") {
                            let deleteFile = new File(["yes"], "delete", { type: "text/plain" })
                            let imgNameFile = new File([`${this.state.deleteBackgroundImgName}`], "backgroundName", { type: "text/plain" })
                
                            formData.append("delete", deleteFile)
                            formData.append("imgName", imgNameFile)
                        }
    
                        let backgroundImgRes = await axios.post("/api/admin/add/game-background", formData)
    
                        e.target.value = null
    
                        this.setState({
                            backgroundImg: backgroundImgRes.data[0],
                            backgroundImgName: backgroundImgRes.data[1],
                            deleteBackgroundImgName: backgroundImgRes.data[2]
                        })
                    }
                }
                else {
                    alert("There must be no spaces, forward slashes or back slashes in the image name")
                }
            }
            else {
                alert("Image must be of type png, jpg, gif, ico or bmp")
            }
        }
        catch(err) {
            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }

    uploadLogoImage = async (e) => {
        try {
            let file = e.target.files[0]
    
            if(file.type === "image/png" || file.type === "image/bmp" || file.type === "image/jpeg" || file.type === "image/jpg" || file.type === "image/gif" || file.type === "image/x-icon") {
                let regex = /\s+|[,/\\]/g
                if(!file.name.match(regex)) {
                    if(((file.size / 1000) / 1000) > 10) {
                        alert("File is too large to store please upload a smaller image")
                    }
                    else {
                        const formData = new FormData()
            
                        formData.append("gameLogoImg", file)
            
                        if(this.state.logoImgName !== "No file selected.") {
                            let deleteFile = new File(["yes"], "delete", { type: "text/plain" })
                            let imgNameFile = new File([`${this.state.deleteLogoImgName}`], "iconName", { type: "text/plain" })
                
                            formData.append("delete", deleteFile)
                            formData.append("imgName", imgNameFile)
                        }
    
                        let logoImgRes = await axios.post("/api/admin/add/game-logo", formData)
    
                        e.target.value = null
    
                        this.setState({
                            logo: logoImgRes.data[0],
                            logoImgName: logoImgRes.data[1],
                            deleteLogoImgName: logoImgRes.data[2]
                        })
                    }
                }
                else {
                    alert("There must be no spaces, forward slashes or back slashes in the image name")
                }
            }
            else {
                alert("Image must be of type png, jpg, gif, ico or bmp")
            }
        }
        catch(err) {
            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }

    uploadOverlayImg = async (e) => {
        try {
            let file = e.target.files[0]
    
            if(file.type === "image/png" || file.type === "image/bmp" || file.type === "image/jpeg" || file.type === "image/jpg" || file.type === "image/gif" || file.type === "image/x-icon") {
                let regex = /\s+|[,/\\]/g
                if(!file.name.match(regex)) {
                    if(((file.size / 1000) / 1000) > 10) {
                        alert("File is too large to store please upload a smaller image")
                    }
                    else {
                        const formData = new FormData()
            
                        formData.append("gameOverlayImg", file)
            
                        if(this.state.overlayImgName !== "No file selected.") {
                            let deleteFile = new File(["yes"], "delete", { type: "text/plain" })
                            let imgNameFile = new File([`${this.state.deleteOverlayImgName}`], "iconName", { type: "text/plain" })
                
                            formData.append("delete", deleteFile)
                            formData.append("imgName", imgNameFile)
                        }
    
                        let overlayImgRes = await axios.post("/api/admin/add/game-overlay", formData)
    
                        e.target.value = null
    
                        this.setState({
                            overlay: overlayImgRes.data[0],
                            overlayImgName: overlayImgRes.data[1],
                            deleteOverlayImgName: overlayImgRes.data[2]
                        })
                    }
                }
                else {
                    alert("There must be no spaces, forward slashes or back slashes in the image name")
                }
            }
            else {
                alert("Image must be of type png, jpg, gif, ico or bmp")
            }
        }
        catch(err) {
            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }

    openClosePreview = () => {
        this.setState({
            previewShow: !this.state.previewShow
        })
    }

    addGame = async () => {
        try {
            let addBtn = document.getElementById("admin_add_game_add_btn")
            addBtn.innerHTML = ""
            addBtn.classList.add("spinner")

            if(!this.state.name || !this.state.description || !this.state.developer || !this.state.backgroundImg || !this.state.logo || !this.state.overlay || !this.state.video) {
                alert("Must have add name, add a description, add a developer, upload a background image, upload a logo and add a youtube video url")

                addBtn.innerHTML = "Add"
                addBtn.classList.remove("spinner")
            }
            else {
                if(this.state.description.length > 500) {
                    alert("Description too long keep it under 500 characters")

                    addBtn.innerHTML = "Add"
                    addBtn.classList.remove("spinner")
                }
                else {
                    
                    if(this.state.marginTop > 500 || this.state.marginTop < -500) {
                        alert("Logo margin top must be less than 500 and greater than -500")
                        
                        addBtn.innerHTML = "Add"
                        addBtn.classList.remove("spinner")
                    }
                    else {
                        let regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
                        let match = this.state.video.match(regExp)

                        if(match && match[2].length >= 11) {
                            let websiteRegExp = /^(ftp|http|https):\/\/[^ "]+$/
                            
                            if(this.state.website) {
                                if(this.state.website.match(websiteRegExp)) {
                                    let gameRes = await axios.post("/api/admin/add/game", {
                                        name: this.state.name,
                                        description: this.state.description,
                                        developer: this.state.developer,
                                        backgroundImg: this.state.backgroundImg,
                                        logo: this.state.logo,
                                        overlay: this.state.overlay,
                                        video: this.state.video,
                                        website: this.state.website,
                                        marginTop: this.state.marginTop,
                                        marginLeft: this.state.marginLeft,
                                        order: this.props.gamesLength + 1
                                    })
                
                                    alert(gameRes.data)
                
                                    addBtn.innerHTML = "Add"
                                    addBtn.classList.remove("spinner")
                
                                    this.setState({
                                        name: "",
                                        description: "",
                                        developer: "",
                                        backgroundImg: "",
                                        logo: "",
                                        video: "",
                                        website: "",
                                        marginTop: 0,
                                        marginLeft: 0,
                                        backgroundImgName: "No file selected.",
                                        logoImgName: "No file selected.",
                                        deleteBackgroundImgName: "",
                                        deleteLogoImgName: ""
                                    }, async () => {
                                        await this.props.getGamesAgain()
                                        this.props.changePanelType("view")
                                    })
                                }
                                else {
                                    alert("Invalid website url. If you are going to add a website url it needs to be valid empty the website input field to add the game without a website url")
        
                                    addBtn.innerHTML = "Add"
                                    addBtn.classList.remove("spinner")
                                }
                            }
                            else {
                                let gameRes = await axios.post("/api/admin/add/game", {
                                    name: this.state.name,
                                    description: this.state.description,
                                    developer: this.state.developer,
                                    backgroundImg: this.state.backgroundImg,
                                    logo: this.state.logo,
                                    overlay: this.state.overlay,
                                    video: this.state.video,
                                    website: this.state.website,
                                    marginTop: this.state.marginTop,
                                    marginLeft: this.state.marginLeft,
                                    order: this.props.gamesLength + 1
                                })
            
                                alert(gameRes.data)
            
                                addBtn.innerHTML = "Add"
                                addBtn.classList.remove("spinner")
            
                                this.setState({
                                    name: "",
                                    description: "",
                                    developer: "",
                                    backgroundImg: "",
                                    logo: "",
                                    video: "",
                                    website: "",
                                    marginTop: 0,
                                    marginLeft: 0,
                                    backgroundImgName: "No file selected.",
                                    logoImgName: "No file selected.",
                                    deleteBackgroundImgName: "",
                                    deleteLogoImgName: ""
                                }, async () => {
                                    await this.props.getGamesAgain()
                                    this.props.changePanelType("view")
                                })
                            }
                        }
                        else {
                            alert("Invalid youtube url")
        
                            addBtn.innerHTML = "Add"
                            addBtn.classList.remove("spinner")
                        }
                    }
                }
            }
        }
        catch(err) {
            let addBtn = document.getElementById("admin_add_game_add_btn")

            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }

            addBtn.innerHTML = "Add"
            addBtn.classList.remove("spinner")
        }
    }

    render() {
        return (
            <div className="admin_add_game">
                <div className="admin_form_container">
                    <div className="admin_input_container">
                        <p>Name:</p>
                        <input type="text" 
                        name="name" 
                        placeholder="Name" 
                        value={this.state.name} 
                        onChange={this.updateInput} />
                    </div>
                    <div className="admin_input_container">
                        <p>Developer:</p>
                        <input type="text" 
                        name="developer" 
                        placeholder="Developer" 
                        value={this.state.developer} 
                        onChange={this.updateInput} />
                    </div>
                    <div className="admin_input_container">
                        <div className="admin_char_limit_container">
                            <p>Description:</p>
                            <p>{this.state.description.length} / 500</p>
                        </div>
                        <textarea name="description" 
                        placeholder="Description" 
                        cols="30" 
                        rows="10" 
                        value={this.state.description} 
                        onChange={this.updateInput}></textarea>
                    </div>
                    <div className="admin_input_container">
                        <p>Background Image (at least 1000 x 418):</p>
                        <div className="admin_file_upload_container">
                            <input type="file" 
                            accept="image/*" 
                            onChange={this.uploadBackgroundImage} />
                            <p>{this.state.backgroundImgName}</p>
                        </div>
                    </div>
                    <div className="admin_input_container">
                        <p>Logo Image (no more than 400 x 150):</p>
                        <div className="admin_file_upload_container">
                            <input type="file" 
                            accept="image/*" 
                            onChange={this.uploadLogoImage} />
                            <p>{this.state.logoImgName}</p>
                        </div>
                    </div>
                    <div className="admin_input_container">
                        <p>Logo Margin Top:</p>
                        <input type="number" 
                        name="marginTop" 
                        value={this.state.marginTop} 
                        onChange={this.updateInput} />
                    </div>
                    <div className="admin_input_container">
                        <p>Logo Margin Left:</p>
                        <input type="number" 
                        name="marginLeft" 
                        value={this.state.marginLeft} 
                        onChange={this.updateInput} />
                    </div>
                    <div className="admin_input_container">
                        <p>Overlay Background (1920 x 1080):</p>
                        <div className="admin_file_upload_container">
                            <input type="file" 
                            accept="image/*" 
                            onChange={this.uploadOverlayImg} />
                            <p>{this.state.overlayImgName}</p>
                        </div>
                    </div>
                    <div className="admin_input_container">
                        <p>Youtube Video URL:</p>
                        <input type="text" 
                        name="video" 
                        placeholder="https://www.youtube.com/watch?v=-dseFDgpPeE" 
                        value={this.state.video} 
                        onChange={this.updateInput} />
                    </div>
                    <div className="admin_input_container">
                        <p>Official Website (optional):</p>
                        <input type="text" 
                        name="website" 
                        placeholder="https://example.com" 
                        value={this.state.website} 
                        onChange={this.updateInput} />
                    </div>
                    <div className="admin_btn_container">
                        <button onClick={this.changeToView}>Cancel</button>
                        <div className="admin_two_btn">
                            <button onClick={this.openClosePreview}>Preview</button>
                            <button id="admin_add_game_add_btn" 
                            className="admin_spinner_container" 
                            disabled={!this.state.name || 
                                !this.state.description || 
                                !this.state.developer || 
                                !this.state.backgroundImg || 
                                !this.state.logo || 
                                !this.state.overlay || 
                                !this.state.video} 
                            onClick={this.addGame}>Add</button>
                        </div>
                    </div>
                </div>
                <AdminPreviewGame show={this.state.previewShow} 
                openClosePreview={this.openClosePreview} 
                name={this.state.name} 
                description={this.state.description} 
                developer={this.state.developer} 
                backgroundImg={this.state.backgroundImg} 
                logo={this.state.logo} 
                marginTop={this.state.marginTop} 
                marginLeft={this.state.marginLeft} 
                overlay={this.state.overlay} 
                video={this.state.video} />
            </div>
        )
    }
}

export default AdminAddGame