import React, { Component } from "react"
import axios from "axios"
import { Redirect } from "react-router-dom"

import Navbar from "../../ReusableComponents/Navbar/Navbar"
import Footer from "../../ReusableComponents/Footer/Footer"
import "./AdminUnlock.scss"

class AdminUnlock extends Component {
    constructor(props) {
        super(props)

        this.state = {
            pageStatus: "loading",
            user: {},
            password: "",
            confirmPassword: "",
            passwordIcon: "/PasswordVisibility/hide_password.png",
            confirmPasswordIcon: "/PasswordVisibility/hide_password.png",
            passwordVisibility: "hide",
            confirmPasswordVisibility: "hide",
            err: false,
            errMessage: ""
        }
    }

    async componentDidMount() {
        try {
            const urlParams = new URLSearchParams(this.props.location.search)
    
            let email = urlParams.get("email")
            let token = urlParams.get("token")
    
            if(email === null || token === null) {
                this.setState({
                    pageStatus: "redirect"
                })
            }
            else {
                let unlockRes = await axios.put("/auth-serv/admin/check/unlock", {email, token})

                this.setState({
                    pageStatus: "authorized",
                    user: unlockRes.data
                })
            }
        }
        catch(err) {
            if(err.response) {
                if(err.response.status) {
                    if(err.response.status === 401 || err.response.status === 403 || err.response.status === 500) {
                        if(err.response.data) {
                            alert(err.response.data)
                        }
                        this.setState({
                            pageStatus: "redirect"
                        })
                    }
                }
            }
        }
    }

    updateInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    showHidePassword = () => {
        let passwordInput = document.getElementById("unlock_user_password")

        if(this.state.passwordVisibility === "hide") {
            passwordInput.type = "text"

            this.setState({
                passwordVisibility: "show",
                passwordIcon: "/PasswordVisibility/show_password.png"
            })
        }
        else {
            passwordInput.type = "password"

            this.setState({
                passwordVisibility: "hide",
                passwordIcon: "/PasswordVisibility/hide_password.png"
            })
        }
    }

    showHideConfirmPassword = () => {
        let passwordInput = document.getElementById("unlock_user_confirm_password")

        if(this.state.confirmPasswordVisibility === "hide") {
            passwordInput.type = "text"

            this.setState({
                confirmPasswordVisibility: "show",
                confirmPasswordIcon: "/PasswordVisibility/show_password.png"
            })
        }
        else {
            passwordInput.type = "password"

            this.setState({
                confirmPasswordVisibility: "hide",
                confirmPasswordIcon: "/PasswordVisibility/hide_password.png"
            })
        }
    }

    unlockAccount = async () => {
        try {
            let unlockBtn = document.getElementById("admin_user_unlock_btn")
            unlockBtn.innerHTML = ""
            unlockBtn.classList.add("spinner")

            if(!this.state.password || !this.state.confirmPassword) {
                alert("You must fill out all fields")

                unlockBtn.innerHTML = "Unlock"
                unlockBtn.classList.remove("spinner")
            }
            else {
                if(this.state.password === this.state.confirmPassword) {
                    let unlockRes = await axios.put("/auth-serv/admin/unlock/user", {id: this.state.user.id, email: this.state.user.email, password: this.state.password})

                    alert(unlockRes.data)

                    unlockBtn.innerHTML = "Unlock"
                    unlockBtn.classList.remove("spinner")

                    this.props.history.push("/auth/sign-in")
                }
                else {
                    alert("Passwords do not match")

                    unlockBtn.innerHTML = "Unlock"
                    unlockBtn.classList.remove("spinner")
                }
            }
        }
        catch(err) {
            let unlockBtn = document.getElementById("admin_user_unlock_btn")

            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }

            unlockBtn.innerHTML = "Unlock"
            unlockBtn.classList.remove("spinner")
        }
    }

    render() {
        const { REACT_APP_SERVER_HOST } = process.env
        return (
            this.state.pageStatus === "loading"
            ?
                <div className="admin_unlock"></div>
            :
            this.state.pageStatus === "authorized"
            ?
                <div className="main_wrapper">
                    <div className="admin_unlock">
                        <Navbar />
                        <div className="admin_unlock_panel panel">
                            <header className="admin_unlock_header">
                                <h3>
                                    <b>Unlock your account</b>
                                </h3>
                            </header>
                            <div className="admin_unlock_content">
                                <h4>
                                    <b>Reset your password in order to unlock your account</b>
                                </h4>
                                <div className="admin_form_container">
                                    {
                                        this.state.err
                                        ?
                                            <div className="admin_unlock_err_container">
                                                <p>{this.state.errMessage}</p>
                                            </div>
                                        :
                                            null
                                    }
                                    <div className="admin_input_container">
                                        <p>New Password:</p>
                                        <div className="password_input_container">
                                            <input type="password" 
                                            id="unlock_user_password" 
                                            name="password" 
                                            placeholder="Password" 
                                            value={this.state.password} 
                                            onChange={this.updateInput} />
                                            <img src={`${REACT_APP_SERVER_HOST}${this.state.passwordIcon}`} alt="password_icon" onClick={this.showHidePassword} />
                                        </div>
                                    </div>
                                    <div className="admin_input_container">
                                        <p>Confirm Password:</p>
                                        <div className="password_input_container">
                                            <input type="password" 
                                            id="unlock_user_confirm_password" 
                                            name="confirmPassword" 
                                            placeholder="Password" 
                                            value={this.state.confirmPassword} 
                                            onChange={this.updateInput} />
                                            <img src={`${REACT_APP_SERVER_HOST}${this.state.confirmPasswordIcon}`} alt="password_icon" onClick={this.showHideConfirmPassword} />
                                        </div>
                                    </div>
                                    <div className="admin_btn_container">
                                        <button id="admin_user_unlock_btn" 
                                        className="admin_spinner_container" 
                                        disabled={!this.state.password || !this.state.confirmPassword} 
                                        onClick={this.unlockAccount}>Unlock Account</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            :
                <Redirect to={"/"} />
        )
    }
}

export default AdminUnlock