import React from "react"

import "./GameOverlay.scss"

let GameOverlay = (props) => {
    const { REACT_APP_SERVER_HOST } = process.env
    let className = props.activeOverlay === props.overlay.id ? "game_overlay game_overlay_active" : "game_overlay"
    return (
        <div className={className} style={{ "backgroundImage": `url("${REACT_APP_SERVER_HOST}${props.overlay.background_overlay}")` }}></div>
    )
}

export default GameOverlay