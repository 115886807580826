import React from "react"

import "./TeamMember.scss"

let TeamMember = (props) => {
    const { REACT_APP_SERVER_HOST } = process.env
    return (
        <div className="portrait">
            <img src={`${REACT_APP_SERVER_HOST}${props.teamMember.img}`} alt="" />
            <div className="description">
                <span className="name">{props.teamMember.name}</span>
                <br />
                <span className="title">{props.teamMember.title}</span>
                <div className="facts">
                    <p>
                        Favorite Games:
                        <br />
                        - {props.teamMember.favorite_game_one}
                        <br />
                        - {props.teamMember.favorite_game_two}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default TeamMember