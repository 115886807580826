import React, { Component } from "react"
import axios from "axios"
import { TwitterTimelineEmbed } from "react-twitter-embed"

import Navbar from "../../ReusableComponents/Navbar/Navbar"
import HomeVideo from "./Components/HomeVideo/HomeVideo"
import HomeVideoIcon from "./Components/HomeVideoIcon/HomeVideoIcon"
import Footer from "../../ReusableComponents/Footer/Footer"
import "./Home.scss"

class Home extends Component {
    constructor(props) {
        super(props)

        this.state = {
            about: "",
            support: "",
            businessInquiries: "",
            media: "",
            mailingName: "",
            mailingStreet: "",
            mailingCity: "",
            mailingState: "",
            mailingZipcode: "",
            twitterUsername: "",
            videos: [],
            activeVideo: 0,
            bannerTicks: 15
        }
    }

    async componentDidMount() {
        document.title = "Re-Logic"
        
        let homeInfoPlusVideosRes = await axios.get("/api/user/get/home-info-videos")

        this.setState({
            about: homeInfoPlusVideosRes.data[0].about,
            support: homeInfoPlusVideosRes.data[0].support,
            businessInquiries: homeInfoPlusVideosRes.data[0].business_inquiries,
            media: homeInfoPlusVideosRes.data[0].media_contact,
            mailingName: homeInfoPlusVideosRes.data[0].mailing_name,
            mailingStreet: homeInfoPlusVideosRes.data[0].mailing_street,
            mailingCity: homeInfoPlusVideosRes.data[0].mailing_city,
            mailingState: homeInfoPlusVideosRes.data[0].mailing_state,
            mailingZipcode: homeInfoPlusVideosRes.data[0].mailing_zipcode,
            twitterUsername: homeInfoPlusVideosRes.data[0].twitter_username,
            videos: homeInfoPlusVideosRes.data[1]
        }, () => {
            this.countDown()
        })
    }

    countDown = () => {
        if(this.state.bannerTicks === 0) {
            if(this.state.activeVideo === this.state.videos.length - 1) {
                this.setState({
                    activeVideo: 0,
                    bannerTicks: 15
                }, () => {
                    setTimeout(this.countDown, 1000)
                })
            }
            else {
                this.setState({
                    activeVideo: this.state.activeVideo + 1,
                    bannerTicks: 15
                }, () => {
                    setTimeout(this.countDown, 1000)
                })
            }
        }
        else {
            this.setState({
                bannerTicks: this.state.bannerTicks - 1
            }, () => {
                setTimeout(this.countDown, 1000)
            })
        }
    }

    iconClick = (video) => {
        this.setState({
            activeVideo: video,
            bannerTicks: 15
        })
    }

    render() {
        const { REACT_APP_SERVER_HOST } = process.env
        let homeVideos = this.state.videos.map((video, i) => {
            return (
                <HomeVideo key={i} video={video} activeVideo={this.state.activeVideo} index={i} />
            )
        })
        let gameIcons = this.state.videos.map((video, i) => {
            return (
                <HomeVideoIcon key={i} iconGreyed={video.icon_greyed} iconActive={video.icon_active} index={i} iconClick={this.iconClick} activeVideo={this.state.activeVideo} />
            )
        })
        let about = ""
        if(this.state.about) {
            about = this.state.about.replace(/\r\n|\r|\n/g, "/newline").split("/newline").filter((text) => text).map((text, i) => {
                return (
                    <p key={i}>{text}</p>
                )
            })
            if(!about) {
                about = this.state.about
            }
        }
        return (
            <div className="main_wrapper">
                <div className="home">
                    <Navbar />
                    <div className="home_banner_container panel">
                        {homeVideos}
                        <div className="overlay"></div>
                        <div className="game_icons_container">
                            {gameIcons}
                        </div>
                    </div>
                    <div className="home_info_container">
                        <div className="about_container home_box panel">
                            <div className="home_box_title">
                                <img src={`${REACT_APP_SERVER_HOST}/Home/icon_about.png`} alt="about" />
                                <h2 id="about-re-logic">About Re-Logic</h2>
                            </div>
                            <hr />
                            {about}
                        </div>
                        <div className="latest_tweets_container home_box panel">
                            <div className="home_box_title">
                                <img src={`${REACT_APP_SERVER_HOST}/Home/icon_twitter.png`} alt="twitter" />
                                <h2 id="latestt-tweets">Latest Tweets</h2>
                            </div>
                            <hr />
                            <div className="twitter_wrapper">
                                {
                                    this.state.twitterUsername
                                    ?
                                        <TwitterTimelineEmbed sourceType="profile" screenName={this.state.twitterUsername} theme="dark" options={{ height: 250, width: 340 }} />
                                    :
                                        null
                                }
                            </div>
                        </div>
                        <div className="contact_container home_box panel">
                            <div className="home_box_title">
                                <img src={`${REACT_APP_SERVER_HOST}/Home/icon_mail.png`} alt="mail" />
                                <h2 id="contact">Contact</h2>
                            </div>
                            <hr />
                            <div className="contact_info">
                            <p>
                                Support
                                <br />
                                <a href={`mailto:${this.state.support}`}>{this.state.support}</a>
                            </p>
                            <p>
                                Business Inquiries
                                <br />
                                <a href={`mailto:${this.state.businessInquiries}`}>{this.state.businessInquiries}</a>
                            </p>
                            <p>
                                Media Contact
                                <br />
                                <a href={`mailto${this.state.media}`}>{this.state.media}</a>
                            </p>
                            <p>
                                Mailing Address
                                <br />
                                {this.state.mailingName}
                                <br />
                                {this.state.mailingStreet}
                                <br />
                                {this.state.mailingCity}, {this.state.mailingState} {this.state.mailingZipcode}
                            </p>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        )
    }
}

export default Home