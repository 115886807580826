import React, { Component } from "react"
import axios from "axios"

import AdminPreviewHomeVideo from "../AdminPreviewHomeVideo/AdminPreviewHomeVideo"
import "./AdminEditHomeVideo.scss"

class AdminEditHomeVideo extends Component {
    constructor(props) {
        super(props)

        this.state = {
            name: "",
            videoSource: "",
            backupSource: "",
            logo: "",
            iconActive : "",
            iconGreyed: "",
            videoName: "No file selected.",
            backupVideoName: "No file selected.",
            logoName: "No file selected.",
            iconGreyedName: "No file selected.",
            iconActiveName: "No file selected.",
            deleteVideoName: "",
            deleteBackupVideoName: "",
            deleteLogoName: "",
            deleteIconActiveName: "",
            deleteIconGreyedName: "",
            order: 0,
            changeOrder: false,
            swapVideoId: 0,
            oldVideoName: "",
            oldBackupName: "",
            oldLogoName: "",
            oldIconActiveName: "",
            oldIconGreyedName: "",
            previewShow: false,
            videoMimeType: "",
            backupMimeType: "",
            url: "",
            manualOrderReset: false
        }
    }

    componentDidMount() {
        let oldVideoName = this.props.video.video_source.split("HomeVideos/")[1]
        let oldBackupName = this.props.video.backup_source.split("/HomeVideos/")[1]
        let oldLogoName = this.props.video.logo.split("HomeVideoLogos/")[1]
        let oldIconGreyedName = this.props.video.icon_greyed.split("HomeVideoIcons/")[1]
        let oldIconActiveName = this.props.video.icon_active.split("HomeVideoIcons/")[1]
        let url = ""
        if(this.props.video.url) {
            url = this.props.video.url
        }

        this.setState({
            name: this.props.video.name,
            videoSource: this.props.video.video_source,
            backupSource: this.props.video.backup_source,
            logo: this.props.video.logo,
            iconGreyed: this.props.video.icon_greyed,
            iconActive: this.props.video.icon_active,
            order: this.props.video.video_order,
            oldVideoName: oldVideoName,
            oldBackupName: oldBackupName,
            oldLogoName: oldLogoName,
            oldIconActiveName: oldIconActiveName,
            oldIconGreyedName: oldIconGreyedName,
            videoMimeType: this.props.video.video_mimetype,
            backupMimeType: this.props.video.backup_mimetype,
            url: url
        })
    }

    componentDidUpdate(prevProps) {
        if(this.props.video.id !== prevProps.video.id) {
            let oldVideoName = this.props.video.video_source.split("HomeVideos/")[1]
            let oldBackupName = this.props.video.backup_source.split("/HomeVideos/")[1]
            let oldLogoName = this.props.video.logo.split("HomeVideoLogos/")[1]
            let oldIconGreyedName = this.props.video.icon_greyed.split("HomeVideoIcons/")[1]
            let oldIconActiveName = this.props.video.icon_active.split("HomeVideoIcons/")[1]
            let url = ""
            if(this.props.video.url) {
                url = this.props.video.url
            }

            this.setState({
                name: this.props.video.name,
                videoSource: this.props.video.video_source,
                backupSource: this.props.video.backup_source,
                logo: this.props.video.logo,
                iconGreyed: this.props.video.icon_greyed,
                iconActive: this.props.video.icon_active,
                order: this.props.video.video_order,
                oldVideoName: oldVideoName,
                oldBackupName: oldBackupName,
                oldLogoName: oldLogoName,
                oldIconActiveName: oldIconActiveName,
                oldIconGreyedName: oldIconGreyedName,
                videoMimeType: this.props.video.video_mimetype,
                backupMimeType: this.props.video.backup_mimetype,
                url: url
            })
        }
    }

    changeToView = () => {
        this.props.changePanelType("view")
    }

    updateInput = (e) => {
        if(e.target.name === "url") {
            let regExp = /^(ftp|http|https):\/\/[^ "]+$/

            if(e.target.value === "") {
                this.setState({
                    [e.target.name]: e.target.value,
                    errMessage: ""
                })
            }
            else {
                if(e.target.value.match(regExp)) {
                    this.setState({
                        [e.target.name]: e.target.value,
                        errMessage: ""
                    })
                }
                else {
                    this.setState({
                        [e.target.name]: e.target.value,
                        errMessage: "Invalid URL"
                    })
                }
            }
        } 
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    uploadHomeVideo = async (e) => {
        try {
            let file = e.target.files[0]
    
            if(file.type === "video/mp4" || file.type=== "video/webm" || file.type === "video/m4v" || file.type === "video/avi" || file.type === "video/mpg") {
                let regex = /\s+|[,/\\]/g
                if(!file.name.match(regex)) {
                    if(((file.size / 1000) / 1000) > 10) {
                        alert("File is too large to store please upload a smaller video")
                    }
                    else {
                        const formData = new FormData()
            
                        formData.append("homeVideo", file)
            
                        if(e.target.name === "videoSource") {
                            if(this.state.videoName !== "No file selected.") {
                                let deleteFile = new File(["yes"], "delete", { type: "text/plain" })
                                let videoNameFile = new File([`${this.state.deleteVideoName}`], "videoName", { type: "text/plain" })
                
                                formData.append("delete", deleteFile)
                                formData.append("videoName", videoNameFile)
                            }
                        }
                        else {
                            if(this.state.backupVideoName !== "No file selected.") {
                                let deleteFile = new File(["yes"], "delete", { type: "text/plain" })
                                let videoNameFile = new File([`${this.state.deleteBackupVideoName}`], "videoName", { type: "text/plain" })
                
                                formData.append("delete", deleteFile)
                                formData.append("videoName", videoNameFile)
                            }
                        }
    
                        let homeVideoRes = await axios.post("/api/admin/add/home-video-source", formData)
    
                        e.target.value = null
    
                        if(e.target.name === "videoSource") {
                            this.setState({
                                videoSource: homeVideoRes.data[0],
                                videoName: homeVideoRes.data[1],
                                deleteVideoName: homeVideoRes.data[2],
                                videoMimeType: homeVideoRes.data[3]
                            })
                        }
                        else {
                            this.setState({
                                backupSource: homeVideoRes.data[0],
                                backupVideoName: homeVideoRes.data[1],
                                deleteBackupVideoName: homeVideoRes.data[2],
                                backupMimeType: homeVideoRes.data[3]
                            })
                        }
                    }
                }
                else {
                    alert("There must be no spaces, forward slashes or back slashes in the video name")
                }
            }
            else {
                alert("Video must be of type mp4, webm, m4v, avi or mpg")
            }
        }
        catch(err) {
            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }

    uploadLogo = async (e) => {
        try {
            let file = e.target.files[0]
    
            if(file.type === "image/png" || file.type === "image/bmp" || file.type === "image/jpeg" || file.type === "image/jpg" || file.type === "image/gif" || file.type === "image/x-icon") {
                let regex = /\s+|[,/\\]/g
                if(!file.name.match(regex)) {
                    if(((file.size / 1000) / 1000) > 10) {
                        alert("File is too large to store please upload a smaller image")
                    }
                    else {
                        const formData = new FormData()
            
                        formData.append("homeVideoLogo", file)
            
                        if(this.state.logoName !== "No file selected.") {
                            let deleteFile = new File(["yes"], "delete", { type: "text/plain" })
                            let imgNameFile = new File([`${this.state.deleteLogoName}`], "logoName", { type: "text/plain" })
                
                            formData.append("delete", deleteFile)
                            formData.append("imgName", imgNameFile)
                        }
    
                        let logoRes = await axios.post("/api/admin/add/logo", formData)
    
                        e.target.value = null
    
                        this.setState({
                            logo: logoRes.data[0],
                            logoName: logoRes.data[1],
                            deleteLogoName: logoRes.data[2]
                        })
                    }
                }
                else {
                    alert("There must be no spaces, forward slashes or back slashes in the image name")
                }
            }
            else {
                alert("Image must be of type png, jpg, gif, ico or bmp")
            }
        }
        catch(err) {
            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }

    uploadHomeVideoIcon = async (e) => {
        try {
            let file = e.target.files[0]
    
            if(file.type === "image/png" || file.type === "image/bmp" || file.type === "image/jpeg" || file.type === "image/jpg" || file.type === "image/gif" || file.type === "image/x-icon") {
                let regex = /\s+|[,/\\]/g
                if(!file.name.match(regex)) {
                    if(((file.size / 1000) / 1000) > 10) {
                        alert("File is too large to store please upload a smaller image")
                    }
                    else {
                        const formData = new FormData()
            
                        formData.append("homeVideoIcon", file)
            
                        if(e.target.name === "iconActive") {
                            if(this.state.iconActiveName !== "No file selected.") {
                                let deleteFile = new File(["yes"], "delete", { type: "text/plain" })
                                let imgNameFile = new File([`${this.state.deleteIconActiveName}`], "iconName", { type: "text/plain" })
                    
                                formData.append("delete", deleteFile)
                                formData.append("imgName", imgNameFile)
                            }
                        }
                        else {
                            if(this.state.iconGreyedName !== "No file selected.") {
                                let deleteFile = new File(["yes"], "delete", { type: "text/plain" })
                                let imgNameFile = new File([`${this.state.deleteIconGreyedName}`], "iconName", { type: "text/plain" })
                    
                                formData.append("delete", deleteFile)
                                formData.append("imgName", imgNameFile)
                            }
                        }
    
                        let iconRes = await axios.post("/api/admin/add/icon", formData)
    
                        e.target.value = null
    
                        if(e.target.name === "iconActive") {
                            this.setState({
                                iconActive: iconRes.data[0],
                                iconActiveName: iconRes.data[1],
                                deleteIconActiveName: iconRes.data[2]
                            })
                        }
                        else {
                            this.setState({
                                iconGreyed: iconRes.data[0],
                                iconGreyedName: iconRes.data[1],
                                deleteIconGreyedName: iconRes.data[2]
                            })
                        }
                    }
                }
                else {
                    alert("There must be no spaces, forward slashes or back slashes in the image name")
                }
            }
            else {
                alert("Image must be of type png, jpg, gif, ico or bmp")
            }
        }
        catch(err) {
            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }
    changeVideoOrder = (e) => {
        let video = this.props.videos.find((video) => video.video_order === Number(e.target.value))

        this.setState({
            order: Number(e.target.value),
            changeOrder: true,
            swapVideoId: video.id
        })
    }

    openClosePreview = () => {
        this.setState({
            previewShow: !this.state.previewShow
        })
    }

    updateHomeVideo = async () => {
        try {
            let updateBtn = document.getElementById("admin_edit_home_video_update_btn")
            updateBtn.innerHTML = ""
            updateBtn.classList.add("spinner")

            if(!this.state.name || !this.state.videoSource || !this.state.backupSource || !this.state.logo || !this.state.iconGreyed || !this.state.iconActive) {
                alert("You must have a name, upload a video, upload a backup video, upload a logo, upload a greyed icon and upload an icon")

                updateBtn.innerHTML = "Update"
                updateBtn.classList.remove("spinner")
            }
            else {
                if(this.state.url) {
                    let regExp = /^(ftp|http|https):\/\/[^ "]+$/

                    if(this.state.url.match(regExp)) {
                        let videoRes = await axios.put("/api/admin/update/home-video", {
                            id: this.props.video.id,
                            name: this.state.name,
                            videoSource: this.state.videoSource,
                            backupSource: this.state.backupSource,
                            logo: this.state.logo,
                            iconGreyed: this.state.iconGreyed,
                            iconActive: this.state.iconActive,
                            videoMimeType: this.state.videoMimeType,
                            backupMimeType: this.state.backupMimeType,
                            changeOrder: this.state.changeOrder,
                            swapVideoId: this.state.swapVideoId,
                            url: this.state.url
                        })
        
                        if(this.state.videoName !== "No file selected.") {
                            await axios.delete(`/api/admin/delete/old-video-source/${this.state.oldVideoName}`)
                        }
                        if(this.state.backupVideoName !== "No file selected.") {
                            await axios.delete(`/api/admin/delete/old-video-source/${this.state.oldBackupName}`)
                        }
                        if(this.state.logoName !== "No file selected.") {
                            await axios.delete(`/api/admin/delete/old-logo/${this.state.oldLogoName}`)
                        }
                        if(this.state.iconGreyedName !== "No file selected.") {
                            await axios.delete(`/api/admin/delete/old-icon/${this.state.oldIconGreyedName}`)
                        }
                        if(this.state.iconActiveName !== "No file selected.") {
                            await axios.delete(`/api/admin/delete/old-icon/${this.state.oldIconActiveName}`)
                        }
        
                        alert(videoRes.data)
        
                        updateBtn.innerHTML = "Update"
                        updateBtn.classList.remove("spinner")
        
                        this.setState({
                            name: "",
                            videoSource: "",
                            backupSource: "",
                            logo: "",
                            iconGreyed : "",
                            iconActive: "",
                            videoName: "No file selected.",
                            backupVideoName: "No file selected.",
                            logoName: "No file selected.",
                            iconGreyedName: "No file selected.",
                            iconActiveName: "No file selected.",
                            deleteVideoName: "",
                            deleteBackupVideoName: "",
                            deleteLogoName: "",
                            deleteIconGreyedName: "",
                            deleteIconActiveName: "",
                            order: 0,
                            changeOrder: false,
                            swapVideoId: 0,
                            oldVideoName: "",
                            oldBackupName: "",
                            oldLogoName: "",
                            oldIconGreyedName: "",
                            oldIconActiveName: "",
                            url: ""
                        }, async () => {
                            await this.props.getVideosAgain()
                            this.props.changePanelType("view")
                        })
                    }
                    else {
                        alert("Invalid URL if you are going to add a URL it must be valid")

                        updateBtn.innerHTML = "Update"
                        updateBtn.classList.remove("spinner")
                    }
                }
                else {
                    let videoRes = await axios.put("/api/admin/update/home-video", {
                        id: this.props.video.id,
                        name: this.state.name,
                        videoSource: this.state.videoSource,
                        backupSource: this.state.backupSource,
                        logo: this.state.logo,
                        iconGreyed: this.state.iconGreyed,
                        iconActive: this.state.iconActive,
                        videoMimeType: this.state.videoMimeType,
                        backupMimeType: this.state.backupMimeType,
                        changeOrder: this.state.changeOrder,
                        swapVideoId: this.state.swapVideoId,
                        url: this.state.url
                    })
    
                    if(this.state.videoName !== "No file selected.") {
                        await axios.delete(`/api/admin/delete/old-video-source/${this.state.oldVideoName}`)
                    }
                    if(this.state.backupVideoName !== "No file selected.") {
                        await axios.delete(`/api/admin/delete/old-video-source/${this.state.oldBackupName}`)
                    }
                    if(this.state.logoName !== "No file selected.") {
                        await axios.delete(`/api/admin/delete/old-logo/${this.state.oldLogoName}`)
                    }
                    if(this.state.iconGreyedName !== "No file selected.") {
                        await axios.delete(`/api/admin/delete/old-icon/${this.state.oldIconGreyedName}`)
                    }
                    if(this.state.iconActiveName !== "No file selected.") {
                        await axios.delete(`/api/admin/delete/old-icon/${this.state.oldIconActiveName}`)
                    }
    
                    alert(videoRes.data)
    
                    updateBtn.innerHTML = "Update"
                    updateBtn.classList.remove("spinner")
    
                    this.setState({
                        name: "",
                        videoSource: "",
                        backupSource: "",
                        logo: "",
                        iconGreyed : "",
                        iconActive: "",
                        videoName: "No file selected.",
                        backupVideoName: "No file selected.",
                        logoName: "No file selected.",
                        iconGreyedName: "No file selected.",
                        iconActiveName: "No file selected.",
                        deleteVideoName: "",
                        deleteBackupVideoName: "",
                        deleteLogoName: "",
                        deleteIconGreyedName: "",
                        deleteIconActiveName: "",
                        order: 0,
                        changeOrder: false,
                        swapVideoId: 0,
                        oldVideoName: "",
                        oldBackupName: "",
                        oldLogoName: "",
                        oldIconGreyedName: "",
                        oldIconActiveName: "",
                        url: ""
                    }, async () => {
                        await this.props.getVideosAgain()
                        this.props.changePanelType("view")
                    })
                }
            }
        }
        catch(err) {
            let updateBtn = document.getElementById("admin_edit_home_video_update_btn")

            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }

            updateBtn.innerHTML = "Update"
            updateBtn.classList.remove("spinner")
        }
    }

    toggleOrderReset = () => {
        this.setState({
            manualOrderReset: !this.state.manualOrderReset
        })
    }


    manuallyUpdateOrder = async () => {
        try {
            let updateBtn = document.getElementById("home_video_update_order_btn")
            updateBtn.innerHTML = ""
            updateBtn.classList.add("spinner")

            if( this.state.order > 2147483647 || this.state.order <= 0) {
                alert("Order must be above 0 and less than 2147483647")

                updateBtn.innerHTML = "Update Order"
                updateBtn.classList.remove("spinner")
            }
            else {
                let orderRes = await axios.put("/api/admin/update/home-video/order", { id: this.props.video.id, order: this.state.order })

                alert(orderRes.data)

                updateBtn.innerHTML = "Update Order"
                updateBtn.classList.remove("spinner")

                await this.props.getVideosAgain()
            }
        }
        catch(err) {
            let updateBtn = document.getElementById("home_video_update_order_btn")

            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }

            updateBtn.innerHTML = "Update Order"
            updateBtn.classList.remove("spinner")
        }
    }
    
    render() {
        let className = this.state.errMessage ? "err_input" : "input"
        let videoOrders = this.props.videos.map((video, i) => {
            return (
                <option key={i} value={video.video_order}>{video.video_order}</option>
            )
        })
        return (
            <div className="admin_edit_home_video">
                <div className="admin_form_container">
                    <div className="admin_input_container">
                        <p>Name:</p>
                        <input type="text" 
                        name="name" 
                        placeholder="Name" 
                        value={this.state.name} 
                        onChange={this.updateInput} />
                    </div>
                    <div className="admin_input_container">
                        <p>Video Source:</p>
                        <div className="admin_file_upload_container">
                            <input type="file"
                            name="videoSource" 
                            accept="video/*" 
                            onChange={this.uploadHomeVideo} />
                            <p>{this.state.videoName}</p>
                        </div>
                    </div>
                    <div className="admin_input_container">
                        <p>Backup Video Source:</p>
                        <div className="admin_file_upload_container">
                            <input type="file" 
                            name="backupSource" 
                            accept="video/*" 
                            onChange={this.uploadHomeVideo} />
                            <p>{this.state.backupVideoName}</p>
                        </div>
                    </div>
                    <div className="admin_input_container">
                        <p>Logo (no more than 400 x 150):</p>
                        <div className="admin_file_upload_container">
                            <input type="file" 
                            accept="image/*" 
                            onChange={this.uploadLogo} />
                            <p>{this.state.logoName}</p>
                        </div>
                    </div>
                    <div className="admin_input_container">
                        <p>Icon Greyed Out (36 x 36):</p>
                        <div className="admin_file_upload_container">
                            <input type="file" 
                            name="iconGreyed" 
                            accept="image/*" 
                            onChange={this.uploadHomeVideoIcon} />
                            <p>{this.state.iconGreyedName}</p>
                        </div>
                    </div>
                    <div className="admin_input_container">
                        <p>Icon Active (36 x 36):</p>
                        <div className="admin_file_upload_container">
                            <input type="file" 
                            name="iconActive" 
                            accept="image/*" 
                            onChange={this.uploadHomeVideoIcon} />
                            <p>{this.state.iconActiveName}</p>
                        </div>
                    </div>
                    <div className="admin_input_container">
                        <p>URL (optional): (leads to the video game website)</p>
                        {
                            this.state.errMessage
                            ?
                                <p className="err_message">{this.state.errMessage}</p>
                            :
                                null
                        }
                        <input type="text"  
                        className={className} 
                        name="url" 
                        placeholder="https://example.com" 
                        value={this.state.url} 
                        onChange={this.updateInput} />
                    </div>
                    <div className="admin_input_container">
                        <p>Order:</p>
                        <select name="order" value={this.state.order} onChange={this.changeVideoOrder}>
                            {videoOrders}
                        </select>
                    </div>
                    <div className="admin_input_container">
                        <p>Video order not working correctly? Click the button below to manually update this video's order</p>
                        {
                            this.state.manualOrderReset
                            ?
                                <div className="admin_order_change_container">
                                    <button className="admin_store_order_reset_cancel_btn" onClick={this.toggleOrderReset}>X</button>
                                    <input type="number" 
                                    name="order" 
                                    placeholder="0" 
                                    value={this.state.order} 
                                    onChange={this.updateInput} />
                                    <button id="home_video_update_order_btn" 
                                    className="admin_spinner_container" 
                                    onClick={this.manuallyUpdateOrder}>Update Order</button>
                                </div>
                            :
                                <div className="admin_order_change_container">
                                    <button onClick={this.toggleOrderReset}>Set Order</button>
                                </div>
                        }
                    </div>
                    <div className="admin_btn_container">
                        <button onClick={this.changeToView}>Cancel</button>
                        <div className="admin_two_btn">
                            <button onClick={this.openClosePreview}>Preview</button>
                            <button id="admin_edit_home_video_update_btn" 
                            className="admin_spinner_container" 
                            disabled={!this.state.name || !this.state.videoSource || !this.state.backupSource || !this.state.logo || !this.state.iconGreyed || !this.state.iconActive || !this.state.order} 
                            onClick={this.updateHomeVideo}>Update</button>
                        </div>
                    </div>
                </div>
                <AdminPreviewHomeVideo show={this.state.previewShow} 
                videoSource={this.state.videoSource} 
                backupSource={this.state.backupSource} 
                logo={this.state.logo} 
                iconGreyed={this.state.iconGreyed} 
                iconActive={this.state.iconActive}
                openClosePreview={this.openClosePreview} 
                videoMimeType={this.state.videoMimeType} 
                backupMimeType={this.state.backupMimeType} />
            </div>
        )
    }
}

export default AdminEditHomeVideo