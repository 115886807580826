import React, { Component } from "react"
import axios from "axios"

import "./AdminUser.scss"

class AdminUser extends Component {
    constructor(props) {
        super(props)

        this.state = {
            editingRole: false,
            role: this.props.user.role,
            editingPassword: false,
            userPassword: "",
            userPasswordVisibility: "hide",
            userPasswordIcon: "/PasswordVisibility/hide_password.png",
        }
    }

    updateInput = (e) => {
        this.setState({
            role: e.target.value
        })
    }

    toggleEditing = () => {
        this.setState({
            editingRole: !this.state.editingRole
        })
    }

    cancelRoleChange = () => {
        this.setState({
            role: this.props.user.role,
            editingRole: false
        })
    }

    changeRole = async () => {
        try {
            let changeRoleBtn = document.getElementById(`change_role_btn_${this.props.user.id}`)
            changeRoleBtn.innerHTML = ""
            changeRoleBtn.classList.add("spinner")

            let editRoleRes = await axios.put("/api/admin/update/user/role", {id: this.props.user.id, role: this.state.role})

            alert(editRoleRes.data)

            changeRoleBtn.innerHTML ="Change Role"
            changeRoleBtn.classList.remove("spinner")

            await this.props.getUsersAgain()

            this.setState({
                editingRole: false
            })
        }
        catch(err) {
            let changeRoleBtn = document.getElementById(`change_role_btn_${this.props.user.id}`)

            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }

            changeRoleBtn.innerHTML ="Change Role"
            changeRoleBtn.classList.remove("spinner")
        }
    }

    deleteUser = async () => {
        try {
            let deleteBtn = document.getElementById(`delete_user_btn_${this.props.user.id}`)
            deleteBtn.innerHTML =""
            deleteBtn.classList.add("spinner")

            if(window.confirm("Are you sure you want to delete this user?")) {
                let deleteUserRes = await axios.delete(`/api/admin/delete/user/${this.props.user.id}`)

                alert(deleteUserRes.data)

                deleteBtn.innerHTML = "Delete"
                deleteBtn.classList.remove("spinner")

                await this.props.getUsersAgain()
            }
            else {
                deleteBtn.innerHTML = "Delete"
                deleteBtn.classList.remove("spinner")
            }
        }
        catch(err) {
            let deleteBtn = document.getElementById(`delete_user_btn_${this.props.user.id}`)

            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }

            deleteBtn.innerHTML = "Delete"
            deleteBtn.classList.remove("spinner")
        }
    }

    unlockUserAcount = async () => {
        try {
            let unlockBtn = document.getElementById(`unlock_btn_${this.props.user.id}`)
            unlockBtn.innerHTML = ""
            unlockBtn.classList.add("spinner")

            let unlockUserRes = await axios.put("/api/admin/unlock/user", {id: this.props.user.id, email: this.props.user.email, screenName: this.props.user.screen_name})

            alert(unlockUserRes.data)

            unlockBtn.innerHTML = "Unlock"
            unlockBtn.classList.remove("spinner")
        }
        catch(err) {
            let unlockBtn = document.getElementById(`unlock_btn_${this.props.user.id}`)

            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }

            unlockBtn.innerHTML = "Unlock"
            unlockBtn.classList.remove("spinner")
        }
    }

    toggleEditingPassword = () => {
        this.setState({
            editingPassword: !this.state.editingPassword,
            userPassword: ""
        })
    }

    updatePasswordInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    showHideUserPassword = () => {
        let passwordInput = document.getElementById(`change_user_password_${this.props.user.id}`)

        if(this.state.userPasswordVisibility === "hide") {
            passwordInput.type = "text"

            this.setState({
                userPasswordVisibility: "show",
                userPasswordIcon: "/PasswordVisibility/show_password.png"
            })
        }
        else {
            passwordInput.type = "password"

            this.setState({
                userPasswordVisibility: "hide",
                userPasswordIcon: "/PasswordVisibility/hide_password.png"
            })
        }
    }

    changeUserPassword = async () => {
        try {
            if(this.state.userPassword) {
                if(window.confirm("Are you sure you want to update this user's password?")) {
                    let changeUserPasswordBtn = document.getElementById(`change_user_${this.props.user.id}_password_btn`)
            
                    changeUserPasswordBtn.innerHTML = ""
                    changeUserPasswordBtn.classList.add("spinner")
            
                    let updatePasswordRes = await axios.put("/api/admin/update/other-user-password", { id: this.props.user.id, password: this.state.userPassword})
    
                    alert(updatePasswordRes.data)
    
                    changeUserPasswordBtn.innerHTML = "Confirm"
                    changeUserPasswordBtn.classList.remove("spinner")
    
                    this.toggleEditingPassword()
                }
                else {
                    this.toggleEditingPassword()
                }
            }
            else {
                alert("You must Type in a password to change a user's password")
            }
        }
        catch(err) {
            let changeUserPasswordBtn = document.getElementById(`change_user_${this.props.user.id}_password_btn`)

            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }

            changeUserPasswordBtn.innerHTML = "Confirm"
            changeUserPasswordBtn.classList.remove("spinner")
        }
    }

    render() {
        const { REACT_APP_SERVER_HOST } = process.env
        return (
            <div className="admin_user">
                <h4>
                    <strong>{this.props.user.screen_name}</strong>
                </h4>
                <div className="admin_user_info_container">
                    {
                        this.state.editingRole
                        ?
                            <div className="admin_user_editing_container">
                                <select name="role" value={this.state.role} onChange={this.updateInput}>
                                    <option value="super admin">Super Admin</option>
                                    <option value="admin">Admin</option>
                                    <option value="new user">New User</option>
                                </select>
                                <div className="admin_user_btn_container">
                                    <button onClick={this.cancelRoleChange}>Cancel</button>
                                    <button id={`change_role_btn_${this.props.user.id}`} 
                                    className="change_role_btn admin_spinner_container" 
                                    onClick={this.changeRole}>Change Role</button>
                                </div>
                            </div>
                        :
                        this.state.editingPassword
                        ?
                            <div className="admin_user_editing_user_password_container">
                                <div className="password_input">
                                    <input type="password" 
                                    id={`change_user_password_${this.props.user.id}`}
                                    name="userPassword" 
                                    value={this.state.userPassword} 
                                    onChange={this.updatePasswordInput} />
                                    <img src={`${REACT_APP_SERVER_HOST}${this.state.userPasswordIcon}`} alt="password_icon" onClick={this.showHideUserPassword}/>
                                </div>
                                <div className="admin_user_btn_container">
                                    <button className="admin_spinner_container" 
                                    id={`change_user_${this.props.user.id}_password_btn`} 
                                    onClick={this.changeUserPassword} 
                                    disabled={!this.state.userPassword} >Confirm</button>
                                    <button onClick={this.toggleEditingPassword}>Cancel</button>
                                </div>
                            </div>
                        :
                            <div className="admin_user_edit_delete_container">
                                <h4 className="admin_user_role">
                                    <b>{this.props.user.role}</b>
                                </h4>
                                <div className="admin_user_btn_container">
                                    <button onClick={this.toggleEditing}>Edit Role</button>
                                    <button style={{"width": "130px", "marginRight": "5px"}} 
                                    onClick={this.toggleEditingPassword}>Change Password</button>
                                    <button id={`delete_user_btn_${this.props.user.id}`} 
                                    className="admin_spinner_container" 
                                    onClick={this.deleteUser}>Delete</button>
                                </div>
                            </div>
                    }
                    {
                        this.props.user.locked_at
                        ?
                            <div className="admin_user_locked_container">
                                <p>Account Locked:</p>
                                <button id={`unlock_btn_${this.props.user.id}`} 
                                className="admin_spinner_container" 
                                onClick={this.unlockUserAcount}>Unlock</button>
                            </div>
                        :
                            null
                    }
                </div>
            </div>
        )
    }
}

export default AdminUser