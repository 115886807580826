import React, { Component } from "react"
import axios from "axios"

import Navbar from "../../ReusableComponents/Navbar/Navbar"
import Footer from "../../ReusableComponents/Footer/Footer"
import "./AdminSignIn.scss"

class AdminSignIn extends Component {
    constructor(props) {
        super(props)

        this.state = {
            pageStatus: "sign in",
            email: "",
            password: "",
            flashAlertStyle: { "maxHeight": "30px" },
            err: false,
            errMessage: "",
            panelClass: "admin_login_panel panel panel_default",
            emailValidation: true,
            passwordIcon: "/PasswordVisibility/hide_password.png",
            passwordVisibility: "hide",
            forgotPasswordEmail: "",
            remember: "no"
        }
    }

    componentDidMount() {
        if(this.props.location.state) {
            this.setState({
                err: true,
                errMessage: this.props.location.state.err,
                panelClass: "admin_login_panel panel panel_danger"
            })
        }

        let userEmailInput = document.getElementById("user_email")

        let userPasswordInput = document.getElementById("sign_in_user_password")

        userEmailInput.addEventListener("keyup", this.enterKey)

        userPasswordInput.addEventListener("keyup", this.enterKey)
    }

    enterKey = (e) => {
        e.preventDefault()

        if(e.keyCode === 13) {
            document.getElementById("sign_in_btn").click()
        }
    }

    updateInput = (e) => {
        let emailReg = /\S+@\S+\.\S+/

        if(e.target.name === "email" || e.target.name === "forgotPasswordEmail") {
            if(e.target.value === "") {
                this.setState({
                    [e.target.name]: e.target.value,
                    emailValidation: true,
                    err: false,
                    errMessage: "",
                    panelClass: "admin_login_panel panel panel_default"
                })
            }
            else {
                if(e.target.value.match(emailReg)) {
                    this.setState({
                        [e.target.name]: e.target.value,
                        emailValidation: true,
                        err: false,
                        errMessage: "",
                        panelClass: "admin_login_panel panel panel_default"
                    })
                }
                else {
                    this.setState({
                        [e.target.name]: e.target.value,
                        emailValidation: false,
                        err: true,
                        errMessage: "Invalid email",
                        panelClass: "admin_login_panel panel panel_danger"
                    })
                }
            }
        }
        else {
            this.setState({
                [e.target.name]: e.target.value
            })
        }
    }

    removeFlashAlert = () => {
        this.setState({
            flashAlertStyle: { "maxHeight": "0px" }
        })
    }

    togglePageStatus = () => {
        try {
            if(this.state.pageStatus === "sign in") {
                this.setState({
                    pageStatus: "forgot password",
                    emailValidation: true,
                    err: false,
                    errMessage: ""
                })
            }
            else {
                this.setState({
                    pageStatus: "sign in",
                    emailValidation: true,
                    err: false,
                    errMessage: ""
                })
            }
        }
        catch(err) {
            alert("Error switching forms")
        }
    }

    showHidePassword = () => {
        let passwordInput = document.getElementById("sign_in_user_password")

        if(this.state.passwordVisibility === "hide") {
            passwordInput.type = "text"

            this.setState({
                passwordVisibility: "show",
                passwordIcon: "/PasswordVisibility/show_password.png"
            })
        }
        else {
            passwordInput.type = "password"

            this.setState({
                passwordVisibility: "hide",
                passwordIcon: "/PasswordVisibility/hide_password.png"
            })
        }
    }

    rememberChange = () => {
        if(this.state.remember === "no") {
            this.setState({
                remember: "yes"
            })
        }
        else {
            this.setState({
                remember: "no"
            })
        }
    }

    signIn = async () => {
        try {
            let signInBtn = document.getElementById("sign_in_btn")
            signInBtn.innerHTML = ""
            signInBtn.classList.add("spinner")
    
            if(!this.state.email || !this.state.password) {
                alert("Please enter both email and password")
    
                signInBtn.innerHTML = "Sign In"
                signInBtn.classList.remove("spinner")
            }
            else {
                if(this.state.emailValidation) {
                    await axios.put("/auth-serv/admin/sign-in", { email: this.state.email, password: this.state.password, remember: this.state.remember })
    
                    signInBtn.innerHTML = "Sign In"
                    signInBtn.classList.remove("spinner")
    
                    this.props.history.push("/admin")
                }
                else {
                    alert("Please enter valid email")

                    signInBtn.innerHTML = "Sign In"
                    signInBtn.classList.remove("spinner")
                }
            }
        }
        catch(err) {
            let signInBtn = document.getElementById("sign_in_btn")
            signInBtn.innerHTML = "Sign In"
            signInBtn.classList.remove("spinner")

            if(err.response) {
                if(err.response.data) {
                    this.setState({
                        err: true,
                        errMessage: err.response.data,
                        panelClass: "admin_login_panel panel panel_danger"
                    })
                }
            }
        }
    }

    resetPassword = async () => {
        try {
            let resetPasswordBtn = document.getElementById("reset_password_btn")
            resetPasswordBtn.innerHTML = ""
            resetPasswordBtn.classList.add("spinner")

            if(!this.state.forgotPasswordEmail) {
                alert("You need to fill out the email field")

                resetPasswordBtn.innerHTML = "Reset Password"
                resetPasswordBtn.classList.remove("spinner")
            }
            else {
                if(this.state.emailValidation) {
                    let resetPasswordRes = await axios.put("/auth-serv/admin/reset-password", { email: this.state.forgotPasswordEmail })
        
                    alert(resetPasswordRes.data)
        
                    resetPasswordBtn.innerHTML = "Reset Password"
                    resetPasswordBtn.classList.remove("spinner")
                }
                else {
                    alert("Please eneter in valid email")

                    resetPasswordBtn.innerHTML = "Reset Password"
                    resetPasswordBtn.classList.remove("spinner")
                }
            }
        }
        catch(err) {
            let resetPasswordBtn = document.getElementById("reset_password_btn")

            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }

            resetPasswordBtn.innerHTML = "Reset Password"
            resetPasswordBtn.classList.remove("spinner")
        }
    }
    
    render() {
        const { REACT_APP_SERVER_HOST } = process.env
        return (
            <div className="main_wrapper">
                <div className="admin_sign_in">
                    <Navbar />
                    <div className="flash_container">
                        {
                            this.state.err
                            ?
                                <div className="flash flash_alert" style={this.state.flashAlertStyle}>
                                    {this.state.errMessage}
                                    <div>
                                        <span onClick={this.removeFlashAlert} className="glyphicon glyphicon_remove"></span>
                                    </div>
                                </div>
                            :
                                null
                        }
                    </div>
                    {
                        this.state.pageStatus === "sign in"
                        ?
                            <div className="sign_in">
                                <h2>
                                    <b>Sign In</b>
                                </h2>
                                <div className={this.state.panelClass}>
                                    {
                                        this.state.err
                                        ?
                                            <div className="panel_heading">
                                                <span className="glyphicon glyphicon_warning_sign"></span>
                                                {this.state.errMessage}
                                            </div>
                                        :
                                            null
                                    }
                                    <div className="panel_body">
                                        <div className="sign_in_form">
                                            <input type="text" 
                                            className="form_control" 
                                            id="user_email" 
                                            autoFocus="autoFocus" 
                                            name="email" 
                                            placeholder="User@exmaple.com" 
                                            autoComplete="off" 
                                            value={this.state.email} 
                                            onChange={this.updateInput}/>
                                            <div className="password_input_container">
                                                <input type="password" 
                                                className="form_control" 
                                                id="sign_in_user_password" 
                                                name="password" 
                                                placeholder="password" 
                                                autoComplete="off" 
                                                value={this.state.password} 
                                                onChange={this.updateInput}/>
                                                <img src={`${REACT_APP_SERVER_HOST}${this.state.passwordIcon}`} alt="password_icon" onClick={this.showHidePassword}/>
                                            </div>
                                            <div className="forgot_password_container">
                                                <p onClick={this.togglePageStatus}>Forgot Password?</p>
                                            </div>
                                            <button id="sign_in_btn" 
                                            className="sign_in_btn admin_spinner_container" 
                                            disabled={!this.state.email || !this.state.password} 
                                            onClick={this.signIn}>Sign In</button>
                                            <div className="remember_me_container">
                                                <input type="checkbox" 
                                                name="user_remember_me" 
                                                value={this.state.remember} 
                                                onChange={this.rememberChange}/>
                                                <label htmlFor="user_remember_me">Remember me</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br/>
                                <br/>
                            </div>
                        :
                            <div className="forgot_password">
                                <h2>
                                    <b>Forgot Password</b>
                                </h2>
                                <div className={this.state.panelClass}>
                                    {
                                        this.state.err
                                        ?
                                            <div className="panel_heading">
                                                <span className="glyphicon glyphicon_warning_sign"></span>
                                                {this.state.errMessage}
                                            </div>
                                        :
                                            null
                                    }
                                    <div className="panel_body">
                                        <div className="forgot_password_form">
                                            <input type="text" 
                                            className="form_control" 
                                            name="forgotPasswordEmail" 
                                            placeholder="User@example.com" 
                                            value={this.state.forgotPasswordEmail} 
                                            onChange={this.updateInput}/>
                                            <button id="reset_password_btn" 
                                            className="reset_password_btn admin_spinner_container" 
                                            disabled={!this.state.forgotPasswordEmail} 
                                            onClick={this.resetPassword}>Reset Password</button>
                                            <div className="back_arrow_container">
                                                <p onClick={this.togglePageStatus}>&larr;</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Footer />
                            </div>
                    }
                </div>
            </div>
        )
    }
}

export default AdminSignIn