import React from "react"

import "./HomeVideo.scss"

let HomeVideo = (props) => {
    const { REACT_APP_SERVER_HOST } = process.env
    let videoClass = props.activeVideo === props.index ? "video active_video" : "video"
    let logoClass = props.activeVideo === props.index ? "logo active_logo" : "logo"
    return (
        <div className="home_video">
            <video className={videoClass} autoPlay loop preload="auto">
                <source src={`${REACT_APP_SERVER_HOST}${props.video.video_source}`} />
                <source src={`${REACT_APP_SERVER_HOST}${props.video.backup_source}`} />
            </video>
                {
                    props.video.url
                    ?
                        <a id="logo_a" rel="noreferrer" target="_blank" href={`${props.video.url}`}>
                            <div className={logoClass} style={{ "backgroundImage": `url("${REACT_APP_SERVER_HOST}${props.video.logo}")` }}></div>
                        </a>
                    :
                        <div className={logoClass} style={{ "backgroundImage": `url("${REACT_APP_SERVER_HOST}${props.video.logo}")` }}></div>
                }
        </div>
    )
}

export default HomeVideo