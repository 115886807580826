import React, { Component } from "react"
import axios from "axios"

import "./AdminChangePassword.scss"

class AdminMainChangePassword extends Component {
    constructor(props) {
        super(props)

        this.state = {
            currentPassword: "",
            newPassword: "",
            confirmPassword: "",
            currentPasswordIcon: "/PasswordVisibility/hide_password.png",
            newPasswordIcon: "/PasswordVisibility/hide_password.png",
            confirmPasswordIcon: "/PasswordVisibility/hide_password.png",
            currentPasswordVisibility: "hide",
            newPasswordVisibility: "hide",
            confirmPasswordVisibility: "hide"
        }
    }

    updateInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    showHideCurrentPassword = () => {
        let passwordInput = document.getElementById("change_password_current_password")

        if(this.state.currentPasswordVisibility === "hide") {
            passwordInput.type = "text"

            this.setState({
                currentPasswordVisibility: "show",
                currentPasswordIcon: "/PasswordVisibility/show_password.png"
            })
        }
        else {
            passwordInput.type = "password"

            this.setState({
                currentPasswordVisibility: "hide",
                currentPasswordIcon: "/PasswordVisibility/hide_password.png"
            })
        }
    }

    showHideNewPassword = () => {
        let passwordInput = document.getElementById("change_password_new_password")

        if(this.state.newPasswordVisibility === "hide") {
            passwordInput.type = "text"

            this.setState({
                newPasswordVisibility: "show",
                newPasswordIcon: "/PasswordVisibility/show_password.png"
            })
        }
        else {
            passwordInput.type = "password"

            this.setState({
                newPasswordVisibility: "hide",
                newPasswordIcon: "/PasswordVisibility/hide_password.png"
            })
        }
    }

    showHideConfirmPassword = () => {
        let passwordInput = document.getElementById("change_password_confirm_password")
 
        if(this.state.confirmPasswordVisibility === "hide") {
            passwordInput.type = "text"

            this.setState({
                confirmPasswordVisibility: "show",
                confirmPasswordIcon: "/PasswordVisibility/show_password.png"
            })
        }
        else {
            passwordInput.type = "password"

            this.setState({
                confirmPasswordVisibility: "hide",
                confirmPasswordIcon: "/PasswordVisibility/hide_password.png"
            })
        }
    }

    changePassword = async () => {
        try {
            let changePasswordBtn = document.getElementById("admin_change_password_btn")
            changePasswordBtn.innerHTML = ""
            changePasswordBtn.classList.add("spinner")

            if(!this.state.currentPassword || !this.state.newPassword || !this.state.confirmPassword) {
                alert("Please fill out all password fields")

                changePasswordBtn.innerHTML = "Change Password"
                changePasswordBtn.classList.remove("spinner")
            }
            else {
                if(this.state.newPassword === this.state.confirmPassword) {
                    let changePasswordRes = await axios.put("/auth-serv/admin/compare/change/password", 
                    {
                        id: this.props.user.id, 
                        email: this.props.user.email,
                        currentPassword: this.state.currentPassword,
                        newPassword: this.state.newPassword
                    })

                    alert(changePasswordRes.data)

                    changePasswordBtn.innerHTML = "Change Password"
                    changePasswordBtn.classList.remove("spinner")

                    this.props.pushToSignIn()
                }
                else {
                    alert("New password and confirm password do not match")

                    changePasswordBtn.innerHTML = "Change Password"
                    changePasswordBtn.classList.remove("spinner")
                }
            }
        }
        catch(err) {
            let changePasswordBtn = document.getElementById("admin_change_password_btn")

            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }

            changePasswordBtn.innerHTML = "Change Password"
            changePasswordBtn.classList.remove("spinner")
        }
    }


    render() {
        const { REACT_APP_SERVER_HOST } = process.env
        return (
            <div className="admin_main_change_password">
                <h4>
                    <b>Change Password</b>
                </h4>
                <div className="admin_form_container">
                    <div className="admin_input_container">
                        <p>Current Password:</p>
                        <div className="admin_password_input_container">
                            <input type="password" 
                            name="currentPassword" 
                            placeholder="Password" 
                            id="change_password_current_password" 
                            value={this.state.currentPassword} 
                            onChange={this.updateInput} /> 
                            <img src={`${REACT_APP_SERVER_HOST}${this.state.currentPasswordIcon}`} alt="password_icon" onClick={this.showHideCurrentPassword} />
                        </div>
                    </div>
                    <div className="admin_input_container">
                        <p>New Password:</p>
                        <div className="admin_password_input_container">
                            <input type="password" 
                            name="newPassword" 
                            id="change_password_new_password" 
                            placeholder="Password" 
                            value={this.state.newPassword} 
                            onChange={this.updateInput} /> 
                            <img src={`${REACT_APP_SERVER_HOST}${this.state.newPasswordIcon}`} alt="password_icon" onClick={this.showHideNewPassword} />
                        </div>
                    </div>
                    <div className="admin_input_container">
                        <p>Confirm Password:</p>
                        <div className="admin_password_input_container">
                            <input type="password" 
                            name="confirmPassword" 
                            id="change_password_confirm_password" 
                            placeholder="Password" 
                            value={this.state.confirmPassword} 
                            onChange={this.updateInput} /> 
                            <img src={`${REACT_APP_SERVER_HOST}${this.state.confirmPasswordIcon}`} alt="password_icon" onClick={this.showHideConfirmPassword} />
                        </div>
                    </div>
                    <div className="admin_btn_container">
                        <button id="admin_change_password_btn" 
                        className="admin_spinner_container" 
                        disabled={!this.state.currentPassword || !this.state.newPassword || !this.state.confirmPassword} 
                        onClick={this.changePassword}>Change Password</button>
                    </div>
                </div>
            </div>
        )
    }
}

export default AdminMainChangePassword