import React, { useState, useEffect } from "react"
import axios from "axios"

import AdminTeamMember from "./Components/AdminTeamMember/AdminTeamMember"
import "./AdminViewTeam.scss"

let AdminViewTeam = (props) => {
    const [description, setDescription] = useState("")

    useEffect(() => {
        const getDescription = async () => {
            try {
                let descriptionRes = await axios.get("/api/admin/get/team-description")
    
                setDescription(descriptionRes.data[0].description)
            }
            catch(err) {
                if(err.response) {
                    if(err.response.data) {
                        alert(err.response.data)
                    }
                }
            }
        }

        getDescription()
    }, [])

    const updateInput = (e) => {
        setDescription(e.target.value)
    }

    const updateTeamDescription = async () => {
        try {
            const updateBtn = document.getElementById("admin_view_team_update_team_description_btn")

            updateBtn.innerHTML = ""
            updateBtn.classList.add("spinner")

            if(description) {
                let descriptionRes = await axios.put("/api/admin/update/team-description", { description: description })

                alert(descriptionRes.data)

                updateBtn.innerHTML = "Update"
                updateBtn.classList.remove("spinner")
            }
            else {
                alert("You can't have an empty description")

                updateBtn.innerHTML = "Update"
                updateBtn.classList.remove("spinner")
            }
        }
        catch(err) {
            const updateBtn = document.getElementById("admin_view_team_update_team_description_btn")

            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }

            updateBtn.innerHTML = "Update"
            updateBtn.classList.remove("spinner")
        }
    }

    let changeToAdd = () => {
        props.changePanelType("add")
    }

    let teamMembers = props.teamMembers.map((teamMember, i) => {
        return (
            <AdminTeamMember key={i} teamMember={teamMember} changePanelType={props.changePanelType} getTeamMembersAgain={props.getTeamMembersAgain} />
        )
    })

    return (
        <div className="admin_view_team">
            <div className="admin_view_team_description_form_container">
                <p>Team Page Description:</p>
                <textarea cols="30" 
                rows="10" 
                value={description} 
                onChange={updateInput} ></textarea>
                <div className="admin_btn_container admin_view_team_update_description_btn_container">
                    <button id="admin_view_team_update_team_description_btn" 
                    className="admin_spinner_container" 
                    disabled={!description} 
                    onClick={updateTeamDescription}>Update</button>
                </div>
            </div>
            <div className="admin_view_team_members_container">
                <div className="admin_view_page_info_container">
                    <h4>
                        Team page - {props.pageNumber}
                    </h4>
                    <button onClick={changeToAdd}>New</button>
                </div>
                <div className="admin_view_team_team_container">
                    {teamMembers}
                </div>
                <div className="admin_previous_next_container">
                    <button disabled={props.pageNumber === 1} onClick={props.previousPage}>Previous</button>
                    <button disabled={props.pageNumber === props.pages} onClick={props.nextPage}>Next</button>
                </div>
            </div>
        </div>
    )
}

export default AdminViewTeam