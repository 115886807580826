import React from "react"

import "./NavbarSocial.scss"

let NavbarSocial = (props) => {
    const { REACT_APP_SERVER_HOST } = process.env
    return (
        <div className="navbar_social">
            <a rel="noreferrer" target="_blank" href={`${props.social.url}`}>
                <div className="social_img" style={{ "backgroundImage": `url("${REACT_APP_SERVER_HOST}${props.social.img}")` }}></div>
            </a>
        </div>
    )
}

export default NavbarSocial