import React, { Component } from "react"
import axios from "axios"

import AdminViewTeam from "./Components/AdminViewTeam/AdminViewTeam"
import AdminAddTeamMember from "./Components/AdminAddTeamMember/AdminAddTeamMember"
import AdminEditTeamMember from "./Components/AdminEditTeamMember/AdminEditTeamMember"
import "./AdminTeam.scss"

class AdminTeam extends Component {
    constructor(props) {
        super(props)

        this.state = {
            panelType: "view",
            teamMembers: [],
            editTeamMember : {},
            offset: 0,
            pages: 1,
            pageNumber: 1,
            allTeamMembers: []
        }
    }

    async componentDidMount() {
        try {
            let teamMembersRes = await axios.get("/api/admin/get/initial/team-members")
            let allTeamMembersRes = await axios.get("/api/admin/get/all/team-members")

            let pages = teamMembersRes.data[0] === 0 ? 1 : Math.ceil(teamMembersRes.data[0] / 10)

            this.setState({
                pages: pages,
                offset: 0,
                pageNumber: 1,
                teamMembers: teamMembersRes.data[1],
                allTeamMembers: allTeamMembersRes.data
            })
        }
        catch(err) {
            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }

    changePanelType = (type, edit) => {
        if(edit && type === "edit") {
            this.setState({
                panelType: type,
                editTeamMember: edit
            })
        }
        else {
            this.setState({
                panelType: type
            })
        }
    }

    getTeamMembersAgain = async () => {
        try {
            let teamMembersRes = await axios.get("/api/admin/get/initial/team-members")
            let allTeamMembersRes = await axios.get("/api/admin/get/all/team-members")

            let pages = teamMembersRes.data[0] === 0 ? 1 : Math.ceil(teamMembersRes.data[0] / 10)

            this.setState({
                pages: pages,
                offset: 0,
                pageNumber: 1,
                teamMembers: teamMembersRes.data[1],
                allTeamMembers: allTeamMembersRes.data
            })
        }
        catch(err) {
            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }

    previousPage = async () => {
        try {
            let teamMembersRes = await axios.get(`/api/admin/get/team-members/${this.state.offset - 10}`)

            this.setState({
                teamMembers: teamMembersRes.data,
                offset: this.state.offset - 10,
                pageNumber: this.state.pageNumber - 1
            })
        }
        catch(err) {
            if(err.respone) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }

    nextPage = async () => {
        try {
            let teamMembersRes = await axios.get(`/api/admin/get/team-members/${this.state.offset + 10}`)

            this.setState({
                teamMembers: teamMembersRes.data,
                offset: this.state.offset + 10,
                pageNumber: this.state.pageNumber + 1
            })
        }
        catch(err) {
            if(err.respone) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }

    render() {
        return (
            <div className="admin_team">
                {
                    this.state.panelType === "view"
                    ?
                        <AdminViewTeam changePanelType={this.changePanelType} 
                        getTeamMembersAgain={this.getTeamMembersAgain} 
                        teamMembers={this.state.teamMembers} 
                        pages={this.state.pages} 
                        pageNumber={this.state.pageNumber} 
                        previousPage={this.previousPage} 
                        nextPage={this.nextPage} />
                    :
                    this.state.panelType === "add"
                    ?
                        <AdminAddTeamMember changePanelType={this.changePanelType} teamMembersLength={this.state.allTeamMembers.length} getTeamMembersAgain={this.getTeamMembersAgain} />
                    :
                        <AdminEditTeamMember changePanelType={this.changePanelType} teamMember={this.state.editTeamMember} getTeamMembersAgain={this.getTeamMembersAgain} team={this.state.allTeamMembers} />
                }
            </div>
        )
    }
}

export default AdminTeam