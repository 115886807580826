import React, { Component } from "react"
import axios from "axios"

import AdminViewHomeSocial from "./Components/AdminViewHomeSocial/AdminViewHomeSocials"
import AdminAddHomeSocial from "./Components/AdminAddHomeSocial/AdminAddHomeSocial"
import AdminEditHomeSocial from "./Components/AdminEditHomeSocial/AdminEditHomeSocial"
import "./AdminHomeSocials.scss"

class AdminHomeSocial extends Component {
    constructor(props) {
        super(props)

        this.state = {
            panelType: "view",
            socials: [],
            editSocial: {},
            offset: 0,
            pages: 1,
            pageNumber: 1,
            allSocials: []
        }
    }

    async componentDidMount() {
        let socialsRes = await axios.get("/api/admin/get/initial/home-socials")
        let allSocialsRes = await axios.get("/api/admin/get/all/home-socials")

        let pages = socialsRes.data[0] === 0 ? 1 : Math.ceil(socialsRes.data[0] / 10)

        this.setState({
            pages: pages,
            offset: 0,
            pageNumber: 1,
            socials: socialsRes.data[1],
            allSocials: allSocialsRes.data
        })
    }

    changePanelType = (type, edit) => {
        if(edit && type === "edit") {
            this.setState({
                panelType: type,
                editSocial: edit
            })
        }
        else {
            this.setState({
                panelType: type
            })
        }
    }

    getSocialsAgain = async () => {
        let socialsRes = await axios.get("/api/admin/get/initial/home-socials")
        let allSocialsRes = await axios.get("/api/admin/get/all/home-socials")

        let pages = socialsRes.data[0] === 0 ? 1 : Math.ceil(socialsRes.data[0] / 10)

        this.setState({
            pages: pages,
            offset: 0,
            pageNumber: 1,
            socials: socialsRes.data[1],
            allSocials: allSocialsRes.data
        })
    }

    previousPage = async () => {
        try {
            let socialsRes = await axios.get(`/api/admin/get/home-socials/${this.state.offset - 10}`)

            this.setState({
                socials: socialsRes.data,
                offset: this.state.offset - 10,
                pageNumber: this.state.pageNumber - 1
            })
        }
        catch(err) {
            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }

    nextPage = async () => {
        try {
            let socialsRes = await axios.get(`/api/admin/get/home-socials/${this.state.offset + 10}`)

            this.setState({
                social: socialsRes.data,
                offset: this.state.offset + 10,
                pageNumber: this.state.pageNumber + 1
            })
        }
        catch(err) {
            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }

    render() {
        return (
            <div className="admin_home_socials">
                {
                    this.state.panelType === "view"
                    ?
                        <AdminViewHomeSocial changePanelType={this.changePanelType} 
                        getSocialsAgain={this.getSocialsAgain} 
                        socials={this.state.socials} 
                        pages={this.state.pages} 
                        pageNumber={this.state.pageNumber} 
                        previousPage={this.previousPage} 
                        nextPage={this.nextPage} />
                    :
                    this.state.panelType === "add"
                    ?
                        <AdminAddHomeSocial changePanelType={this.changePanelType} 
                        socialsLength={this.state.allSocials.length} 
                        getSocialsAgain={this.getSocialsAgain} 
                        updateNavSocials={this.props.updateNavSocials} />
                    :
                        <AdminEditHomeSocial changePanelType={this.changePanelType} 
                        social={this.state.editSocial} 
                        getSocialsAgain={this.getSocialsAgain} 
                        socials={this.state.allSocials} 
                        updateNavSocials={this.props.updateNavSocials} />

                }
            </div>
        )
    }
}

export default AdminHomeSocial