import React from "react"

import "./HomeVideoIcon.scss"

let HomeVideoIcon = (props) => {
    const { REACT_APP_SERVER_HOST } = process.env

    let className = props.activeVideo === props.index ? "game_icon_active icon_active" : "game_icon_active"

    let changeGame = () => {
        props.iconClick(props.index)
    }

    return (
        <div className="game_icon_grey" style={{ "backgroundImage": `url("${REACT_APP_SERVER_HOST}${props.iconGreyed}")` }} onClick={changeGame}>
            <div className={className} style={{ "backgroundImage": `url("${REACT_APP_SERVER_HOST}${props.iconActive}")` }}></div>
        </div>
    )
}

export default HomeVideoIcon