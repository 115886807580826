import React, { Component } from "react"

import "./AdminPreviewHomeVideo.scss"

class AdminPreviewHomeVideo extends Component {
    constructor(props) {
        super(props)

        this.videoRef = React.createRef()
        this.logoRef = React.createRef()
        this.iconGreyedRef = React.createRef()
        this.iconActiveRef = React.createRef()
    }

    componentDidMount() {
        const { REACT_APP_SERVER_HOST } = process.env

        this.videoRef.current.load()

        let img = new Image()
        img.onload = () => {
            this.logoRef.current.style.backgroundImage = `url("${REACT_APP_SERVER_HOST}${this.props.logo}")`
            this.logoRef.current.style.width = `${img.width}px`
            this.logoRef.current.style.height = `${img.height}px`
        }
        img.src = `${REACT_APP_SERVER_HOST}${this.props.logo}`

        this.iconGreyedRef.current.style.backgroundImage = `url("${REACT_APP_SERVER_HOST}${this.props.iconGreyed}")`
        this.iconActiveRef.current.style.backgroundImage = `url("${REACT_APP_SERVER_HOST}${this.props.iconActive}")`

    }

    componentDidUpdate(prevProps) {
        const { REACT_APP_SERVER_HOST } = process.env

        if(this.props.videoSource !== prevProps.videoSource || this.props.backupSource !== prevProps.backupSource) {
            this.videoRef.current.load()
        }

        if(this.props.logo !== prevProps.logo) {
            let img = new Image()
            img.onload = () => {
                this.logoRef.current.style.backgroundImage = `url("${REACT_APP_SERVER_HOST}${this.props.logo}")`
                this.logoRef.current.style.width = `${img.width}px`
                this.logoRef.current.style.height = `${img.height}px`
            }
            img.src = `${REACT_APP_SERVER_HOST}${this.props.logo}`

        }

        if(this.props.iconGreyed !== prevProps.iconGreyed) {
            this.iconGreyedRef.current.style.backgroundImage = `url("${REACT_APP_SERVER_HOST}${this.props.iconGreyed}")`
        }

        if(this.props.iconActive !== prevProps.iconActive) {
            this.iconActiveRef.current.style.backgroundImage = `url("${REACT_APP_SERVER_HOST}${this.props.iconActive}")`
        }
    }

    reloadVideo = () => {
        this.videoRef.current.load()
    }

    stopRun = (e) => {
        e.stopPropagation()
    }
    
    render() {
        const { REACT_APP_SERVER_HOST } = process.env
        let className = this.props.show ? "admin_preview_home_video preview_show" : "admin_preview_home_video preview_hide"
        return (
            <div className={className} onClick={this.props.openClosePreview}>
                <div className="admin_preview_home_video_panel" onClick={this.stopRun}>
                    <div className="admin_preview_home_video_reload">
                        <p>Video not loading?</p>
                        <button onClick={this.reloadVideo}>Reload</button>
                    </div>
                    <div className="admin_preview_home_video_video_container">
                        <video autoPlay loop preload="auto" ref={this.videoRef}>
                            <source src={`${REACT_APP_SERVER_HOST}${this.props.videoSource}`} type={this.props.videoMimeType} />
                            <source src={`${REACT_APP_SERVER_HOST}${this.props.backupSource}`} type={this.props.backupMimeType} />
                            Your browser does not support this video
                        </video>
                        <div className="admin_preview_home_video_overlay"></div>
                    </div>
                    <div className="preview_home_video_logo" ref={this.logoRef}></div>
                    <div className="preview_home_video_icon_greyed" ref={this.iconGreyedRef}>
                        <div className="preview_home_video_icon_active" ref={this.iconActiveRef}></div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AdminPreviewHomeVideo