import React, { Component } from "react"
import axios from "axios"

import "./AdminEditHomeSocial.scss"

class AdminEditHomeSocial extends Component {
    constructor(props) {
        super(props)

        this.state = {
            name: "",
            img: "",
            url: "",
            imgName: "No file selected.",
            oldImgName: "",
            deleteImgName: "",
            order: 0,
            changeOrder: false,
            swapSocialId: 0,
            manualOrderReset: false
        }
    }

    componentDidMount() {
        let oldImgName = this.props.social.img.split("HomeSocials/")[1]
        this.setState({
            name: this.props.social.name,
            img: this.props.social.img,
            url: this.props.social.url,
            oldImgName: oldImgName,
            order: this.props.social.social_order
        })
    }

    componentDidUpdate(prevProps) {
        if(this.props.social.id !== prevProps.social.id) {
            let oldImgName = this.props.social.img.split("HomeSocials/")[1]
            this.setState({
                name: this.props.social.name,
                img: this.props.social.img,
                url: this.props.social.url,
                oldImgName: oldImgName
            })
        }
    }

    changeToView = () => {
        this.props.changePanelType("view")
    }

    updateInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    uploadSocialImg = async (e) => {
        try {
            let file = e.target.files[0]
    
            if(file.type === "image/png" || file.type === "image/bmp" || file.type === "image/jpeg" || file.type === "image/jpg" || file.type === "image/gif" || file.type === "image/x-icon") {
                let regex = /\s+|[,/\\]/g
                if(!file.name.match(regex)) {
                    if(((file.size / 1000) / 1000) > 10) {
                        alert("File is too large to store please upload a smaller image")
                    }
                    else {
                        const formData = new FormData()
            
                        formData.append("homeSocialImg", file)
            
                        if(this.state.imgName !== "No file selected.") {
                            let deleteFile = new File(["yes"], "delete", { type: "text/plain" })
                            let imgNameFile = new File([`${this.state.deleteImgName}`], "iconName", { type: "text/plain" })
                
                            formData.append("delete", deleteFile)
                            formData.append("imgName", imgNameFile)
                        }
    
                        let imgRes = await axios.post("/api/admin/add/home-social-img", formData)
    
                        e.target.value = null
    
                        this.setState({
                            img: imgRes.data[0],
                            imgName: imgRes.data[1],
                            deleteImgName: imgRes.data[2]
                        })
                    }
                }
                else {
                    alert("There must be no spaces, forward slashes or back slashes in the image name")
                }
            }
            else {
                alert("Image must be of type png, jpg, gif, ico or bmp")
            }
        }
        catch(err) {
            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }

    changeSocialOrder = (e) => {
        let social = this.props.socials.find((social) => social.social_order === Number(e.target.value))

        this.setState({
            order: Number(e.target.value),
            changeOrder: true,
            swapSocialId: social.id
        })
    }

    updateHomeSocial = async () => {
        try {
            let updateBtn = document.getElementById("admin_edit_home_social_update_btn")
            updateBtn.innerHTML = ""
            updateBtn.classList.add("spinner")

            if(!this.state.name || !this.state.img || !this.state.url || !this.state.order) {
                alert("You must add a name, upload an image and add an url")

                updateBtn.innerHTML = "Update"
                updateBtn.classList.remove("spinner")
            }
            else {
                let regExp = /^(ftp|http|https):\/\/[^ "]+$/
                if(this.state.url.match(regExp)) {
                    let socialRes = await axios.put("/api/admin/update/home-social", { 
                        id: this.props.social.id, 
                        name: this.state.name, 
                        img: this.state.img, 
                        url: this.state.url, 
                        changeOrder: this.state.changeOrder, 
                        swapSocialId: this.state.swapSocialId 
                    })
    
                    if(this.state.imgName !== "No file selected.") {
                        await axios.delete(`/api/admin/delete/old-social-img/${this.state.oldImgName}`)
                    }
    
                    alert(socialRes.data)
    
                    updateBtn.innerHTML = "Update"
                    updateBtn.classList.remove("spinner")
    
                    this.setState({
                        name: "",
                        img: "",
                        url: "",
                        imgName: "No file selected.",
                        oldImgName: "",
                        deleteImgName: "",
                        order: 0,
                        changeOrder: false,
                        swapSocialId: 0
                    }, async () => {
                        await this.props.updateNavSocials()
                        await this.props.getSocialsAgain()
                        this.props.changePanelType("view")
                    })
                }
                else {
                    alert("Invalid url")
                }
            }
        }
        catch(err) {
            let updateBtn = document.getElementById("admin_edit_home_social_update_btn")

            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }

            updateBtn.innerHTML = "Update"
            updateBtn.classList.remove("spinner")

            await this.props.getSocialsAgain()
            this.props.changePanelType("view")
        }
    }

    toggleOrderReset = () => {
        this.setState({
            manualOrderReset: !this.state.manualOrderReset
        })
    }


    manuallyUpdateOrder = async () => {
        try {
            let updateBtn = document.getElementById("home_social_update_order_btn")
            updateBtn.innerHTML = ""
            updateBtn.classList.add("spinner")

            if( this.state.order > 2147483647 || this.state.order <= 0) {
                alert("Order must be above 0 and less than 2147483647")

                updateBtn.innerHTML = "Update Order"
                updateBtn.classList.remove("spinner")
            }
            else {
                let orderRes = await axios.put("/api/admin/update/home-social/order", { id: this.props.social.id, order: this.state.order })

                alert(orderRes.data)

                updateBtn.innerHTML = "Update Order"
                updateBtn.classList.remove("spinner")

                await this.props.getSocialsAgain()
            }
        }
        catch(err) {
            let updateBtn = document.getElementById("home_social_update_order_btn")

            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }

            updateBtn.innerHTML = "Update Order"
            updateBtn.classList.remove("spinner")
        }
    }

    render() {
        const { REACT_APP_SERVER_HOST } = process.env
        let socialOrders = this.props.socials.map((social, i) => {
            return (
                <option key={i} value={social.social_order}>{social.social_order}</option>
            )
        })
        return (
            <div className="admin_edit_home_social">
                <h4>
                    <strong>Admin Navbar Socials</strong>
                </h4>
                <div className="admin_form_preview_container">
                    <div className="admin_form_container">
                        <div className="admin_input_container">
                            <p>Name:</p>
                            <input type="text" 
                            name="name" 
                            placeholder="Name" 
                            value={this.state.name} 
                            onChange={this.updateInput} />
                        </div>
                        <div className="admin_input_container">
                            <p>Image (17 x 15):</p>
                            <div className="admin_file_upload_container">
                                <input type="file" 
                                accept="image/*" 
                                onChange={this.uploadSocialImg} />
                                <p>{this.state.imgName}</p>
                            </div>
                        </div>
                        <div className="admin_input_container">
                            <p>URL:</p>
                            <input type="text"
                            name="url" 
                            placeholder="https://example.com" 
                            value={this.state.url} 
                            onChange={this.updateInput} />
                        </div>
                        <div className="admin_input_container">
                            <p>Order:</p>
                            <select name="order" value={this.state.order} onChange={this.changeSocialOrder}>
                                {socialOrders}
                            </select>
                        </div>
                        <div className="admin_input_container">
                            <p>Social order not working correctly? Click the button below to manually update this social's order</p>
                            {
                                this.state.manualOrderReset
                                ?
                                    <div className="admin_order_change_container">
                                        <button className="admin_store_order_reset_cancel_btn" onClick={this.toggleOrderReset}>X</button>
                                        <input type="number" 
                                        name="order" 
                                        placeholder="0" 
                                        value={this.state.order} 
                                        onChange={this.updateInput} />
                                        <button id="home_social_update_order_btn" 
                                        className="admin_spinner_container" 
                                        onClick={this.manuallyUpdateOrder}>Update Order</button>
                                    </div>
                                :
                                    <div className="admin_order_change_container">
                                        <button onClick={this.toggleOrderReset}>Set Order</button>
                                    </div>
                            }
                        </div>
                    </div>
                    <div className="admin_preview_container">
                        <div className="admin_edit_social_preview">
                            <p>FOLLOW US</p>
                            <div className="logo_preview" style={ { "backgroundImage": `url("${REACT_APP_SERVER_HOST}${this.state.img}")` } }></div>
                        </div>
                    </div>
                </div>
                <div className="admin_btn_container">
                    <button onClick={this.changeToView}>Cancel</button>
                    <button id="admin_edit_home_social_update_btn" 
                    className="admin_spinner_container" 
                    disabled={!this.state.name || !this.state.img || !this.state.url || !this.state.order} 
                    onClick={this.updateHomeSocial}>Update</button>
                </div>
            </div>
        )
    }
}

export default AdminEditHomeSocial