import React from "react"

import AdminGame from "./Components/AdminGame/AdminGame"
import "./AdminViewGames.scss"

let AdminViewGames = (props) => {
    let changeToAdd = () => {
        props.changePanelType("add")
    }

    let games = props.games.map((game, i) => {
        return (
            <AdminGame key={i} game={game} changePanelType={props.changePanelType} getGamesAgain={props.getGamesAgain} />
        )
    })

    return (
        <div className="admin_view_games">
            <div className="admin_view_page_info_container">
                <h4>
                    Games page - {props.pageNumber}
                </h4>
                <button onClick={changeToAdd}>New</button>
            </div>
            <div className="admin_view_games_games_container">
                {games}
            </div>
            <div className="admin_previous_next_container">
                <button disabled={props.pageNumber === 1} onClick={props.previousPage}>Previous</button>
                <button disabled={props.pageNumber === props.pages} onClick={props.nextPage}>Next</button>
            </div>
        </div>
    )
}

export default AdminViewGames