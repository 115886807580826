import React, { Component } from "react"
import axios from "axios"

import AdminViewGames from "./Components/AdminViewGames/AdminViewGames"
import AdminAddGame from "./Components/AdminAddGame/AdminAddGame"
import AdminEditGame from "./Components/AdminEditGame/AdminEditGame"
import "./AdminGames.scss"

class AdminGames extends Component {
    constructor(props) {
        super(props)

        this.state = {
            panelType: "view",
            games: [],
            editGame: {},
            offset: 0,
            pages: 1,
            pageNumber: 1,
            allGames: []
        }
    }

    async componentDidMount() {
        let gamesRes = await axios.get("/api/admin/get/initial/games")
        let allGamesRes = await axios.get("/api/admin/get/all/games")

        let pages = gamesRes.data[0] === 0 ? 1 : Math.ceil(gamesRes.data[0] / 10)

        this.setState({
            pages: pages,
            offset: 0,
            pageNumber: 1,
            games: gamesRes.data[1],
            allGames: allGamesRes.data
        })
    }

    changePanelType = (type, edit) => {
        if(edit && type === "edit") {
            this.setState({
                panelType: type,
                editGame: edit
            })
        }
        else {
            this.setState({
                panelType: type
            })
        }
    }

    getGamesAgain = async () => {
        let gamesRes = await axios.get("/api/admin/get/initial/games")
        let allGamesRes = await axios.get("/api/admin/get/all/games")

        let pages = gamesRes.data[0] === 0 ? 1 : Math.ceil(gamesRes.data[0] / 10)

        this.setState({
            pages: pages,
            offset: 0,
            pageNumber: 1,
            games: gamesRes.data[1],
            allGames: allGamesRes.data
        })
    }

    previousPage = async () => {
        try {
            let gamesRes = await axios.get(`/api/admin/get/games/${this.state.offset - 10}`)

            this.setState({
                games: gamesRes.data,
                offset: this.state.offset - 10,
                pageNumber: this.state.pageNumber - 1
            })
        }
        catch(err) {
            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }

    nextPage = async () => {
        try {
            let gamesRes = await axios.get(`/api/admin/get/games/${this.state.offset + 10}`)

            this.setState({
                games: gamesRes.data,
                offset: this.state.offset + 10,
                pageNumber: this.state.pageNumber + 1
            })
        }
        catch(err) {
            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }

    render() {
        return (
            <div className="admin_games">
                {
                    this.state.panelType === "view"
                    ?
                        <AdminViewGames changePanelType={this.changePanelType} 
                        getGamesAgain={this.getGamesAgain} 
                        games={this.state.games} 
                        pages={this.state.pages} 
                        pageNumber={this.state.pageNumber} 
                        previousPage={this.previousPage} 
                        nextPage={this.nextPage} />
                    :
                    this.state.panelType === "add"
                    ?
                        <AdminAddGame changePanelType={this.changePanelType} gamesLength={this.state.allGames.length} getGamesAgain={this.getGamesAgain} />
                    :
                        <AdminEditGame changePanelType={this.changePanelType} game={this.state.editGame} getGamesAgain={this.getGamesAgain} games={this.state.allGames} />
                }
            </div>
        )
    }
}

export default AdminGames