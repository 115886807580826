import React, { Component } from "react"
import axios from "axios"

import AdminPreviewGame from "../AdminPreviewGame/AdminPreviewGame"
import "./AdminEditGame.scss"

class AdminEditGame extends Component {
    constructor(props) {
        super(props)

        this.state = {
            name: "",
            description: "",
            developer: "",
            backgroundImg: "",
            logo: "",
            overlay: "",
            video: "",
            website: "",
            marginTop: 0,
            marginLeft: 0,
            backgroundImgName: "No file selected.",
            logoImgName: "No file selected.",
            overlayImgName: "No file selected.",
            deleteBackgroundImgName: "",
            deleteLogoImgName: "",
            deleteOverlayImgName: "",
            oldBackgroundName: "",
            oldLogoName: "",
            oldOverlayName: "",
            order: 0,
            changeOrder: false,
            swapGameId: 0,
            previewShow: false,
            manualOrderReset: false
        }
    }

    componentDidMount() {
        let oldBackgroundName = this.props.game.background_img.split("GameBackgrounds/")[1]
        let oldLogoName = this.props.game.logo.split("GameLogos/")[1]
        let oldOverlayName = this.props.game.background_overlay.split("GameOverlays/")[1]
        let website = ""
        if(this.props.game.website) {
            website = this.props.game.website
        }
        this.setState({
            name: this.props.game.name,
            description: this.props.game.description,
            developer: this.props.game.developer,
            backgroundImg: this.props.game.background_img,
            logo: this.props.game.logo,
            overlay: this.props.game.background_overlay,
            video: this.props.game.video,
            website: website,
            marginTop: this.props.game.margin_top,
            marginLeft: this.props.game.margin_left,
            oldBackgroundName: oldBackgroundName,
            oldLogoName: oldLogoName,
            oldOverlayName: oldOverlayName,
            order: this.props.game.game_order
        })
    }

    componentDidUpdate(prevProps) {
        if(this.props.game.id !== prevProps.game.id) {
            let oldBackgroundName = this.props.game.background_img.split("GameBackgrounds/")[1]
            let oldLogoName = this.props.game.logo.split("GameLogos/")[1]
            let oldOverlayName = this.props.game.background_overlay.split("GameOverlays/")[1]
            let website = ""
            if(this.props.game.website) {
                website = this.props.game.website
            }
            this.setState({
                name: this.props.game.name,
                description: this.props.game.description,
                developer: this.props.game.developer,
                backgroundImg: this.props.game.background_img,
                logo: this.props.game.logo,
                overlay: this.props.game.background_overlay,
                video: this.props.game.video,
                website: website,
                marginTop: this.props.game.margin_top,
                marginLeft: this.props.game.margin_left,
                oldBackgroundName: oldBackgroundName,
                oldLogoName: oldLogoName,
                oldOverlayName: oldOverlayName,
                order: this.props.game.game_order
            })
        }
    }

    changeToView = () => {
        this.props.changePanelType("view")
    }

    updateInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    uploadBackgroundImage = async (e) => {
        try {
            let file = e.target.files[0]
    
            if(file.type === "image/png" || file.type === "image/bmp" || file.type === "image/jpeg" || file.type === "image/jpg" || file.type === "image/gif" || file.type === "image/x-icon") {
                let regex = /\s+|[,/\\]/g
                if(!file.name.match(regex)) {
                    if(((file.size / 1000) / 1000) > 10) {
                        alert("File is too large to store please upload a smaller image")
                    }
                    else {
                        const formData = new FormData()
            
                        formData.append("gameBackgroundImg", file)
            
                        if(this.state.backgroundImgName !== "No file selected.") {
                            let deleteFile = new File(["yes"], "delete", { type: "text/plain" })
                            let imgNameFile = new File([`${this.state.deleteBackgroundImgName}`], "backgroundName", { type: "text/plain" })
                
                            formData.append("delete", deleteFile)
                            formData.append("imgName", imgNameFile)
                        }
    
                        let backgroundImgRes = await axios.post("/api/admin/add/game-background", formData)
    
                        e.target.value = null
    
                        this.setState({
                            backgroundImg: backgroundImgRes.data[0],
                            backgroundImgName: backgroundImgRes.data[1],
                            deleteBackgroundImgName: backgroundImgRes.data[2]
                        })
                    }
                }
                else {
                    alert("There must be no spaces, forward slashes or back slashes in the image name")
                }
            }
            else {
                alert("Image must be of type png, jpg, gif, ico or bmp")
            }
        }
        catch(err) {
            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }

    uploadLogoImage = async (e) => {
        try {
            let file = e.target.files[0]
    
            if(file.type === "image/png" || file.type === "image/bmp" || file.type === "image/jpeg" || file.type === "image/jpg" || file.type === "image/gif" || file.type === "image/x-icon") {
                let regex = /\s+|[,/\\]/g
                if(!file.name.match(regex)) {
                    if(((file.size / 1000) / 1000) > 10) {
                        alert("File is too large to store please upload a smaller image")
                    }
                    else {
                        const formData = new FormData()
            
                        formData.append("gameLogoImg", file)
            
                        if(this.state.logoImgName !== "No file selected.") {
                            let deleteFile = new File(["yes"], "delete", { type: "text/plain" })
                            let imgNameFile = new File([`${this.state.deleteLogoImgName}`], "iconName", { type: "text/plain" })
                
                            formData.append("delete", deleteFile)
                            formData.append("imgName", imgNameFile)
                        }
    
                        let logoImgRes = await axios.post("/api/admin/add/game-logo", formData)
    
                        e.target.value = null
    
                        this.setState({
                            logo: logoImgRes.data[0],
                            logoImgName: logoImgRes.data[1],
                            deleteLogoImgName: logoImgRes.data[2]
                        })
                    }
                }
                else {
                    alert("There must be no spaces, forward slashes or back slashes in the image name")
                }
            }
            else {
                alert("Image must be of type png, jpg, gif, ico or bmp")
            }
        }
        catch(err) {
            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }

    uploadOverlayImg = async (e) => {
        try {
            let file = e.target.files[0]
    
            if(file.type === "image/png" || file.type === "image/bmp" || file.type === "image/jpeg" || file.type === "image/jpg" || file.type === "image/gif" || file.type === "image/x-icon") {
                let regex = /\s+|[,/\\]/g
                if(!file.name.match(regex)) {
                    if(((file.size / 1000) / 1000) > 10) {
                        alert("File is too large to store please upload a smaller image")
                    }
                    else {
                        const formData = new FormData()
            
                        formData.append("gameOverlayImg", file)
            
                        if(this.state.overlayImgName !== "No file selected.") {
                            let deleteFile = new File(["yes"], "delete", { type: "text/plain" })
                            let imgNameFile = new File([`${this.state.deleteOverlayImgName}`], "iconName", { type: "text/plain" })
                
                            formData.append("delete", deleteFile)
                            formData.append("imgName", imgNameFile)
                        }
    
                        let overlayImgRes = await axios.post("/api/admin/add/game-overlay", formData)
    
                        e.target.value = null
    
                        this.setState({
                            overlay: overlayImgRes.data[0],
                            overlayImgName: overlayImgRes.data[1],
                            deleteOverlayImgName: overlayImgRes.data[2]
                        })
                    }
                }
                else {
                    alert("There must be no spaces, forward slashes or back slashes in the image name")
                }
            }
            else {
                alert("Image must be of type png, jpg, gif, ico or bmp")
            }
        }
        catch(err) {
            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }

    changeGameOrder = (e) => {
        let game = this.props.games.find((game) => game.game_order === Number(e.target.value))

        this.setState({
            order: Number(e.target.value),
            changeOrder: true,
            swapGameId: game.id
        })
    }

    openClosePreview = () => {
        this.setState({
            previewShow: !this.state.previewShow
        })
    }

    updateGame = async () => {
        try {
            let updateBtn = document.getElementById("admin_edit_game_update_btn")
            updateBtn.innerHTML = ""
            updateBtn.classList.add("spinner")

            if(!this.state.name || !this.state.description || !this.state.developer || !this.state.backgroundImg || !this.state.logo || !this.state.video) {
                alert("Must have add name, add a description, add a developer, upload a background image, upload a logo and add a youtube video url")

                updateBtn.innerHTML = "Update"
                updateBtn.classList.remove("spinner")
            }
            else {
                if(this.state.description.length > 500) {
                    alert("Description must be under 500 characters")
                    updateBtn.innerHTML = "Update"
                    updateBtn.classList.remove("spinner")
                }
                else {
                    if(this.state.marginTop > 500 || this.state.marginTop < -500) {
                        alert("Logo margin top must be less than 500 and more than -500")
    
                        updateBtn.innerHTML = "Update"
                        updateBtn.classList.remove("spinner")
                    }
                    else {
                        let regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
                        let match = this.state.video.match(regExp)
        
                        if(match && match[2].length >= 11) {
                            let websiteRegExp = /^(ftp|http|https):\/\/[^ "]+$/
                            if(this.state.website) {
                                if(this.state.website.match(websiteRegExp)) {
                                    let gameRes = await axios.put("/api/admin/update/game", {
                                        id: this.props.game.id,
                                        name: this.state.name, 
                                        description: this.state.description,
                                        developer: this.state.developer,
                                        backgroundImg: this.state.backgroundImg,
                                        logo: this.state.logo,
                                        overlay: this.state.overlay,
                                        video: this.state.video,
                                        website: this.state.website,
                                        marginTop: this.state.marginTop,
                                        marginLeft: this.state.marginLeft,
                                        changeOrder: this.state.changeOrder,
                                        swapGameId: this.state.swapGameId
                                    })
                
                                    alert(gameRes.data)
                
                                    updateBtn.innerHTML = "Update"
                                    updateBtn.classList.remove("spinner")
                                    
                                    if(this.state.backgroundImgName !== "No file selected.") {
                                        await axios.delete(`/api/admin/delete/old-game-background-img/${this.state.oldBackgroundName}`)
                                    }
                    
                                    if(this.state.logoImgName !== "No file selected.") {
                                        await axios.delete(`/api/admin/delete/old-game-logo/${this.state.oldLogoName}`)
                                    }
                
                                    if(this.state.overlayImgName !== "No file selected.") {
                                        await axios.delete(`/api/admin/delete/old-game-overlay/${this.state.oldOverlayName}`)
                                    }
                    
                                    this.setState({
                                        name: "",
                                        description: "",
                                        developer: "",
                                        backgroundImg: "",
                                        logo: "",
                                        video: "",
                                        website: "",
                                        marginTop: 0,
                                        marginLeft: 0,
                                        backgroundImgName: "No file selected.",
                                        logoImgName: "No file selected.",
                                        deleteBackgroundImgName: "",
                                        deleteLogoImgName: "",
                                        oldBackgroundName: "",
                                        oldLogoName: "",
                                        order: 0,
                                        changeOrder: false,
                                        swapGameId: 0
                                    }, async () => {
                                        await this.props.getGamesAgain()
                                        this.props.changePanelType("view")
                                    })
                                }
                                else {
                                    alert("Invalid website url. If you are going to update the website url you need to have a valid website url if you want to update the game to not have a website please clear out the website input field")
        
                                    updateBtn.innerHTML = "Update"
                                    updateBtn.classList.remove("spinner")
                                }
                            }
                            else {
                                let gameRes = await axios.put("/api/admin/update/game", {
                                    id: this.props.game.id,
                                    name: this.state.name, 
                                    description: this.state.description,
                                    developer: this.state.developer,
                                    backgroundImg: this.state.backgroundImg,
                                    logo: this.state.logo,
                                    overlay: this.state.overlay,
                                    video: this.state.video,
                                    website: this.state.website,
                                    marginTop: this.state.marginTop,
                                    marginLeft: this.state.marginLeft,
                                    changeOrder: this.state.changeOrder,
                                    swapGameId: this.state.swapGameId
                                })
            
                                alert(gameRes.data)
            
                                updateBtn.innerHTML = "Update"
                                updateBtn.classList.remove("spinner")
                                
                                if(this.state.backgroundImgName !== "No file selected.") {
                                    await axios.delete(`/api/admin/delete/old-game-background-img/${this.state.oldBackgroundName}`)
                                }
                
                                if(this.state.logoImgName !== "No file selected.") {
                                    await axios.delete(`/api/admin/delete/old-game-logo/${this.state.oldLogoName}`)
                                }
            
                                if(this.state.overlayImgName !== "No file selected.") {
                                    await axios.delete(`/api/admin/delete/old-game-overlay/${this.state.oldOverlayName}`)
                                }
                
                                this.setState({
                                    name: "",
                                    description: "",
                                    developer: "",
                                    backgroundImg: "",
                                    logo: "",
                                    video: "",
                                    website: "",
                                    marginTop: 0,
                                    marginLeft: 0,
                                    backgroundImgName: "No file selected.",
                                    logoImgName: "No file selected.",
                                    deleteBackgroundImgName: "",
                                    deleteLogoImgName: "",
                                    oldBackgroundName: "",
                                    oldLogoName: "",
                                    order: 0,
                                    changeOrder: false,
                                    swapGameId: 0
                                }, async () => {
                                    await this.props.getGamesAgain()
                                    this.props.changePanelType("view")
                                })
                            }
                        }
                        else {
                            alert("Invalid youtube url")
        
                            updateBtn.innerHTML = "Update"
                            updateBtn.classList.remove("spinner")
                        }
                    }
                }
            }
        }
        catch(err) {
            let updateBtn = document.getElementById("admin_edit_game_update_btn")

            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }

            updateBtn.innerHTML = "Update"
            updateBtn.classList.remove("spinner")
        }
    }

    toggleOrderReset = () => {
        this.setState({
            manualOrderReset: !this.state.manualOrderReset
        })
    }


    manuallyUpdateOrder = async () => {
        try {
            let updateBtn = document.getElementById("game_update_order_btn")
            updateBtn.innerHTML = ""
            updateBtn.classList.add("spinner")

            if( this.state.order > 2147483647 || this.state.order <= 0) {
                alert("Order must be above 0 and less than 2147483647")

                updateBtn.innerHTML = "Update Order"
                updateBtn.classList.remove("spinner")
            }
            else {
                let orderRes = await axios.put("/api/admin/update/game/order", { id: this.props.game.id, order: this.state.order })

                alert(orderRes.data)

                updateBtn.innerHTML = "Update Order"
                updateBtn.classList.remove("spinner")

                await this.props.getGamesAgain()
            }
        }
        catch(err) {
            let updateBtn = document.getElementById("game_update_order_btn")

            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }

            updateBtn.innerHTML = "Update Order"
            updateBtn.classList.remove("spinner")
        }
    }

    render() {
        let gamesOrder = this.props.games.map((game, i) => {
            return (
                <option key={i} value={game.game_order}>{game.game_order}</option>
            )
        })
        return (
            <div className="admin_edit_game">
                <div className="admin_form_container">
                    <div className="admin_input_container">
                        <p>Name:</p>
                        <input type="text" 
                        name="name" 
                        placeholder="Name" 
                        value={this.state.name} 
                        onChange={this.updateInput} />
                    </div>
                    <div className="admin_input_container">
                        <p>Developer:</p>
                        <input type="text" 
                        name="developer" 
                        placeholder="Developer" 
                        value={this.state.developer} 
                        onChange={this.updateInput} />
                    </div>
                    <div className="admin_input_container">
                        <div className="admin_char_limit_container">
                            <p>Description:</p>
                            <p>{this.state.description.length} / 500</p>
                        </div>
                        <textarea name="description" 
                        placeholder="Description" 
                        cols="30" 
                        rows="10" 
                        value={this.state.description} 
                        onChange={this.updateInput}></textarea>
                    </div>
                    <div className="admin_input_container">
                        <p>Background Image (at least 1000 x 418):</p>
                        <div className="admin_file_upload_container">
                            <input type="file" 
                            accept="image/*" 
                            onChange={this.uploadBackgroundImage} />
                            <p>{this.state.backgroundImgName}</p>
                        </div>
                    </div>
                    <div className="admin_input_container">
                        <p>Logo Image (no more than 400 x 150):</p>
                        <div className="admin_file_upload_container">
                            <input type="file" 
                            accept="image/*" 
                            onChange={this.uploadLogoImage} />
                            <p>{this.state.logoImgName}</p>
                        </div>
                    </div>
                    <div className="admin_input_container">
                        <p>Logo Margin Top:</p>
                        <input type="number" 
                        name="marginTop" 
                        value={this.state.marginTop} 
                        onChange={this.updateInput} />
                    </div>
                    <div className="admin_input_container">
                        <p>Logo Margin Left:</p>
                        <input type="number" 
                        name="marginLeft" 
                        value={this.state.marginLeft} 
                        onChange={this.updateInput} />
                    </div>
                    <div className="admin_input_container">
                        <p>Overlay Background (1920 x 1080):</p>
                        <div className="admin_file_upload_container">
                            <input type="file" 
                            accept="image/*" 
                            onChange={this.uploadOverlayImg} />
                            <p>{this.state.overlayImgName}</p>
                        </div>
                    </div>
                    <div className="admin_input_container">
                        <p>Youtube Video URL:</p>
                        <input type="text" 
                        name="video" 
                        placeholder="https://www.youtube.com/watch?v=-skdJDgpPeE" 
                        value={this.state.video} 
                        onChange={this.updateInput} />
                    </div>
                    <div className="admin_input_container">
                        <p>Official Website (optional):</p>
                        <input type="text" 
                        name="website" 
                        placeholder="https://example.com" 
                        value={this.state.website} 
                        onChange={this.updateInput} />
                    </div>
                    <div className="admin_input_container">
                        <p>Order:</p>
                        <select name="order" value={this.state.order} onChange={this.changeGameOrder}>
                            {gamesOrder}
                        </select>
                    </div>
                    <div className="admin_input_container">
                        <p>Game order not working correctly? Click the button below to manually update this game's order</p>
                        {
                            this.state.manualOrderReset
                            ?
                                <div className="admin_order_change_container">
                                    <button className="admin_store_order_reset_cancel_btn" onClick={this.toggleOrderReset}>X</button>
                                    <input type="number" 
                                    name="order" 
                                    placeholder="0" 
                                    value={this.state.order} 
                                    onChange={this.updateInput} />
                                    <button id="game_update_order_btn" 
                                    className="admin_spinner_container" 
                                    onClick={this.manuallyUpdateOrder}>Update Order</button>
                                </div>
                            :
                                <div className="admin_order_change_container">
                                    <button onClick={this.toggleOrderReset}>Set Order</button>
                                </div>
                        }
                    </div>
                    <div className="admin_btn_container">
                        <button onClick={this.changeToView}>Cancel</button>
                        <div className="admin_two_btn">
                            <button onClick={this.openClosePreview}>Preview</button>
                            <button id="admin_edit_game_update_btn" 
                            className="admin_spinner_container" 
                            disabled={!this.state.name || 
                                !this.state.description || 
                                !this.state.developer || 
                                !this.state.backgroundImg || 
                                !this.state.logo || 
                                !this.state.overlay || 
                                !this.state.video} 
                            onClick={this.updateGame}>Update</button>
                        </div>
                    </div>
                </div>
                <AdminPreviewGame show={this.state.previewShow} 
                openClosePreview={this.openClosePreview} 
                name={this.state.name} 
                description={this.state.description} 
                developer={this.state.developer} 
                backgroundImg={this.state.backgroundImg} 
                logo={this.state.logo} 
                marginTop={this.state.marginTop} 
                marginLeft={this.state.marginLeft} 
                overlay={this.state.overlay} 
                video={this.state.video} />
            </div>
        )
    }
}

export default AdminEditGame