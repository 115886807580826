import React, { Component } from "react"
import axios from "axios"

import "./AdminAddTeamMember.scss"

class AdminAddTeam extends Component {
    constructor(props) {
        super(props)

        this.state = {
            img: "",
            name: "",
            title: "",
            favoriteGameOne: "",
            favoriteGameTwo: "",
            imgName: "No file selected.",
            deleteImgName: ""
        }
    }

    changeToView = () => {
        this.props.changePanelType("view")
    }

    updateInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    uploadImg = async (e) => {
        try {
            let file = e.target.files[0]
    
            if(file.type === "image/png" || file.type === "image/bmp" || file.type === "image/jpeg" || file.type === "image/jpg" || file.type === "image/gif" || file.type === "image/x-icon") {
                let regex = /\s+|[,/\\]/g
                if(!file.name.match(regex)) {
                    if(((file.size / 1000) / 1000) > 10) {
                        alert("File is too large to store please upload a smaller image")
                    }
                    else {
                        const formData = new FormData()
            
                        formData.append("teamMemberImg", file)
            
                        if(this.state.imgName !== "No file selected.") {
                            let deleteFile = new File(["yes"], "delete", { type: "text/plain" })
                            let imgNameFile = new File([`${this.state.deleteImgName}`], "iconName", { type: "text/plain" })
                
                            formData.append("delete", deleteFile)
                            formData.append("imgName", imgNameFile)
                        }
    
                        let imgRes = await axios.post("/api/admin/add/team-member-img", formData)
    
                        e.target.value = null
    
                        this.setState({
                            img: imgRes.data[0],
                            imgName: imgRes.data[1],
                            deleteImgName: imgRes.data[2]
                        })
                    }
                }
                else {
                    alert("There must be no spaces, forward slashes or back slashes in the image name")
                }
            }
            else {
                alert("Image must be of type png, jpg, gif, ico or bmp")
            }
        }
        catch(err) {
            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }

    addTeamMember = async () => {
        try {
            let addBtn = document.getElementById("admin_add_team_member_add_btn")
            addBtn.innerHTML = ""
            addBtn.classList.add("spinner")

            if(!this.state.img || !this.state.name || !this.state.title || !this.state.favoriteGameOne || !this.state.favoriteGameTwo) {
                alert("Must upload an image, add a name, add a title, add first favorite game and add second favorite game")

                addBtn.innerHTML = "Add"
                addBtn.classList.remove("spinner")
            }
            else {
                let teamMemberRes = await axios.post("/api/admin/add/team-member", {
                    img: this.state.img,
                    name: this.state.name,
                    title: this.state.title,
                    favoriteGameOne: this.state.favoriteGameOne,
                    favoriteGameTwo: this.state.favoriteGameTwo,
                    order: this.props.teamMembersLength + 1
                })

                alert(teamMemberRes.data)

                addBtn.innerHTML = "Add"
                addBtn.classList.remove("spinner")

                this.setState({
                    img: "",
                    name: "",
                    title: "",
                    favoriteGameOne: "",
                    favoriteGameTwo: "",
                    imgName: "No file selected.",
                    deleteImgName: ""
                }, async () => {
                    await this.props.getTeamMembersAgain()
                    this.props.changePanelType("view")
                })
            }
        }
        catch(err) {
            let addBtn = document.getElementById("admin_add_team_member_add_btn")

            if(err.response) {
                if(err.response.data) {
                    alert(err.response)
                }
            }

            addBtn.innerHTML = "Add"
            addBtn.classList.remove("spinner")
        }
    }

    render() {
        const { REACT_APP_SERVER_HOST } = process.env
        return (
            <div className="admin_add_team_member">
                <div className="admin_form_preview_container">
                    <div className="admin_form_container">
                        <div className="admin_input_container">
                            <p>Image (120 x 120):</p>
                            <div className="admin_file_upload_container">
                                <input type="file" 
                                accept="image/*" 
                                onChange={this.uploadImg} />
                                <p>{this.state.imgName}</p>
                            </div>
                        </div>
                        <div className="admin_input_container">
                            <p>Name:</p>
                            <input type="text" 
                            name="name" 
                            placeholder="Name" 
                            value={this.state.name} 
                            onChange={this.updateInput} />
                        </div>
                        <div className="admin_input_container">
                            <p>Title:</p>
                            <input type="text" 
                            name="title" 
                            placeholder="Title" 
                            value={this.state.title} 
                            onChange={this.updateInput} />
                        </div>
                        <div className="admin_input_container">
                            <p>First Favorite Game:</p>
                            <input type="text" 
                            name="favoriteGameOne" 
                            placeholder="first favorite game" 
                            value={this.state.favoriteGameOne} 
                            onChange={this.updateInput} />
                        </div>
                        <div className="admin_input_container">
                            <p>Second Favorite Game:</p>
                            <input type="text" 
                            name="favoriteGameTwo" 
                            placeholder="second favorite game" 
                            value={this.state.favoriteGameTwo} 
                            onChange={this.updateInput} />
                        </div>
                    </div>
                    <div className="admin_preview_container">
                        <div className="admin_add_team_member_preview">
                            <div className="team_member_img_preview">
                                <img src={`${REACT_APP_SERVER_HOST}${this.state.img}`} alt="team_member" />
                            </div>
                            <div className="team_member_info_preview">
                                <div className="name_title_preivew">
                                    <span id="name">{this.state.name}</span>
                                    <span id="title">{this.state.title}</span>    
                                </div>
                                <div className="games_preview">
                                    <p>Favorite Games:</p>
                                    <p>- {this.state.favoriteGameOne}</p>
                                    <p>- {this.state.favoriteGameTwo}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="admin_btn_container">
                    <button onClick={this.changeToView}>Cancel</button>
                    <button id="admin_add_team_member_add_btn" 
                    className="admin_spinner_container" 
                    disabled={!this.state.img || !this.state.name || !this.state.title || !this.state.favoriteGameOne || !this.state.favoriteGameTwo} 
                    onClick={this.addTeamMember}>Add</button>
                </div>
            </div>
        )
    }
}

export default AdminAddTeam