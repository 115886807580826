import React, { Component } from "react"
import axios from "axios"

import "./AdminUsers.scss"
import AdminUser from "./Components/AdminUser/AdminUser"

class AdminUsers extends Component {
    constructor(props) {
        super(props)

        this.state = {
            email: "",
            tempPassword: "",
            emailValidation: true,
            passwordVisibility: "hide",
            passwordIcon: "/PasswordVisibility/hide_password.png",
            users: []
        }
    }

    async componentDidMount() {
        try {
            let usersRes = await axios.get("/api/admin/get/users")

            let users = usersRes.data[0].filter((elem) => elem.id !== usersRes.data[1])
    
            this.setState({
                users: users
            })
        }
        catch(err) {
            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }

    showHidePassword = () => {
        let passwordInput = document.getElementById("add_user_password")

        if(this.state.passwordVisibility === "hide") {
            passwordInput.type = "text"

            this.setState({
                passwordVisibility: "show",
                passwordIcon: "/PasswordVisibility/show_password.png"
            })
        }
        else {
            passwordInput.type = "password"

            this.setState({
                passwordVisibility: "hide",
                passwordIcon: "/PasswordVisibility/hide_password.png"
            })
        }
    }

    updateInput = (e) => {
        if(e.target.name === "email") {
            let emailReg = /\S+@\S+\.\S+/

            if(e.target.value === "") {
                this.setState({
                    [e.target.name]: e.target.value,
                    emailValidation: true
                })
            }
            else {
                if(e.target.value.match(emailReg)) {
                    this.setState({
                        [e.target.name]: e.target.value,
                        emailValidation: true
                    })
                }
                else {
                    this.setState({
                        [e.target.name]: e.target.value,
                        emailValidation: false
                    })
                }
            }
        }
        else {
            this.setState({
                [e.target.name]: e.target.value
            })
        }
    }

    addUser = async () => {
        try {
            let addBtn = document.getElementById("admin_users_add_user_btn")
            addBtn.innerHTML = ""
            addBtn.classList.add("spinner")

            if(this.state.email.length > 255 || this.state.tempPassword > 255) {
                alert("Email and Password must be under 255 characters")

                addBtn.innerHTML = "Add"
                addBtn.classList.add("spinner")
            }
            else {
                let userRes = await axios.post("/auth-serv/admin/add/user", { email: this.state.email, password: this.state.tempPassword })

                alert(userRes.data)

                addBtn.innerHTML = "Add"
                addBtn.classList.remove("spinner")
            }
        }
        catch(err) {
            let addBtn = document.getElementById("admin_users_add_user_btn")

            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }

            addBtn.innerHTML = "Add"
            addBtn.classList.remove("spinner")
        }
        
    }

    getUsersAgain = async () => {
        try {
            let usersRes = await axios.get("/api/admin/get/users")

            let users = usersRes.data[0].filter((user) => user.id !== usersRes.data[1])

            this.setState({
                users: users
            })
        }
        catch(err) {
            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }
        }
    }

    render() {
        let users = this.state.users.map((user, i) => {
            return (
                <AdminUser key={i} user={user} getUsersAgain={this.getUsersAgain} />
            )
        })
        const { REACT_APP_SERVER_HOST } = process.env
        let emailInputClass = this.state.emailValidation === true ? "email_input" : "email_input_error"
        return (
            <div className="admin_users">
                <div className="admin_add_users_container">
                    {
                        this.state.emailValidation === true
                        ?
                            null
                        :
                            <div className="email_warning">
                                <p>Invalid email</p>
                            </div>
                    }
                    <h4>
                        <strong>Add User:</strong>
                    </h4>
                    <div className="admin_form_container">
                        <div className="admin_input_container">
                            <p>Email:</p>
                            <input type="text" 
                            className={emailInputClass}
                            name="email" 
                            placeholder="email@example.com"
                            value={this.state.email} 
                            onChange={this.updateInput}/>
                        </div>
                        <div className="admin_input_container">
                            <p>Temporary Password:</p>
                            <div className="admin_password_input">
                                <input type="password" 
                                id="add_user_password" 
                                name="tempPassword" 
                                placeholder="Temporary password" 
                                value={this.state.tempPassword} 
                                onChange={this.updateInput}/>
                                <img src={`${REACT_APP_SERVER_HOST}${this.state.passwordIcon}`} alt="password_icon" onClick={this.showHidePassword}/>
                            </div>
                        </div>
                        <div className="admin_users_btn_container">
                            <button id="admin_users_add_user_btn" 
                            className="admin_spinner_container" 
                            disabled={!this.state.emailValidation || !this.state.tempPassword || !this.state.email} 
                            onClick={this.addUser}>Add</button>
                        </div>
                    </div>
                </div>
                <div className="admin_users_container">
                    <h4>
                        <strong>Users:</strong>
                    </h4>
                    <div className="admin_users_user_list_container">
                        {users}
                    </div>
                </div>
            </div>
        )
    }
}

export default AdminUsers