import React from "react"
import axios from "axios"

import "./AdminHomeVideo.scss"

let AdminHomeVideo = (props) => {
    let changeToEdit = () => {
        props.changePanelType("edit", props.video)
    }

    let deleteVideo = async () => {
        try {
            let deleteBtn = document.getElementById(`admin_home_video_delete_btn_${props.video.id}`)
            deleteBtn.innerHTML = ""
            deleteBtn.classList.add("spinner")

            if(window.confirm("Are you sure you want to delete this home video?")) {
                let deleteRes = await axios.delete(`/api/admin/delete/home-video/${props.video.id}`)
    
                alert(deleteRes.data)
    
                deleteBtn.innerHTML = "Delete"
                deleteBtn.classList.remove("spinner")
    
                await props.getVideosAgain()
            }
            else {
                deleteBtn.innerHTML = "Delete"
                deleteBtn.classList.remove("spinner")
            }
        }
        catch(err) {
            let deleteBtn = document.getElementById(`admin_home_video_delete_btn_${props.video.id}`)

            if(err.response) {
                if(err.response.data) {
                    alert(err.response.data)
                }
            }

            deleteBtn.innerHTML = "Delete"
            deleteBtn.classList.remove("spinner")
        }
    }

    return (
        <div className="admin_home_video admin_list_item">
            <p>{props.video.name}</p>
            <div className="admin_list_item_btn_container">
                <button onClick={changeToEdit}>Edit</button>
                <button id={`admin_home_video_delete_btn_${props.video.id}`} 
                className="admin_spinner_container" 
                onClick={deleteVideo}>Delete</button>
            </div>
        </div>
    )
}

export default AdminHomeVideo